import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class EnvironmentService implements EnvironmentI {
  abstract app: string;
  abstract production: boolean;
  abstract baseApiUrl: string;
  abstract baseApiAttachmentUrl: string;
  abstract keycloakServerUrl: string;
  abstract keycloakRealmName: string;
  abstract keycloakClientId: string;
  abstract keycloakRedirectUri: string;
}

export interface EnvironmentI {
  app: string;
  production: boolean;
  baseApiUrl: string;
  baseApiAttachmentUrl: string;
  keycloakServerUrl: string;
  keycloakRealmName: string;
  keycloakClientId: string;
  keycloakRedirectUri: string;
}
