<div matDialogTitle>
  Company Create
  <mat-icon>apartment</mat-icon>
</div>
<div matDialogContent>
  <form [formGroup]="formGroup" class="container">
    <mat-card class="child-container">
      <mat-card-header>
        <mat-card-title>
          Basic Information
        </mat-card-title>
      </mat-card-header>
      <div class="mat-card-divider"></div>
      <mat-card-content class="form-div">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>

  <app-address #addressComponent></app-address>
</div>

<div matDialogActions class="fennec-controls">
  <button mat-stroked-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button mat-flat-button color="accent" (click)="onSubmit()">
    Submit
  </button>
</div>


