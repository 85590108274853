<lib-page>
  <ng-container pageTitle>
    Recent Activity
  </ng-container>
  <ng-container pageContent>
    <div>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="createdDateString">
          <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.createdDateString}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activityDiaryDomain">
          <mat-header-cell *matHeaderCellDef> Domain </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.activityDiaryDomain}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="miCaseId">
            <mat-header-cell *matHeaderCellDef> Stay ID </mat-header-cell>
            <mat-cell *matCellDef="let c">
              <button mat-button mat-raised-button color="accent">
                <mat-icon matListIcon>folder</mat-icon>
              <a *ngIf="c.activityDiaryDomain != 'ATTACHMENT'" [routerLink]="'/mi-case/'+ c.miCaseId + '/attachments'" routerLinkActive="active">
                Stay ID: {{c.miCaseId}}</a>
              <a *ngIf="c.activityDiaryDomain == 'ATTACHMENT'" [routerLink]="'/mi-case/'+ c.miCaseId + '/attachments'" routerLinkActive="active">
                Stay ID: {{c.miCaseId}}</a>
              </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activityDiaryMessageType">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.activityDiaryMessageType}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="messageText">
            <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.messageText}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="errorFlag">
          <mat-header-cell *matHeaderCellDef>
            <mat-icon [ngStyle]="{'color': 'orange'}">warning</mat-icon>
            <pre> </pre>
            Errors
            </mat-header-cell>
          <mat-cell *matCellDef="let c">
            <mat-icon [ngStyle]="{'color': c.errorFlag === false ? 'green' : 'red'}">
              {{ c.errorFlag === true ? 'report_problem' : 'check' }}
            </mat-icon>
            <pre> </pre>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
        <mat-row [ngStyle]="getRowStyle(row)" *matRowDef="let row; columns: displayColumns;"></mat-row>
      </mat-table>
      <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>
  </ng-container>
</lib-page>
