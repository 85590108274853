
<mat-dialog-content>
  <app-comment-list *ngIf="relatedId != '-1'" mode="{{mode}}" relatedId="{{relatedId}}" displayContext="'DIALOG'"></app-comment-list>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-paginator
    class="alt-theme" 
    [length]="commentListComponent == null ? 0 : commentListComponent.totalRowCount" 
    [pageSize]="defaultPageSize" 
    [pageSizeOptions]="defaultPageSizeOptions">
  </mat-paginator>

  <button
    mat-raised-button
    color="accent"
    (click)="openAddDialog()"
  >
    <mat-icon>add_comment</mat-icon>
    Add Comment
  </button>

  <button
    mat-stroked-button
    (click)="closeDialog()"
  >
    <mat-icon>close_circle</mat-icon>
    Close
  </button>
</mat-dialog-actions>