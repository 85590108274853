<div *ngIf="formGroup != null" class="dashboard-filter-param-wrapper-div">
  <h2>{{title}}</h2>
  <form [formGroup]="formGroup" class="container alt-theme">
    <table>
    <tr *ngFor="let pc of paramConfig">
      <td>{{pc.label}}:</td>
      <td>{{pc.type}}<td>
      <td>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'string'">
          <mat-form-field>
            <mat-label>
              {{pc.label}}
            </mat-label>
            <input matInput formControlName="{{pc.formControlName}}">
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'date'">
          <mat-form-field appearance="outline" [style.width.px]="240">
            <mat-label>
              {{pc.label}}
            </mat-label>
            <input matInput formControlName="{{pc.formControlName}}" placeholder="(MM/DD/YYYY)" (keydown.F2)="testDataDate()">
            <mat-error *ngIf="formGroup?.controls[pc.formControlName].errors != null">
              Please enter a valid date
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'primary_client'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Primary Client(s)</mat-label>
            <mat-select #clientSelect panelClass="alt-theme" multiple formControlName="{{pc.formControlName}}">
              <mat-option *ngFor="let pc of primaryClients" [value]="pc.companyId" (onSelectionChange)="onPCChange($event)">{{pc.companyName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'health_care_plan'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Health Care Plan(s)</mat-label>
            <mat-select #hcpSelect panelClass="alt-theme" multiple formControlName="{{pc.formControlName}}">
              <mat-option *ngFor="let hcp of healthCarePlans" [value]="hcp.healthCarePlanId">{{hcp.healthCarePlanName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'lob'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Line of Business</mat-label>
            <mat-select #lobSelect panelClass="alt-theme" multiple formControlName="{{pc.formControlName}}">
              <mat-option *ngFor="let lob of lineOfBusinessValues" [value]="lob.value">{{lob.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'mi_case_type'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Case Type(s)</mat-label>
            <mat-select #miCaseTypeSelect panelClass="alt-theme" multiple formControlName="{{pc.formControlName}}">
              <mat-option *ngFor="let ct of miCaseTypes" [value]="ct.value">{{ct.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'attachment_type'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Attachment Type(s)</mat-label>
            <mat-select #attachmentTypeSelect panelClass="alt-theme" multiple formControlName="{{pc.formControlName}}">
              <mat-option *ngFor="let aType of attachmentTypes" [value]="aType">{{aType}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'line_adj_user'">
          <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
            <mat-label>Nurse Reviewer</mat-label>
            <mat-select #lineAdjUserSelect panelClass="alt-theme" formControlName="{{pc.formControlName}}">
              <mat-option [value]="'ALL'">ALL</mat-option>
              <mat-option *ngFor="let u of lineAdjUsers" [value]="u.userProfileId">{{u.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'primary_client'">
          <!-- Note: there should only be one of these or else this will break. This means that in the dashboard config filters
             there can only be ONE primary_client field. I don't see a reason why this would ever change. -->
          <form [formGroup]="pcSelectAllFormGroup">
            <mat-checkbox formControlName="pcSelectAll" (change)="onPCSelectAll($event)">Select All</mat-checkbox>
          </form>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'health_care_plan'">
          <!-- Note: there should only be one of these or else this will break. This means that in the dashboard config filters
             there can only be ONE primary_client field. I don't see a reason why this would ever change. -->
          <form [formGroup]="pcSelectAllFormGroup">
            <mat-checkbox formControlName="hcpSelectAll" (change)="onHCPSelectAll($event)">Select All</mat-checkbox>
          </form>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'attachment_type'">
          <form [formGroup]="pcSelectAllFormGroup">
            <mat-checkbox formControlName="attachmentTypeSelectAll" (change)="onAttachmentTypeSelectAll($event)">Select All</mat-checkbox>
          </form>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'mi_case_type'">
          <form [formGroup]="pcSelectAllFormGroup">
            <mat-checkbox formControlName="miCaseTypeSelectAll" (change)="onMICaseTypeSelectAll($event)">Select All</mat-checkbox>
          </form>
        </ng-container>
        <ng-container *ngIf="pc.dataType.toLowerCase() === 'lob'">
          <form [formGroup]="pcSelectAllFormGroup">
            <mat-checkbox formControlName="lobSelectAll" (change)="onLOBSelectAll($event)">Select All</mat-checkbox>
          </form>
        </ng-container>
      </td>
    </tr>
    </table>
  </form>
</div>
