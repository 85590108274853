import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { MICaseService } from '@app/mi-case/mi-case.service';
import {BaseComponent, FennecSnackbarService, Logger, SingleChoiceDialogComponent} from 'xf-common';
import { DataEntryAttachmentDialogComponent } from '../data-entry-attachment-dialog/data-entry-attachment-dialog.component';
import {DataEntryService} from "@app/data-entry/data-entry.service";

@Component({
  selector: 'app-data-entry-mi-case-list',
  templateUrl: './data-entry-mi-case-list.component.html',
  styleUrls: ['./data-entry-mi-case-list.component.scss']
})
export class DataEntryMiCaseListComponent extends BaseComponent implements OnInit, AfterViewInit {
  protected log: Logger = new Logger("DataEntryMICaseListComponent");

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayColumns = ['id', 'type', 'statusTrackStatus', 'altexternalid', 'name', 'createdBy', 'createdDate', 'actions'];
  //displayColumns = ['id', 'type', 'statusTrackStatus', 'altexternalid', 'name', 'createdBy', 'createdDate'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;

  defaultPageSize = 20;
  pageSizeOptions = [10, 20, 25, 50];

  attachmentDialogRef?: MatDialogRef<any>;

  constructor(
    private caseService: MICaseService,
    private dataEntryService: DataEntryService,
    protected snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getCases();
      })
    }
  }

  ngOnInit() {
    this.getCases();
  }

  getCases() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.dataEntryService.getCasesForDataEntry(first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    });
  }

  onShowAttachments(miCaseId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "60%";
    dialogConfig.data = {
      miCaseId: miCaseId,
    }
    this.attachmentDialogRef = this.matDialog.open(DataEntryAttachmentDialogComponent, dialogConfig);
    this.attachmentDialogRef.afterClosed().subscribe(response => {
    });
  }

  onManualDataEntry(miCaseId: number) {
    this.router.navigateByUrl(`/mi-case/${miCaseId}`);
  }

  getCase(miCaseId: number): any {
    let miCase: any = null;
    this.dataSource.data.forEach((d:any) => {
      if (d.id === miCaseId) {
        miCase = d;
      }
    });
    return miCase;
  }

  onDataEntryComplete(miCaseId: number) {
    let miCase = this.getCase(miCaseId);
    if (miCase === null) {
      return;
    }
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Complete Data Entry?",
      infoLine1 : "Case: " + miCaseId.toString() + " Name: " + miCase.name
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Submit Case data entry complete",
          requestFn: this.caseService.dataEntryComplete,
          fnParams: [miCaseId],
          onSuccess: data => {
            super.showSuccessSnack(data);
            this.getCases();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    });
  }

}
