export class UB04Edit1 {
  id: number = -1;
  federalTaxNumber?: string | null;
  patientControlNumber?: string | null;
  medicalRecordNumber?: string | null;
  ub04BillType?: string | null;
  ub04ReviewType?: string | null;
  visitType?: string | null;
  presentOnAdmission?: string | null;
  drgCode?: string | null;
}
