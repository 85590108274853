import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Logger } from "../../util/logger";

@Injectable({
  providedIn: 'root'
})
export class FennecSnackbarService {

  log: Logger = new Logger("FennecSnackbarService");
  constructor(
    private snack : MatSnackBar
  ) { }

  showSuccessSnack(message: string, duration = 5000) {
    this.snack?.open(message, "OK", {duration, panelClass: "snack-success"})
    || this.log.warn("Snack is undefined", message);
  }

  showInfoSnack(message: string, duration = 5000) {
    this.snack?.open(message, "OK", {duration, panelClass: "snack-info"})
    || this.log.warn("Snack is undefined", message);
  }

  showWorkerSubmitSnack(message: string, duration = 5000) {
    message += " [Refer to Activity Diary for status]";
    this.snack?.open(message, "OK", {duration, panelClass: "snack-worker-submit"})
    || this.log.warn("Snack is undefined", message);
  }

  showWarningSnack(message: string, duration = undefined) {
    this.snack?.open(message, "OK", {duration, panelClass: "snack-warning"})
    || this.log.warn("Snack is undefined", message);
  }

  showErrorSnack(message: string, duration = undefined) {
    this.snack?.open(message, "OK", {duration, panelClass: "snack-error"})
    || this.log.warn("Snack is undefined", message);
  }
}
