import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CompanyPacket } from "../model/net/company-packet";
import { PagedResponse } from "../model/paged-response";
import { CompanyRelationalTypeCreatePacket } from "../model/net/company-relational-type-create-packet";
import { EnvironmentService } from "../environment.service";

@Injectable()
export class CompanyService {
  private companyUrl = this.environment.baseApiUrl + "company/";

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
  }

  getCompany = (id?: number): Observable<any> => {
    return this.httpClient.get<any>(this.companyUrl + id);
  }

  getAllCompanies = (name?: string, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list?first=" + first + "&max=" + max + "&name=" + name);
  }

  getCompaniesByNameIncludingHCPlanName = (name?: string, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list?includeHealthCarePlans=true&first=" + first + "&max=" + max + "&name=" + name);
  }  

  getAllTenantCompanies = (first: number = 0, max: number = 9999): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list-crt/TENANT/-1?first=" + first + "&max=" + max);
  }

  getAllUserCompanies = (relatedId:number, first: number = 0, max: number = 9999): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(`${this.companyUrl}company-list-crt/USER_PROFILE/${relatedId}?first=${first}&max=${max}`);
  }

  // Note: sending up -1 as the id (UserProfile.Id) necessary for this endpoint. The back-end will handle the conversion using
  // the user information sent up with the token.
  getActiveUserProfilePrimaryClientCompanies = (first: number = 0, max: number = 9999): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list-crt/USER_PROFILE/-1/PRIMARY_CLIENT"
      + "?first=" + first + "&max=" + max);
  }

  createCompany = (company?: CompanyPacket): Observable<any> => {
    return this.httpClient.post<any>(this.companyUrl + "create", company);
  }

  createCompanyRelationalType = (company?: CompanyRelationalTypeCreatePacket): Observable<any> => {
    return this.httpClient.post<any>(this.companyUrl + "create/crt", company);
  }

  updateCompany = (company?: CompanyPacket): Observable<any> => {
    return this.httpClient.put<any>(this.companyUrl + "update", company);
  }

  deleteCompany = (id?: number): Observable<any> => {
    return this.httpClient.delete<any>(this.companyUrl + id);
  }

  deleteCompanyRelationalType = (id?: number): Observable<any> => {
    return this.httpClient.delete<any>(this.companyUrl + "crt/" + id);
  }

  reinstateCompanyRelationalType = (id?: number): Observable<any> => {
    return this.httpClient.put<any>(this.companyUrl + "reinstate/crt/" + id, null);
  }

  hardDeleteCompanyRelationalType = (id?: number): Observable<any> => {
    return this.httpClient.delete<any>(this.companyUrl + "delete/crt/" + id);
  }

  hardDeleteAllCompaniesFromUser = (userProfileId?: number): Observable<any> => {
    return this.httpClient.delete<any>(this.companyUrl + "delete/crt/user/all/" + userProfileId);
  }

}
