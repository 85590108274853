<pdf-shy-button
  primaryToolbarId="download"
  [cssClass]="showDownloadButton | responsiveCSSClass : 'hiddenSmallView'"
  title="Download"
  l10nId="pdfjs-save-button"
  l10nLabel="pdfjs-save-button-label"
  image="<svg width='20px' height='20px' viewBox= '0 0 24 24'><path fill= 'currentColor ' d= 'M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z ' /></svg>"
  [order]="400"
>
  <span data-l10n-id="pdfjs-save-button-label">Download</span>
</pdf-shy-button>
