import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { AddressComponent } from "../../admin/address-admin/address.component";
import { StatusTrackService } from "../../status-track/status-track.service";
import { Ub04Service } from '../ub04.service';
import { DateUtil } from '../../util/date-util';
import { Logger } from "../../util/logger";
import { SingleChoiceDialogComponent } from '../../dialog/single-choice-dialog/single-choice-dialog.component';
import { ExistingPatientSearchComponent } from '../patient/existing-patient-search/existing-patient-search.component';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-ub04-edit-patient-dialog',
  templateUrl: './ub04-edit-patient-dialog.component.html',
  styleUrls: ['./ub04-edit-patient-dialog.component.scss']
})
export class Ub04EditPatientDialogComponent extends UB04StatusTrackBaseDialogComponent
  implements OnInit, AfterViewInit {

  override log = new Logger("Ub04EditPatientDialogComponent");

  formGroup!: FormGroup;
  ub04Patient: any = {};
  selectedGender: string = "F";

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  existingPatientSearchEnabled = false;

  constructor(
    public dialogRef: MatDialogRef<Ub04EditPatientDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private ub04Service: Ub04Service,
    private keycloakService: KeycloakService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // Provide an instance StatusTrackService to the Base component because Angular won't inject
    // this. Also provide additional params (ub04id, element) so the Base component has enough
    // information to do it's work (like make endpoint calls for status tracking lock / unlock).
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_PATIENT, statusTrackService);
    this.formGroup = new FormGroup({
      altPatientId: new FormControl(""),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      birthDateString: new FormControl("", Validators.required)
    });

    const roles = this.keycloakService.getUserRoles();
    this.existingPatientSearchEnabled = roles.includes("PATIENT_READ") || roles.includes("ADMIN");
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngAfterViewInit(): void {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id) => {
        this.ub04Patient.patient.addressId = id;
        this.savePatientToServer(id);
      });
    }
  }

  fetchData() {
    this.ub04Service.getUb04EditPatient(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04Patient = response.data;
        this.formGroup.controls['altPatientId'].setValue(response.data.patient.altPatientId);
        this.formGroup.controls['firstName'].setValue(response.data.patient.firstName);
        this.formGroup.controls['lastName'].setValue(response.data.patient.lastName);
        this.formGroup.controls['birthDateString'].setValue(DateUtil.fennecDBStringToInputDateString(response.data.patient.birthDateString));
        if (this.addressComponent) {
          // Only retrieve the address if there is one (i.e. the id > -1)
          if (response?.data?.patient?.addressId >= 0) {
            this.addressComponent.getAddressById(response.data.patient.addressId);
          }
        }
        this.selectedGender = response.data.patient.gender;
      }
    });
  }

  onSubmit() {
    this.addressComponent?.saveAddressToServer();
  }

  savePatientToServer(addressId: number) {
    this.formGroup.markAllAsTouched();
    let updDto = {
      id: this.data.ub04Id,
      patient: {
        id: this.ub04Patient.patient.id,
        firstName: this.formGroup.controls['firstName'].value,
        lastName: this.formGroup.controls['lastName'].value,
        altPatientId: this.formGroup.controls['altPatientId'].value,
        birthDateString: DateUtil.inputDateStringToFennecDBDateString(this.formGroup.controls['birthDateString'].value),
        gender: this.selectedGender,
        addressId: addressId
      }
    }

    this.ub04Service.putUb04EditPatient(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });

  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onDelete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Are you sure you want to remove Patient from UB04?`,
      infoLine1: "This will affect all UB04s attached to this case!"
    };

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.ub04Service.removePatientFromMICaseByUB04Id(this.data.ub04Id).subscribe(response => {
          if(response.hasErrors) {
            super.showErrorSnack("Error removing patient from MICase");
          } else {
            super.showSuccessSnack("Patient Removed successfully!");
            this.fetchData();
          }
        })
      }
    });
  }

  openSearchDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50vw";
    dialogConfig.data = {
      ub04Id: this.data.ub04Id,
      selectedPatientId: this.ub04Patient?.id ?? -1
    };

    const dialogRef = this.dialog.open(ExistingPatientSearchComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.fetchData();
      }
    });
  }
}
