import { KeycloakService } from "keycloak-angular";
import { EnvironmentService } from "xf-common";

export function initializeKeycloak(
  keycloak: KeycloakService,
  environment: EnvironmentService
  ) {
    return () =>
      keycloak.init({
        config: {
          url: environment.keycloakServerUrl,
          realm: environment.keycloakRealmName,
          clientId: environment.keycloakClientId,
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
          silentCheckSsoFallback: false
        },
        enableBearerInterceptor: true,
        bearerPrefix: 'Bearer',
        bearerExcludedUrls: ['/assets']
     });
}
