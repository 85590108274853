<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.name}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef> Case Type </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.type}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
  </mat-table>