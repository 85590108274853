export class CaseCreate {
  type: string = "";
  name?: string;
  altExternalId?: string;
  primaryClientCompanyId?: number = -1;
  lineOfBusiness?: string;
  preReviewPayable?: number;
  postReviewPayable?: number;
  drgPaymentAmount?: number;
  networkDiscount?: number;
  clientClaimId?: string;
  notes?: string;
  providerPar?: string;
  customerPriority?: string;
  postPaymentReview?: any;
  clientMemberNumber?: any;
  healthCarePlanId?: number;
  drgPaidDate?: string;
  insuredGroupNo?: string;
  insuredGroupName?: string;
  clientInternalAdjustments?: number;
}
