<mat-dialog-content class="alt-theme">
  <div class="header-div">
    <div class="header-left">
      <h1 mat-dialog-title>Edit Patient Information</h1>
    </div>
    <div class="header-right">
      <button mat-icon-button
              *ngIf="ub04Patient?.patient?.id"
              [routerLink]="['patient', ub04Patient?.patient?.id || '']"
              matTooltip="Navigate to patient record"
      >
        <mat-icon>person_search</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="More actions">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #appMenu="matMenu">
        <button
          mat-menu-item
          (click)="openSearchDialog()"
          *ngIf="existingPatientSearchEnabled"
        >
          <mat-icon>add_circle</mat-icon> <span>Add Existing Patient</span>
        </button>

        <button
          mat-menu-item
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> <span>Clear</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form alt-theme">
      <mat-form-field class="alt-patient-id" appearance="outline">
        <mat-label>Alternate Patient Id</mat-label>
        <input matInput placeholder="Alternate Patient Id" formControlName="altPatientId">
      </mat-form-field>

      <div class="input-group">
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field class="input" appearance="outline">
          <mat-label>Sex</mat-label>
          <mat-select [(value)]="selectedGender" placeholder="Gender">
              <mat-option value="F">Female</mat-option>
              <mat-option value="M">Male</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input" appearance="outline">
          <mat-label>Birth Date</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="birthDateString">
        </mat-form-field>
      </div>
    </form>
  </div>

  <app-address #addressComponent></app-address>

</mat-dialog-content>

  <mat-dialog-actions class="fennec-controls">
    <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
      Mark Complete
    </mat-checkbox>
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
  </mat-dialog-actions>
