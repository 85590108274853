import { NgModule } from "@angular/core";
import { PageComponent } from "./page/page.component";
import { PageActionsDirective } from "./page/page-actions.directive";
import { PageTitleDirective } from "./page/page-title.directive";
import { PageContentDirective } from "./page/page-content.directive";
import { CommonModule } from "@angular/common";
import { EditButtonComponent } from './button/edit-button/edit-button.component';
import { DeleteButtonComponent } from './button/delete-button/delete-button.component';
import { CreateButtonComponent } from './button/create-button/create-button.component';
import { MaterialModule } from "./material.module";
import { AutoFocusDirective } from "./auto-focus.directive";
import { PageNoDataDirective } from "./page/page-no-data.directive";
import { TruncateTextDirective } from "./truncate-text/truncate-text.directive";
import { TruncateTextComponent } from "./truncate-text/truncate-text.component";
import { PageToolbarDirective } from "./page/page-toolbar.directive";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    AutoFocusDirective,
    CreateButtonComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    PageActionsDirective,
    PageComponent,
    PageContentDirective,
    PageNoDataDirective,
    PageTitleDirective,
    PageToolbarDirective,
    TruncateTextComponent,
    TruncateTextDirective
  ],
  exports: [
    AutoFocusDirective,
    CreateButtonComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    PageActionsDirective,
    PageComponent,
    PageContentDirective,
    PageNoDataDirective,
    PageTitleDirective,
    PageToolbarDirective,
    TruncateTextComponent,
    TruncateTextDirective
  ]
})
export class CommonViewModule {
}
