import { Deserializable, deserializeAssign } from "../deserializable";
import { CommentPacket } from "./CommentPacket";

export class CommentRelationalMapPacket implements Deserializable<CommentRelationalMapPacket> {

  id?: number;
  comment?: CommentPacket;
  commentRelationalType?: string;
  relatedId?: number;
  lineItemUniqueRefId?: string;
  miCaseId?: number;
  active?:boolean;

  deserialize(input: any): CommentRelationalMapPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}