<pdf-shy-button
  cssClass="hiddenLargeView"
  title="Last page"
  primaryToolbarId="primaryLastPage"
  l10nId="pdfjs-last-page-button"
  l10nLabel="pdfjs-last-page-button-label"
  (click)="lastPage()"
  [disabled]="disableLastPage"
  [order]="800"
  eventBusName="lastpage"
  [closeOnClick]="false"
  image="<svg width='24px' height='24px' viewBox='0 0 24 24'><path fill='currentColor' d='M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z' /></svg>"
>
</pdf-shy-button>
