import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { Subject } from "rxjs";
import { EnvironmentService } from "../../environment.service";
import { BaseResponse } from "../../model/base-response";
import { Address } from "../../model/address";

@Injectable()
export class AddressService {

  saveAddress: Subject<any> = new Subject();
  addressSaveComplete: Subject<number> = new Subject();

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getAddressById = (id: number): Observable<BaseResponse> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "address/" + id);
  }

  updateAddress(address: Address): Observable<any> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "address", address);
  }
}
