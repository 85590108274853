import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  BaseResponse,
  BaseService,
  CommentPacket, CommentRelationalMapPacket,
  DataResponse,
  EnvironmentService,
  Logger,
  PagedResponse
} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  baseEndpoint = this.apiEndpoint("comment");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(new Logger("CommentService"));
  }

  getComments = (packet:{}, first:number, max:number): Observable<PagedResponse<CommentPacket[]>> => {
    return this.httpClient.post<PagedResponse<CommentPacket[]>>(`${this.baseEndpoint}/list?first=${first}&max=${max}`, packet);
  }

  createComment = (packet:CommentRelationalMapPacket): Observable<DataResponse<any>> => {
    return this.httpClient.post<DataResponse<any>>(`${this.baseEndpoint}/create`, packet);
  }

  editComment = (packet: CommentPacket): Observable<DataResponse<any>> => {
    return this.httpClient.post<DataResponse<any>>(`${this.baseEndpoint}/update`, packet)
  }

  getCommentTypes = (): Observable<DataResponse<any>> => {
    return this.httpClient.get<DataResponse<any>>(`${this.baseEndpoint}/types`);
  }

  deleteComment = (id:number): Observable<DataResponse<any>> => {
    return this.httpClient.delete<DataResponse<any>>(`${this.baseEndpoint}/delete/${id}`);
  }
}
