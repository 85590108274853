<mat-dialog-content>
  <h1 mat-dialog-title>Create New Itemized</h1>
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form alt-theme">
      <mat-form-field class="external-altid-field" appearance="outline">
        <mat-label>External Alt Id (Optional)</mat-label>
        <input matInput placeholder="Enter External Alt Id (Optional)..." formControlName="externalAltId">
      </mat-form-field>      
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <button mat-flat-button (click)="onSubmit(data.miCaseId)" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Create
    </button>

    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>

