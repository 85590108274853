<form [formGroup]="formGroup" class="alt-theme" style="margin: 2%">
  <h2>Address</h2>

  <div style="display: flex; flex-direction: column;">

    <mat-form-field>
      <mat-label>
        Address Line 1
      </mat-label>
      <input matInput formControlName="addressLine1">
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        Address Line 2
      </mat-label>
      <input matInput formControlName="addressLine2">
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        Address Line 3
      </mat-label>
      <input matInput formControlName="addressLine3">
    </mat-form-field>
  </div>

  <div style="width: 100%; text-align: center">

    <mat-form-field style="margin: 0 2%;">
      <mat-label>
        City
      </mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <mat-form-field style="margin: 0 2%;">
      <mat-label>
        State
      </mat-label>
      <mat-select formControlName="stateId">
        <mat-option *ngFor="let state of stateList" [value]="state['id']">
          {{ state['stateCode'] }} - {{ state['stateName'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin: 0 2%;">
      <mat-label>
        Zip Code
      </mat-label>
      <input matInput formControlName="zipCode">
    </mat-form-field>
  </div>
</form>
