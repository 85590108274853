import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { BaseCrudService } from '../../view/crud/base.crud.service';
import { DataResponse } from "../../model/data-response";
import { State } from "../../model/net/state";
import { PagedResponse } from "../../model/paged-response";
import { StatePacket } from "../../model/net/statePacket";
import { Crud } from "../../util/common-handlers";

@Injectable()
export class StateService extends BaseCrudService<State> {
  baseEndpoint = super.apiEndpoint("state");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "StateService", "State");
  }

  _list: Crud.ListFunction<State> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<State[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<State> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<State>>(endpoint);
  }

  _create: Crud.CreateFunction<State> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<State>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<State> = (user): Observable<DataResponse<State>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<State>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<State> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  _delete = (ids: number[]):  Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/destroy/` + ids[0];
    return this.httpClient.delete<DataResponse<boolean>>(endpoint, {});
  }

  changeStatus = (diagnosisCodePacket: State): Observable<DataResponse<State>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<State>>(endpoint, diagnosisCodePacket);
  }

  protected _crudFunctions: Crud.CrudServiceDef<State> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  searchByParams = (params:any, first?:number, max?:number): Observable<PagedResponse<StatePacket[]>> => {
    const endpoint = `${this.baseEndpoint}/search?first=${first}&max=${max}`
    return this.httpClient.put<PagedResponse<StatePacket[]>>(endpoint, params);
  }
}
