<lib-page>
  <ng-container pageTitle>
    <span class="header-data-element-span">Stay: <span class="header-data-value-span">{{miCaseId}}</span></span>
    <span class="header-data-element-span" *ngIf="miCaseInfo?.altExternalId != null">Alt Ext Id: <span class="header-data-value-span">{{miCaseInfo?.altExternalId}}</span></span>
    <span class="header-data-element-span" *ngIf="miCaseInfo?.name != null">Name: <span class="header-data-value-span">{{miCaseInfo?.name}}</span></span>
    <span class="header-data-element-span" *ngIf="miCaseInfo?.coverPeriodFromDate != null">From: <span class="header-data-value-span">{{miCaseInfo?.coverPeriodFromDate}}</span></span>
    <span class="header-data-element-span" *ngIf="miCaseInfo?.coverPeriodThruDate != null">To: <span class="header-data-value-span">{{miCaseInfo?.coverPeriodThruDate}}</span></span>
    <mat-chip-list>
      <mat-chip
        id="deadline-chip"
        *ngIf="miCaseInfo?.deadlineApproaching"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
      >
        <mat-icon matChipAvatar style="font-size: 18px; margin-right: -4px;">warning</mat-icon>
        48 Hour Warning
      </mat-chip>
      <mat-chip
        id="rush-chip"
        *ngIf="miCaseInfo?.customerPriority === 'RUSH'"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
      >
        <mat-icon matChipAvatar style="font-size: 18px; margin-right: -4px;">priority_high</mat-icon>
        Rush
      </mat-chip>
      <mat-chip
        #chip
        id="hold-chip"
        class="hold-status"
        [class.case-on-hold]="miCaseInfo?.onHold"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
        [matTooltip]="miCaseInfo?.onHold ? miCaseInfo?.holdReasonUserLabel || 'Unspecified' : ''">
        {{ miCaseInfo?.onHold ? 'On Hold' : 'Off Hold' }}
      </mat-chip>
      <mat-chip
        *ngIf="miCaseInfo?.trialCase"
        id="trial-chip"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
      >
        Trial
      </mat-chip>
      <mat-chip
        id="status-track-status-chip"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
        routerLink="task-list"
      >
        {{miCaseInfo?.statusTrackStatus}}
      </mat-chip>
      <mat-chip
        id="duplicate-case-status-chip"
        *ngIf="miCaseInfo?.duplicateParentCaseId"
        [selectable]="false"
        [class.mat-focus-indicator]="false"
        [class.striped-background]="miCaseInfo?.duplicateParentCaseId"
      >
        {{miCaseInfo?.duplicateParentCaseId ? "Flagged as Duplicate" : "Flag Duplicate" }}
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <ng-container pageToolbar>
    <nav mat-tab-nav-bar
         backgroundColor="primary"
         color="warn">
      <a mat-tab-link
         *ngFor="let link of activeRoutes"
         [routerLink]="link.path"
         routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive"
      >
        {{link.label}}
      </a>
    </nav>
  </ng-container>
  <ng-container pageContent>
    <router-outlet></router-outlet>
  </ng-container>
</lib-page>
