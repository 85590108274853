import { Deserializable, deserializeAssign } from "../deserializable";

export class CommentPacket implements Deserializable<CommentPacket> {

  id?: number;
  commentType?: string;
  commentText?: string;
  active?: boolean;

  deserialize(input: any): CommentPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}

export const DEFAULT_TEXT_INTERNAL_ADJUSTMENT_COMMENT =
  `Carve Out Rev Codes: ` +
  `\n\n` +
  `Denied Dates & Amount: _FROM_DATE_ -- _THRU_DATE_ & $` +
  `\n\n` +
  `Approved Experimental/Investigation Drugs: ` +
  `\n\n` +
  `Denied Experimental/Investigational Drugs: ` +
  `\n\n` +
  `Level of Care Adjustments: `;

