import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject } from 'rxjs';
import { BaseResponse } from "../../../model/base-response";
import { BaseComponent } from "../../../view/base.component";
import { ProviderService } from "../../../provider/provider.service";
import { ProviderPacket } from "../../../model/net/provider-packet";
import { AddressComponent } from "../../../admin/address-admin/address.component";
import { CodeValidationService } from "../../../code/code-validation.service";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../../util/logger";


@Component({
  selector: 'app-ub04-add-provider-2',
  templateUrl: './ub04-add-provider.component.html',
  styleUrls: ['./ub04-add-provider.component.scss']
})
export class Ub04AddProviderComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("Ub04AddProviderComponent");
  formGroup!: FormGroup;
  providerTypes?: any [] = [];

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  saveProvider: Subject<any> = new Subject();
  providerSaveComplete: Subject<any> = new Subject();

  constructor(
    private providerService: ProviderService,
    private codeValidationService: CodeValidationService,
    protected snack: FennecSnackbarService
  ) {
    super();
    this.formGroup = new FormGroup({
      name: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      npiNumber: new FormControl(""),
      providerType: new FormControl("", Validators.required),
      taxonomyCode: new FormControl(""),
      taxonomyCodeDescription: new FormControl(""),
      addressValidator: new FormControl()
    }, {
      // updateOn: 'blur'
    })

    this.formGroup.controls['taxonomyCodeDescription'].disable();

    this.formGroup.valueChanges.subscribe(() => {
      this.validateTaxonomyCode();
      this.validateAddress();
    })

  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngAfterViewInit(): void {
    this.findAddressComponent();
  }

  findAddressComponent(){
    if (this.addressComponent != null) {
      this.addressComponent?.addressSaveComplete?.subscribe((id) => {
        // this.ub04Patient.patient.addressId = id;
        this.saveProviderToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  fetchData() {
    this.providerService.getProviderTypes().subscribe(response => {
      if(response.hasErrors){
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      }else{
        this.providerTypes = response.data;
      }
    })
  }

  validateTaxonomyCode(){
    let taxonomyCodeControl: FormControl = this.formGroup.controls['taxonomyCode'] as FormControl;
    let taxonomyDescriptionControl: FormControl = this.formGroup.controls['taxonomyCodeDescription'] as FormControl;

    if(!taxonomyCodeControl.pristine){
      if(taxonomyCodeControl.value.length == 0) {
        taxonomyCodeControl.markAsPristine();
        taxonomyDescriptionControl.setValue("");
        taxonomyCodeControl.setErrors(null);
      }else {
        this.codeValidationService.getTaxonomyCode(taxonomyCodeControl.value).subscribe((response: BaseResponse) => {
          taxonomyCodeControl.markAsPristine();
          if(response.hasErrors) {
            taxonomyCodeControl.setErrors({'incorrect': true});
          }else{
            if(response.data !== null){
              taxonomyDescriptionControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              taxonomyCodeControl.setErrors(null);
            }else{
              taxonomyDescriptionControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              taxonomyCodeControl.setErrors({'incorrect': true});
            }
          }
        });
      }
    }
  }

  validateAddress(): boolean {
    let addressForm: FormGroup | undefined = this?.addressComponent?.formGroup;
    let addressValueEntered = false;

    if(addressForm){
      if (addressForm?.touched && addressForm?.dirty) {
        // check whether address has values to persist
        const enteredAddressInfo = addressForm?.value;
        const addressFields = Object.keys(enteredAddressInfo);
        let field: string;
        for (let key in addressFields) {
          field = addressFields[key];
          if (field != null && field != "") {
            addressValueEntered = true;
            break;
          }
        }
      }
      if(!addressForm.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
    return addressValueEntered;
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    const addressEntered = this.validateAddress();
    if (this.formGroup.invalid) {
      return;
    }
    if (addressEntered) {
      this.addressComponent?.saveAddressToServer();
    } else {
      this.saveProviderToServer(null);
    }
  }

  saveProviderToServer(addressId: number | null){
    this.formGroup.markAllAsTouched();
    const value = this.formGroup.value;

    if (this.formGroup.errors || this.formGroup.invalid
      || (value["providerType"] == null || value["providerType"] == "")) {
      super.showErrorSnack("Please fill out all required fields.");
      return;
    }

    let packet = new ProviderPacket();
    packet.id = -1;
    packet.name = this.formGroup.controls["name"].value;
    packet.firstName = this.formGroup.controls["firstName"].value;
    packet.lastName = this.formGroup.controls["lastName"].value;
    packet.npiNumber = this.formGroup.controls["npiNumber"].value;
    packet.providerType = this.formGroup.controls["providerType"].value;
    packet.taxonomyCode = this.formGroup.controls["taxonomyCode"].value;
    packet.addressId = addressId;

    this.providerService.createProvider(packet).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.providerSaveComplete.next(response.data);
      }
    });
  }
}
