import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({ selector: "[autoFocus]" })
export class AutoFocusDirective implements AfterViewInit {
  @Input("autoFocus")
  autoFocus?: boolean;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        try {
          this.el.nativeElement.focus();
        } catch (e) {}
      });
    }
  }
}
