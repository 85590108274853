<mat-dialog-content 
  class="existing-display alt-theme"
  style="min-width: 40vw;"
>
  <div
    [hidden]="displayMode !== 'EXISTING'"
  >
    <h1>UB04 Payers</h1>
  
    <div 
      [hidden]="primaryPayerComponent.selectedCompany == null"
      class="payer-bubble">
      <app-ub04-edit-payer-component 
        #primaryPayerComponent 
        [hidden]="displayMode !== 'EXISTING'"
        payerType="PRIMARY">
      </app-ub04-edit-payer-component>
    </div>

    <button
      mat-button 
      mat-stroked-button 
      *ngIf="primaryPayerComponent.selectedCompany == null && displayMode == 'EXISTING'"
      (click)="setDisplayMode('SEARCH_PRIMARY')"
    >
      <mat-icon>add_circle_outline</mat-icon>
      Add Primary Payer
    </button>

    <div
      [hidden]="secondaryPayerComponent.selectedCompany == null && displayMode == 'EXISTING'" 
      class="payer-bubble"
      style="margin-top: 25px;"
    >
      <app-ub04-edit-payer-component 
        #secondaryPayerComponent 
        [hidden]="displayMode !== 'EXISTING'"
        payerType="SECONDARY">
      </app-ub04-edit-payer-component>
    </div>
    <button
      mat-button 
      mat-stroked-button 
      *ngIf="primaryPayerComponent.selectedCompany !== null && secondaryPayerComponent.selectedCompany == null && displayMode == 'EXISTING'"
      (click)="setDisplayMode('SEARCH_SECONDARY')"
    >
      <mat-icon>add_circle_outline</mat-icon>
      Add Secondary Payer
    </button>

    <div
      [hidden]="tertiaryPayerComponent.selectedCompany == null && displayMode == 'EXISTING'" 
      class="payer-bubble"
      style="margin-top: 25px;"
    >
      <app-ub04-edit-payer-component 
        #tertiaryPayerComponent 
        [hidden]="displayMode !== 'EXISTING'"
        payerType="TERTIARY">
      </app-ub04-edit-payer-component>
    </div>
    <button
      mat-button 
      mat-stroked-button 
      *ngIf="secondaryPayerComponent.selectedCompany !== null && tertiaryPayerComponent.selectedCompany == null && displayMode == 'EXISTING'"
      (click)="setDisplayMode('SEARCH_TERTIARY')"
    >
      <mat-icon>add_circle_outline</mat-icon>
      Add Tertiary Payer
    </button>
  </div>
</mat-dialog-content>

<div 
  class="header"
  *ngIf="isSearch()">
  <h2>Company Search for UB04 {{getFormattedPayerTypeForSearch()}}Payer</h2>

  <div
    style="display: flex;"
  >
    <button
      mat-button 
      mat-stroked-button 
      class="icon-btn"
      (click)="setDisplayMode('ADD_COMPANY')"
    >
      <mat-icon>add_circle</mat-icon>
    </button>

    <button
      mat-button 
      mat-stroked-button 
      class="icon-btn"
      (click)="setDisplayMode('EXISTING')"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>

<app-name-search-prompt 
  #companyNameSearch 
  [hidden]="!isSearch()"
  [searchMode]="'UB04_PAYER'" 
  [selectMode]="displayMode.split('_').length > 1 ? displayMode.split('_')[1] : 'MULTI_SELECT'"
  >
</app-name-search-prompt>

<div
  class="header"
  *ngIf="displayMode === 'ADD_COMPANY'"
>

</div>

<app-ub04-add-company
  #companyAddComponent
  [hidden]="displayMode !== 'ADD_COMPANY'"
>
</app-ub04-add-company>

<mat-dialog-actions class="fennec-controls">
  <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
    Mark Complete
  </mat-checkbox>
  
  <button 
    mat-flat-button 
    color="accent"
    *ngIf="displayMode === 'EXISTING' && (primaryPayerComponent.selectedCompany !== null || deletedPayers.length > 0)"
    [disabled]="!checkValidity()"
    (click)="onSave()">
    <mat-icon>save</mat-icon> Save
  </button>

  <button 
    mat-flat-button 
    color="primary"
    *ngIf="displayMode.startsWith('SEARCH') || displayMode === 'ADD_COMPANY'"
    (click)="setDisplayMode('EXISTING')">
    <mat-icon>close</mat-icon> Cancel
  </button>
  
  <button 
    mat-flat-button 
    color="primary"
    (click)="onCancel()">
    <mat-icon>cancel</mat-icon> Close
  </button>
</mat-dialog-actions>