<div *ngIf="itemizedRevisionList.length == 0" class="empty-itemized-revision-list-div">
  No Itemized Revisions currently available for the currently selected Itemized.
  <div class="create-itemized-revision-div">
    <button class="ir-action-button" mat-raised-button color="accent" (click)="createManualItemizedRevision()">Create Manual Itemized Revision</button>
  </div>
</div>

<div *ngIf="itemizedRevisionList.length > 0" class="itemized-revision-list-div">
  <mat-grid-list cols="{{itemizedRevisionList.length}}" rowHeight="fit" gutterSize="15px">
    <ng-container *ngFor="let ir of itemizedRevisionList">
      <mat-grid-tile>
        <mat-card class="mat-elevation-z8 itemized-revision-card" [ngStyle]="getSelectedStyle(ir.id)">
          <mat-card-header>
            <mat-card-title>Itemized Revision (Id: {{ir.id}})</mat-card-title>
            <mat-card-subtitle>{{ir.name}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <table style="width: 100%">
              <tr>
                <td>Origin: {{ir.itemizedRevisionOrigin}}</td>
                <td *ngIf="ir.apeProcessed"><img class="ape-processed-img" src="../assets/ape.png"></td>
                <td>
                  <table class="itemized-revision-summary-table">
                    <tr>
                      <td class="irev-summary-label-td">Line Items: </td>
                      <td class="itemized-revision-summary-number">{{ir.lineItemCount}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Total Bill Amt: </td>
                      <td class="itemized-revision-summary-number">{{ir.info.totalBilledAmount | currency}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Total Alwd Amt: </td>
                      <td class="itemized-revision-summary-number">{{ir.info.totalAdjustedAmount | currency}}</td>
                    </tr>
                    <tr>
                      <td class="irev-summary-label-td">Savings Amt: </td>
                      <td [ngClass]="{'itemized-revision-summary-savings': (ir.info.totalBilledAmount - ir.info.totalAdjustedAmount) > 0, 'itemized-revision-summary-no-savings' : (ir.info.totalBilledAmount - ir.info.totalAdjustedAmount) == 0}">
                        {{ir.info.totalBilledAmount - ir.info.totalAdjustedAmount | currency}}
                      </td>
                    </tr>                     
                  </table>
                </td>
              </tr>
            </table>
          </mat-card-content>

          <mat-card-actions>
            <button mat-raised-button color="accent" (click)="selectItemizedRevision(ir.id)">Select</button>
          </mat-card-actions>    
        </mat-card>
      </mat-grid-tile>
    </ng-container>   
  </mat-grid-list>

  <mat-paginator
    #paginator
    [pageSize]="defaultPageSize"
    [length]="totalRowCount"
    (page)="pageHandler()"
  ></mat-paginator>

  <div class="itemized-revision-actions">
    <!-- Removed from Web for now - we may bring these back in the future 
    <button class="ir-action-button" mat-raised-button color="accent" (click)="cloneItemizedRevision()">
      <mat-icon>content_copy</mat-icon> Clone Revision</button>
    <button class="ir-action-button" mat-raised-button color="accent" (click)="sendToAPE()">
      <mat-icon>insights</mat-icon> Send to APE
    </button> -->
    <button class="ir-action-button" mat-raised-button color="accent" (click)="setDataEntryServiceDateRange()">
      <mat-icon>date_range</mat-icon> 
        Set Service Date Range (Data Entry)
        <ng-container *ngIf="selectedItemizedRevision?.info?.deServiceStartDateString != null">
        <br>
        {{selectedItemizedRevision.info.deServiceStartDateString}} - {{selectedItemizedRevision.info.deServiceEndDateString}} 
        </ng-container>
      </button>
    <button class="ir-action-button" mat-raised-button color="accent" (click)="goToItemizedLineItems()"><mat-icon>list_alt</mat-icon>Access Itemized Line Items</button>
    <!-- Removed from Web for now - we may bring these back in the future 
    <button class="ir-action-button" mat-raised-button color="accent" (click)="createProviderReportPDF()"><mat-icon>note_add</mat-icon>Generate Itemized Provider Report</button>
    <button class="ir-action-button" mat-raised-button color="accent" (click)="createItemizedClientReportPDF()"><mat-icon>note_add</mat-icon>Generate Itemized Client Report</button>
    -->

    <button
      mat-raised-button
      class="ir-action-button"
      (click)="openCommentsDialog()"
      color="accent"
    >
      <mat-icon>comments</mat-icon>
      Comments
    </button>
  </div>

</div>
