import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MICaseService } from "@app/mi-case/mi-case.service";
import { KeycloakService } from "keycloak-angular";
import {BaseComponent, DateUtil, FennecSnackbarService, Logger} from "xf-common";

@Component({
  selector: 'app-case-edit',
  templateUrl: './case-edit.component.html',
  styleUrls: ['./case-edit.component.scss']
})
export class CaseEditComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("CaseEditComponent");
  case: any;
  formGroup!: FormGroup;
  errorMessage!: string;
  typeList: any[] = [];
  linesOfBusinessList: any[] = [];
  customerPriorityList: any[] = [];
  providerParList: any[] = [];

  isAdmin = false;
  canUpdateType = false;
  canUpdatePriority = false;
  canUpdateLOB = false;

  requestsDone: number = 0;

  constructor(
    public dialogRef: MatDialogRef<CaseEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    protected snack: FennecSnackbarService,
    private miCaseService: MICaseService,
    protected keycloakService: KeycloakService
  ) {
    super();
    this.getCaseTypes();
    this.getLinesOfBusiness();
    this.getCustomerPriorities();
    this.getProviderPar();
    // console.log(this.data.miCase);
    this.isAdmin = keycloakService.isUserInRole("ADMIN");
    this.canUpdateType = keycloakService.isUserInRole("CASE_TYPE_UPDATE") || this.isAdmin;
    this.canUpdatePriority = keycloakService.isUserInRole("CASE_PRIORITY_UPDATE") || this.isAdmin;
    this.canUpdateLOB = keycloakService.isUserInRole("CASE_LINE_OF_BUSINESS_UPDATE") || this.isAdmin;
  }

  ngOnInit(): void {
    this.case = this.data.miCase;
    if (this.case == null) {
      this.data.case = {
        altExternalId: "Unknown",
      }
      this.snack.showErrorSnack("Technical issue: No case found. Please contact system support, or try again.");
    }
  }

  createForm() {
    if (this.requestsDone < 4) return;
    this.formGroup = this.fb.group({
      name: [this.case?.name, Validators.required],
      altExternalId: [this.case?.altExternalId],
      salesforceCaseId: [this.case?.salesforceCaseId, null],
      trialCase: [this.case?.trialCase, null],
      caseReviewDueDate: [this.case?.caseReviewDueDate, null],
      type: [this.case?.type, Validators.required],
      lineOfBusiness: [this.case?.lineOfBusiness, Validators.required],
      customerPriority: [this.case?.customerPriority, Validators.required],
      clientClaimId: [this.case?.clientClaimId],
      providerPar: [this.case?.providerPar, Validators.required],
      preReviewPayable: [this.case?.preReviewPayable],
      postReviewPayable: [this.case?.postReviewPayable],
      drgPaidDate: [this.case?.drgPaidDate, DateUtil.dateStringValidator()],
      drgPaymentAmount: [this.case?.drgPaymentAmount],
      networkDiscount: [this.case?.networkDiscount],
      notes: [this.case?.notes],
      clientMemberNumber: [this.case?.clientMemberNumber],
      postPaymentReview: [this.case?.postPaymentReview],
      insuredGroupName: [this.case?.insuredGroupName, Validators.maxLength(50)],
      insuredGroupNo: [this.case?.insuredGroupNo, Validators.maxLength(50)],
      clientInternalAdjustments: [this.case?.clientInternalAdjustments],
      postAppealPayable: [this.case?.postAppealPayable]
    });
  }

  onSave() {
    const value = this.formGroup.value;
    this.case['altExternalId'] = value?.altExternalId;
    this.case['name'] = value?.name;
    this.case['salesforceCaseId'] = value?.salesforceCaseId;
    this.case['trialCase'] = value?.trialCase;
    this.case['type'] = value?.type;
    this.case['lineOfBusiness'] = value?.lineOfBusiness;
    this.case['customerPriority'] = value?.customerPriority;
    this.case['caseReviewDueDate'] = DateUtil.inputDateStringToFennecDBDateString(value?.caseReviewDueDate)
    this.case['clientClaimId'] = value?.clientClaimId;
    this.case['providerPar'] = value?.providerPar;
    this.case['preReviewPayable'] = value?.preReviewPayable;
    this.case['postReviewPayable'] = value?.postReviewPayable;
    this.case['drgPaidDate'] = value?.drgPaidDate;
    this.case['drgPaymentAmount'] = value?.drgPaymentAmount;
    this.case['networkDiscount'] = value?.networkDiscount;
    this.case['notes'] = value?.notes;
    this.case['clientMemberNumber'] = value?.clientMemberNumber;
    this.case['postPaymentReview'] = value?.postPaymentReview;
    this.case['insuredGroupName'] = value?.insuredGroupName;
    this.case['insuredGroupNo'] = value?.insuredGroupNo;
    this.case['clientInternalAdjustments'] = value?.clientInternalAdjustments;
    this.case['postAppealPayable'] = value?.postAppealPayable;

    let dialogResult = {
      confirm: true,
      case: this.case
    };
    this.dialogRef.close(dialogResult);
  }

  onCancel() {
    let dialogResult = {
      confirm: false
    };
    this.dialogRef.close(dialogResult);
  }


  getCaseTypes() {
    this.performXFRequest({
      requestDescription: "Get Case Types",
      requestFn: this.miCaseService.getCaseTypes,
      fnParams: [],
      onResponse: (response) => {
        if (response.hasErrors) {
          this.showErrorSnack(response.consolidatedErrorMessage ?? "Error retrieving Stay Types");
        } else {
          this.requestsDone++;
          this.typeList = response.data as any as any[]; // keep this. compiler is confused
          this.createForm();
        }
      }
    });
  }

  getLinesOfBusiness() {
    this.performXFRequest({
      requestDescription: "Get Lines of Business",
      requestFn: this.miCaseService.getLineOfBusinessValues,
      fnParams: [],
      onSuccess: (data) => {
        this.requestsDone++;
        this.linesOfBusinessList = data;
        this.createForm();
      }
    });
  }

  getCustomerPriorities() {
    this.performXFRequest({
      requestDescription: "Get Customer Priorities",
      requestFn: this.miCaseService.getCustomerPriorityValues,
      fnParams: [],
      onSuccess: (data) => {
        this.requestsDone++;
        this.customerPriorityList = data;
        this.createForm();
      }
    });
  }

  getProviderPar() {
    this.performXFRequest({
      requestDescription: "Get Provider Par Values",
      requestFn: this.miCaseService.getProviderParValues,
      fnParams: [],
      onSuccess: (data) => {
        this.requestsDone++;
        this.providerParList = data;
        this.createForm();
      }
    });
  }
}
