import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BaseResponse, EnvironmentService, ItemizedLineItemUpdatePacket} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class ItemizedLineItemService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  /* Retired in favor of a PUT method that can send up a request body for
     parameters
  getItemizedLineItemQuery (
    itemizedRevisionId: number,
    filterNo: number = 0,
    description: string,
    serviceDateString: string = "ALL",
    first: number = 0,
    max: number = 100
  ): Observable<BaseResponse> {
    let url = environment.baseApiUrl + "itemized-line-item/query/" + itemizedRevisionId.toString();
    url += "?filterNo=" + filterNo.toString();
    if (description !== null) {
      url += "&description=" + encodeURIComponent(description);
    }
    url += "&serviceDateString=" + serviceDateString;
    url += "&first=" + first.toString();
    url += "&max=" + max.toString();
    return this.httpClient.get<any>(url);
  } */

  getItemizedLineItemQuery(
    itemizedRevisionId: number,
    filterNo: number = 0,
    description: string,
    serviceDateString: string = "ALL",
    revenueCodeStrings: string [] = [],
    mappedRevenueCodeStrings: string [] = [],
    adjusted: string = "ALL",
    apeAdjusted: string = "ALL",
    comments: string = "NA",
    reportExcludeString: string = "ALL",
    sortOrder: number = 0,
    first: number = 0,
    max: number = 100
  ): Observable<BaseResponse> {
    let url = this.environment.baseApiUrl + "itemized-line-item/query/" + itemizedRevisionId.toString();
    url += "?first=" + first.toString();
    url += "&max=" + max.toString();

    // if (description !== null && description !== undefined) {
    //   description = encodeURIComponent(description);
    // }
    let reportExclude = null;
    if (reportExcludeString.toLowerCase() === "true") {
      reportExclude = true;
    } else if (reportExcludeString.toLowerCase() === "false") {
      reportExclude = false;
    }

    const filterParams = {
      filterNo: filterNo,
      sortOrder: sortOrder,
      description: description,
      serviceDateString: serviceDateString,
      revenueCodeStrings: revenueCodeStrings,
      mappedRevenueCodeStrings: mappedRevenueCodeStrings,
      adjusted: adjusted,
      apeAdjusted: apeAdjusted,
      comments: comments,
      reportExclude: reportExclude
    }

    return this.httpClient.put<any>(url, filterParams);
  }

  deleteLineItem(id: number): Observable<BaseResponse> {
    return this.httpClient.delete<any>(this.environment.baseApiUrl + "itemized-line-item/" + id.toString());
  }

  /*
  putItemizedLineItemAdjustment(payload: ItemizedLineItemAdjustmentPacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/adjustment", payload);
  } */

  putItemizedLineItemBulkUpdate(payload: ItemizedLineItemUpdatePacket []): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/bulkupdate", payload);
  }

}
