import {ExtractInlineParams, XFRequestHandler, VarLengthTuple} from "../model/safe-request";

// @ts-ignore
export function emissionWrapper<F extends XFRequestHandler<P>, P extends VarLengthTuple<any> = ExtractInlineParams<F>>(
  fn: F | F[] | undefined
): (...params: P) => void {
  if (!fn) {
    return () => {
    };
  } else {
    return (...fnParams: P) => {
      const params: P = (fnParams as P) || ([] as any);
      setTimeout(() => {
        if (Array.isArray(fn)) {
          fn.forEach(f => {
            if (typeof f === "function") {
              f(...params)
            } else if (f != null) {
              console.error(`Invalid parameter provided to emission wrapper: ${f}`);
            }
          });
        } else {
          fn(...params);
        }
      })
    }
  }
}
