export enum LogLevel {
  DEBUG,
  INFO,
  WARNING,
  ERROR
}

export type LogOutput = (source: string, level: LogLevel, ...objects: any[]) => void;

export class Logger {
  static productionMode = false;
  static environmentLogLevel = LogLevel.DEBUG;

  static setProductionMode(productionMode: boolean) {
    Logger.productionMode = productionMode;
    Logger.environmentLogLevel = Logger.productionMode ? LogLevel.WARNING : LogLevel.DEBUG;
  }

  constructor(private source: string) {
  }

  debug(...objects: any[]) {
    this.log(console.debug, LogLevel.DEBUG, objects);
  }

  info(...objects: any[]) {
    this.log(console.info, LogLevel.INFO, objects);
  }

  warn(...objects: any[]) {
    this.log(console.warn, LogLevel.WARNING, objects);
  }

  error(...objects: any[]) {
    this.log(console.error, LogLevel.ERROR, objects);
  }

  group(name: string, ...data: any) {
    console.group(name, ...data);
  }

  groupEnd() {
    console.groupEnd();
  }

  private log(func: Function, level: LogLevel, ...objects: any[]) {
    if (level >= Logger.environmentLogLevel) {
      const log = [`${this.source} | `].concat(objects);
      func.apply(console, log);
    }
  }
}
