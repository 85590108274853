import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CodeValidationService } from '@app/codes/code-validation.service';
import { Subject } from 'rxjs';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import {BaseResponse, FennecSnackbarService, ItemizedLineItemUpdatePacket} from "xf-common";

@Component({
  selector: 'app-itemized-line-item-bulk-editor',
  templateUrl: './itemized-line-item-bulk-editor.component.html',
  styleUrls: ['./itemized-line-item-bulk-editor.component.scss']
})
export class ItemizedLineItemBulkEditorComponent implements OnInit, AfterViewInit {

  @Input()
  itemizedRevisionId: string = "-1";

  @ViewChildren('serviceDateInput')
  serviceDateInputs?:QueryList<any>;

  // Form Group to manage edit bindings.
  formGroup: FormGroup = new FormGroup({
    lineItems: new FormArray([])
  });

  lineItemSaveComplete: Subject<any> = new Subject();
  lineItemEditCancel: Subject<any> = new Subject();

  constructor(
    private codeValidationService: CodeValidationService,
    private itemizedLineItemService: ItemizedLineItemService,
    private snack: FennecSnackbarService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  /* Get's the lineItems formArray from the form group. This will be an array of FormGroups that
  models an entire line item row for each element in the array. Note how it's coded as a 'getter'
  at the class level. This is done so the HTML can properly see this control array when rendering. */
  get lineItems(): FormArray {
    return this.formGroup.controls["lineItems"] as FormArray;
  }

  /* Get's a single revenue code formGroup control from the revenueCodes FormArray in the parent FormGroup.
  Note how it's modelled as a getter at the class level. This is done so the HTML can properly see this
  control when rendering. */
  getLineItemFormGroup(idx: number): FormGroup {
    return this.lineItems.at(idx) as FormGroup;
  }

  /*
  Pass in an array of ItemizedLineItemUpdatePackets to pre-populate the bulk line item formgroup for
  for editing. You can pass in blank line items (id = -1) if you wish to prep these line items for
  adding.
  */
  populateLineItemsOnFormGroup(inputLineItems:ItemizedLineItemUpdatePacket []) {
    this.lineItems.clear();
    // Create the new line items specified
    //let idx = 0;
    //for (idx = 0; idx < this.newLineItemCount; idx++) {
    //  this.onAddLineItemToFormGroup();
    //}
    inputLineItems.forEach((li:ItemizedLineItemUpdatePacket) => {
      this.onAddLineItemToFormGroup(li);
    });

    // Set's focus to first form control on the form
    this.cdRef.detectChanges();
    if (!!this.serviceDateInputs && !!this.serviceDateInputs.first) {
      setTimeout(() => {
        if (!!this.serviceDateInputs && !!this.serviceDateInputs.first) {
          this.serviceDateInputs.first.nativeElement.focus();
        }
      }, 500)
    }

  }

  /* This will add a new, blank revenue code line (FormGroup) so the user can enter a new ub04 revenue code */
  onAddLineItemToFormGroup(li:ItemizedLineItemUpdatePacket) {
    if (li.billPageNo === null || li.billPageNo === undefined) {
      li.billPageNo = 0;
    }
    if (li.billLineNo === null || li.billLineNo === undefined) {
      li.billLineNo = 0;
    }
    let rcFormGroup: FormGroup = new FormGroup({
      id: new FormControl(li.id),
      serviceDate: new FormControl(li.serviceDateString, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      revenueCode: new FormControl(li.revenueCodeString, [Validators.required, Validators.maxLength(4)]),
      cptCode: new FormControl(li.cptCode),
      revenueCodeDescription: new FormControl(li.revenueCodeDescription),
      description: new FormControl(li.description),
      units: new FormControl(li.units, Validators.required),
      billedAmount: new FormControl(li.billedAmount, Validators.required),
      adjustmentReasonCode: new FormControl(li.adjustmentReasonCode ?? "N/A"),
      adjustmentReasonCodeDescription: new FormControl(li.adjustmentReasonCodeDescription ?? "N/A"),
      adjustmentExplanationCode: new FormControl(li.adjustmentExplanationCode ?? "N/A"),
      adjustmentExplanationCodeDescription: new FormControl(li.adjustmentExplanationCodeDescription ?? "N/A"),
      billPageNo: new FormControl(li.billPageNo),
      billLineNo: new FormControl(li.billLineNo),
      preExAdjustedAmount: new FormControl(li.preExAdjustedAmount),
      preExExplanation: new FormControl(li.preExExplanation),
      preExAuditComments: new FormControl(li.preExAuditComments),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });

    rcFormGroup.controls['revenueCodeDescription'].disable();
    rcFormGroup.controls['adjustmentReasonCode'].disable();
    rcFormGroup.controls['adjustmentExplanationCode'].disable();
    rcFormGroup.controls['adjustmentReasonCodeDescription'].disable();
    rcFormGroup.controls['adjustmentExplanationCodeDescription'].disable();
    // Chatty revenue code validation during data entry
    rcFormGroup.valueChanges.subscribe((val) => {
      this.validateRevenueCode(rcFormGroup);
    });

    this.lineItems.push(rcFormGroup);
  }

  getIconStyle(idx: number){
    let rcFormGroup: FormGroup = this.getLineItemFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getLineItemFormGroup(idx);
    // These are newly added by user during this session. Just remove them from the
    // form group list.
    if (rfg.controls["id"].value < 0) {
      this.lineItems.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  getDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getLineItemFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  saveLineItems() {
    this.formGroup.markAllAsTouched();
    let updDto: ItemizedLineItemUpdatePacket [] = [];
    let rcRows: FormArray = this.lineItems;
    rcRows.controls.forEach((rcRow: any) => {
      let revCode = rcRow.controls["revenueCode"].value;
      if (revCode !== null && revCode !== undefined) {
        let liDto: ItemizedLineItemUpdatePacket = new ItemizedLineItemUpdatePacket();
        //rcDto.id = rcRow.controls["id"].value;
        liDto.id = rcRow.controls["id"].value;
        liDto.itemizedRevisionId = parseInt(this.itemizedRevisionId, 10);
        liDto.serviceDateString = rcRow.controls["serviceDate"].value; // TODO: make this editable
        liDto.revenueCodeId = -1;
        liDto.revenueCodeString = rcRow.controls["revenueCode"].value;
        liDto.cptCode = rcRow.controls["cptCode"].value;
        liDto.units = rcRow.controls["units"].value;
        liDto.adjustedAmount = rcRow.controls["billedAmount"].value;
        liDto.billedAmount = rcRow.controls["billedAmount"].value;
        liDto.description = rcRow.controls["description"].value;
        // liDto.adjustmentReasonCode = rcRow.controls["adjustmentReasonCode"].value;
        // liDto.adjustmentExplanationCode = rcRow.controls["adjustmentExplanationCode"].value;
        //liDto.deleteRow = rcRow.controls["deleteRow"].value;
        liDto.billPageNo = rcRow.controls["billPageNo"].value;
        liDto.billLineNo = rcRow.controls["billLineNo"].value;
        liDto.preExAdjustedAmount = rcRow.controls["preExAdjustedAmount"].value;
        liDto.preExExplanation = rcRow.controls["preExExplanation"].value;
        liDto.preExAuditComments = rcRow.controls["preExAuditComments"].value;
        updDto.push(liDto);
      }
    });

    this.itemizedLineItemService.putItemizedLineItemBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.lineItemSaveComplete.next(null);
      }
    });

  }

  cancelEdit() {
    this.lineItemEditCancel.next(null);
  }

  /* Chatty revenue code validation during data entry. This will validate a revenue code that the
  user is trying to enter. The passed in revenueCodeRow is just the object of form 'values' and has
  no pointer to the control itself firing the event. Therefore, we use the tempKey we assigned
  earlier to find the control that is firing the event so we can introspect it and set validation
  status's on it. This is kind of lame, and I wish Angular provided a more straightforward way of
  doing this. But this works. It just seems like too much work to achieve something that should
  already be out-of-the-box from Angular */
  validateRevenueCode(row: FormGroup) {
    let rcFormControl: FormControl = row.controls['revenueCode'] as FormControl;
    let revCodeDescriptionFormControl: FormControl = row.controls['revenueCodeDescription'] as FormControl;

    if (!rcFormControl.pristine) {
      if (rcFormControl.value.length === 3 && !rcFormControl.value.toString().startsWith("0")) {
        rcFormControl.setValue("0" + rcFormControl.value, {onlySelf: true, emitEvent: false});
      }
      if (rcFormControl.value.length !== 4) {
        rcFormControl.markAsPristine();
        revCodeDescriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        rcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getRevenueCode(rcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            rcFormControl.markAsPristine();
            rcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              rcFormControl.markAsPristine();
              revCodeDescriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              rcFormControl.setErrors(null);
            } else {
              revCodeDescriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              rcFormControl.markAsPristine();
              rcFormControl.setErrors({'incorrect': true});

            }
          }
        });
      }
    }
  }

  validateAdjustmentReasonCode(reasonCodeRow: any) {
    let firingControl: FormGroup = this.findControlUsingTempKey(reasonCodeRow.tempKey);
    let arcFormControl: FormControl = firingControl.controls['adjustmentReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = firingControl.controls['adjustmentReasonCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
      }else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getAdjustmentReasonCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
            }
          }
        });
      }
    }
  }

  validateAdjustmentExplanationCode(explanationCodeRow: any) {
    let firingControl: FormGroup = this.findControlUsingTempKey(explanationCodeRow.tempKey);
    let arcFormControl: FormControl = firingControl.controls['adjustmentExplanationCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = firingControl.controls['adjustmentExplanationCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
      }else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getAdjustmentExplanationCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
            }
          }
        });
      }
    }
  }

  /* Given a tempKey - find the FormGroup (i.e. line item row) control that goes with it */
  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.lineItems.length; idx++) {
      let rc: FormGroup = this.getLineItemFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

}
