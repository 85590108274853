<div *ngIf="ub04List.length == 0" class="ub04-select-no-ub04s-found">
  No UB04(s) found for Case!
</div>
<div *ngIf="ub04List.length > 0" class="list-wrapper">
  <mat-table [dataSource]="ub04List">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
      <mat-cell *matCellDef="let result">{{result?.id}}</mat-cell>
    </ng-container>


    <ng-container matColumnDef="npiNumber">
      <mat-header-cell *matHeaderCellDef>NPI Number</mat-header-cell>
      <mat-cell *matCellDef="let result">{{result?.npiNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
      <mat-cell *matCellDef="let result">{{result?.createdBy}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <mat-header-cell *matHeaderCellDef>Patient Name</mat-header-cell>
      <mat-cell *matCellDef="let result">{{result?.lastName}}, {{result?.firstName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let result">
        <button
          mat-raised-button
          color="accent"
          (click)="addUB04ToItemized(result?.id)"
        >
          <mat-icon>queue</mat-icon>
          Select
        </button>
      </mat-cell>
    </ng-container>

    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        ></mat-row>
        <!-- (click)="onSelect(row)" -->
  </mat-table>
</div>
