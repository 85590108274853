export class ProviderPacket {
  id?: number;
  providerType?: string;
  npiNumber?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  addressId?: number | null;
  taxonomyCode?: string;
  taxonomyCodeDescription?: string;
}
