import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FennecSnackbarService } from '../fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-single-choice-dialog',
  templateUrl: './single-choice-dialog.component.html',
  styleUrls: ['./single-choice-dialog.component.scss']
})
export class SingleChoiceDialogComponent implements OnInit {

  question: string = "Developer! Please provider a question in data.question!";
  infoLine1: string | null = null;

  /*
    buttonMode determines the displayed buttons on the Single Choice Dialog component.
    "YES/NO" - original mode.  Displays two buttons, "yes" that confirms and "no" that closes the dialog.
    "OK" - Shows single button.  Closes dialog with confirm message.
  */
  buttonMode:string = "YES/NO";

  constructor(
    public dialogRef: MatDialogRef<SingleChoiceDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (data.question !== null && data.question !== undefined) {
      this.question = data.question;
    }
    if (data.infoLine1 !== null && data.infoLine1 !== undefined) {
      this.infoLine1 = data.infoLine1;
    }
    if(data.buttonMode !== null && data.buttonMode !== undefined) {
      this.buttonMode = data.buttonMode;
    }
   }

  ngOnInit(): void {
  }

  onYes() {
    const returnObj = {
      confirm: true
    }
    this.dialogRef.close(returnObj);
  }

  onNo() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
