import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import { BaseCrudService, Crud, DataResponse, EnvironmentService, HealthCarePlanPacket, PagedResponse } from "xf-common";


@Injectable({
  providedIn: 'root'
})
export class HealthCarePlanService {

  private baseEndpoint = `${this.environment.baseApiUrl}health-care-plan/`;

  constructor(
      private httpClient: HttpClient,
      private environment: EnvironmentService
  ) {}

  getInfo = (id:number) => {
    const endpoint = `${this.baseEndpoint}info/${id}`;
    return this.httpClient.get<DataResponse<HealthCarePlanPacket>>(endpoint);
  }

}
