import { AfterViewInit, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivityLogDialogConfig } from '../../model/activity-log-dialog-config';
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { ActivityLogService } from '../activity-log.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { registerCustomQueryHandler } from 'puppeteer';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivityLogJsonDialogComponent } from '../activity-log-json-dialog/activity-log-json-dialog.component';

@Component({
  selector: 'app-activity-log-dialog',
  templateUrl: './activity-log-dialog.component.html',
  styleUrls: ['./activity-log-dialog.component.scss']
})
export class ActivityLogDialogComponent implements AfterViewInit, OnInit {

  configData: ActivityLogDialogConfig;

  dataSource = new MatTableDataSource<any>();
  displayColumns = [];  

  @ViewChild('adPaginator')
  paginator?: MatPaginator;

  noData: boolean = true;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];  

  constructor(
    private activityLogService: ActivityLogService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ActivityLogDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: ActivityLogDialogConfig
  ) {
    this.configData = data;
    if (this.configData.ub04ChildEntity) {
      this.displayColumns = ['activityLogType', 'ub04ChildCode', 'beforeData', 'afterData', 'createdBy', 'createdDate'];  
    } else {
      this.displayColumns = ['activityLogType', 'beforeData', 'afterData', 'createdBy', 'createdDate'];  
    }
  }

  ngOnInit(): void {
    this.getActivityLog();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.getActivityLog();
        });
      }
    }, 200);
  }

  getActivityLog() {
    const user = "ALL";
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    const entity = this.configData.activityLogEntity;
    const relatedId = this.configData.relatedId;

    this.noData = true;

    if (this.configData.ub04ChildEntity) {
      this.activityLogService.getAllActivityLogForUB04Entity(this.configData.ub04Id, entity, first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.dataSource.data = response.data;
          this.totalRowCount = response.totalRowCount;
          this.formatDataForDisplay();
          if (response.data.length > 0) {
            this.noData = false;
          }
        }
      });
    } else {
      this.activityLogService.getAllActivityLogForEntityRelatedId(entity, relatedId, user, first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.dataSource.data = response.data;
          this.totalRowCount = response.totalRowCount;
          this.formatDataForDisplay();
          if (response.data.length > 0) {
            this.noData = false;
          }
        }
      });
    }
  }

  close() {
    const retObj = {
      confirm: false
    }
    this.dialogRef.close(retObj);
  }

  moreInfoDialog(x: any) {

  }

  formatDataForDisplay() {
    if (this.dataSource.data === null || this.dataSource.data === undefined) {
      return;
    }
    this.dataSource.data.forEach((rec) => {
      var obj = JSON.parse(rec.data);
      rec['beforeData'] = obj['beforeData'];
      rec['afterData'] = obj['afterData'];
    }); 
  }

  viewJson(title: string, json: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = { 
      title: title,
      jsonContent: JSON.parse(json),
    }
    const dialogRef = this.dialog.open(ActivityLogJsonDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

}
