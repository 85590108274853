import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  AttachmentPacket,
  BaseService,
  DataResponse,
  EnvironmentService,
  FilePacket,
  ItemizedUpdatePacket,
  Logger
} from "xf-common";

@Injectable()
export class AttachmentService extends BaseService {
  baseEndpoint = this.apiEndpoint("attachment");

  constructor(
    public httpClient: HttpClient,
    public environment: EnvironmentService
  ) {
    super(new Logger("AttachmentService"));
  }

  getAllAttachmentsforMICase(miCaseId: number, first: number = 0, max: number = 9999): Observable<any> {
    return this.httpClient.get<any>(this.baseEndpoint + "/list/mi-case/" + miCaseId.toString()
      + "?first=" + first + "&max=" + max);
  }

  getAllAttachmentsforMICaseDataEntry(miCaseId: number, first: number = 0, max: number = 9999): Observable<any> {
    return this.httpClient.get<any>(this.baseEndpoint + "/data-entry-list/mi-case/" + miCaseId.toString()
      + "?first=" + first + "&max=" + max);
  }

  createAttachment = (attachment: AttachmentPacket): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.post<DataResponse<boolean>>(endpoint, attachment);
  };

  updateAttachment = (attachment: AttachmentPacket): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, attachment);
  }

  getAttachmentInfo = (attachmentId: number): Observable<DataResponse<FilePacket>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}`;
    return this.httpClient.get<DataResponse<FilePacket>>(endpoint);
  }

  downloadAttachment = (attachmentId: number): Observable<DataResponse<FilePacket>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/download`;
    return this.httpClient.get<DataResponse<FilePacket>>(endpoint);
  }

  emailAttachment = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/email`;
    return this.httpClient.get<DataResponse<any>>(endpoint);
  }

  getAttachmentTypes = (): Observable<DataResponse<String[]>> => {
    const endpoint = `${this.baseEndpoint}/types`;
    return this.httpClient.get<DataResponse<String[]>>(endpoint);
  }

  getAttachmentTypesExtended = (): Observable<DataResponse<any[]>> => {
    const endpoint = `${this.baseEndpoint}/types-extended`;
    return this.httpClient.get<DataResponse<any[]>>(endpoint);
  }

  convertToUB04 = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/toub04`;
    return this.httpClient.post<DataResponse<any>>(endpoint, {});
  };

  convertToItemized = (attachmentId: number, dto: ItemizedUpdatePacket): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/toitemized`;
    return this.httpClient.post<DataResponse<any>>(endpoint, dto);
  };

  deleteAttachment = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  }

  toggleAttachmentReportReceived = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/report-received/${attachmentId}`;
    return this.httpClient.post<DataResponse<any>>(endpoint, null);
  }

}
