import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DashboardFilterParamComponent } from '../dashboard-filter-param/dashboard-filter-param.component';
import {BaseComponent, DateUtil, FennecSnackbarService, Logger} from "xf-common";
@Component({
  selector: 'app-dashboard-filter-param-dialog',
  templateUrl: './dashboard-filter-param-dialog.component.html',
  styleUrls: ['./dashboard-filter-param-dialog.component.scss']
})
export class DashboardFilterParamDialogComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("DashboardFilterParamDialogComponent");

  title: string = "Filter Parameters";
  paramConfig: any [] = [];

  isLoaded = false;

  @ViewChild(DashboardFilterParamComponent)
  dashboardFilterParamComponent?: DashboardFilterParamComponent;

  constructor(
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashboardFilterParamDialogComponent>
  ) {
    super();
   }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.data.paramConfig != null) {
        this.paramConfig = this.data.paramConfig;
        this.isLoaded = true;
    }});
  }

  ngOnInit(): void {
    this.title = this.data.title;
  }

  isFormGroupValid(): boolean {
    if (!this.isLoaded) {
      return false;
    }
    if (this.dashboardFilterParamComponent != null
      && this.dashboardFilterParamComponent.formGroup != null
      && this.dashboardFilterParamComponent.formGroup.valid) {
        return true;
      } else {
        return false;
      }
  }

  submit() {
    if (!this.validateFilterParams()) {
      return;
    }
    let filterParams = this.parseAndFormatFilterParams();
    const responseObj = {
      confirm : true,
      params: filterParams,
    }
    this.dialogRef.close(responseObj);
  }

  cancel() {
    const responseObj = {
      confirm : false
    }
    this.dialogRef.close(responseObj);
  }

  validateFilterParams(): boolean {
    let paramsAreValid: boolean = true;
    this.paramConfig.forEach((pc: any) => {
      let filterParam: any = {};
      filterParam.type = pc.dashboardFilterParamType;
      filterParam.value = this.dashboardFilterParamComponent?.formGroup.controls[pc.formControlName].value;
      if (filterParam.value != null && pc.dataType.toLowerCase() === "primary_client") {
        if (filterParam.value == null || filterParam.value === "") {
          this.showErrorSnack("At least one Primary Client is required!");
          paramsAreValid = false;
        }
      } else if (filterParam.value != null && pc.dataType.toLowerCase() === "health_care_plan") {
        if (filterParam.value == null || filterParam.value === "") {
          this.showErrorSnack("At least one Health Care Plan is required!");
          paramsAreValid = false;
        }
      } else if (pc.required) {
        if (Array.isArray(filterParam.value) && filterParam.value.length === 0) {
          this.showErrorSnack("At least one required parameter value is missing!");
          paramsAreValid = false;
        } else if (filterParam.value == null || filterParam.value === "") {
          this.showErrorSnack("At least one required parameter value is missing!");
          paramsAreValid = false;
        }
      }
    });
    return paramsAreValid;
  }

  // Grabs the filter parameter values off of the form controls and returns them in an array to be passed on
  // to interested consumers (ex: server query call).
  parseAndFormatFilterParams(): any [] {
    let filterParams: any [] = [];
    this.paramConfig.forEach((pc: any) => {
      let filterParam: any = {};
      filterParam.type = pc.dashboardFilterParamType;
      filterParam.value = this.dashboardFilterParamComponent?.formGroup.controls[pc.formControlName].value;
      // Dates should come off the form control(s) as MM/DD/YYYY - convert them to YYYY-MM-DD.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "date") {
        filterParam.value = DateUtil.inputDateStringToFennecDBDateString(filterParam.value);
      }
      // Primary clients is an array that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "primary_client") {
        filterParam.value = filterParam.value.toString();
      }
      // Health Care Plans is an array that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "health_care_plan") {
        filterParam.value = filterParam.value.toString();
      }
      // Attachment Types is an array that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "attachment_type") {
        filterParam.value = filterParam.value.toString();
      }
      // Line of Business Values (lob) is an array that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "lob") {
        filterParam.value = filterParam.value.toString();
      }
      // MI Case Types is an array that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "mi_case_type") {
        filterParam.value = filterParam.value.toString();
      }
      // LineAdjUsers is an id (numeric) that needs to be converted to a string.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "line_adj_user") {
        filterParam.value = filterParam.value.toString();
      }
      // If no values come off the form controls(s), and there is a default value supplied. Plug it here.
      // Watch dates here - they should be in YYYY-MM-DD format here going forward to the server.
      if (filterParam.value == null && (pc.defaultValue != null)) {
        filterParam.value = pc.defaultValue;
      }
      // Note: these fields are not used by the server even though we send them up anyways.
      // We should probably officially ignore these fields in the incoming server dto, but
      // Jackson ignores these anyways.
      filterParam.label = pc.label;
      filterParam.displayValue = filterParam.value;
      // Dates are YYYY-MM-DD at this point. Provide a display value for UI experience on dates.
      if (filterParam.value !== null && filterParam.value !== undefined && pc.dataType.toLowerCase() === "date") {
        filterParam.displayValue = DateUtil.getDisplayDateYYYY(filterParam.value);
      }
      // PRIMARY CLIENT(s) will be a list of id(s). Let's make the display value a list of the actual names of
      // the primary client.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "primary_client") {
        let pcIds: [] = filterParam.value.toString().split(",");
        let pcDisplayValue = "";
        pcIds.forEach((pcId) => {
          this.dashboardFilterParamComponent?.primaryClients.forEach((pcInfo) => {
            if (parseInt(pcId, 10) === pcInfo.companyId) {
              pcDisplayValue += pcInfo.companyName + ", ";
            }
          });
        });
        if (pcDisplayValue.length > 0) {
          pcDisplayValue = pcDisplayValue.substring(0, pcDisplayValue.length - 2);
          filterParam.displayValue = pcDisplayValue;
        }
      }
      // HEALTH_CARE_PLAN(s) will be a list of id(s). Let's make the display value a list of the actual names of
      // the HC Plans.
      if (filterParam.value != null && pc.dataType.toLowerCase() === "health_care_plan") {
        let hcpIds: [] = filterParam.value.toString().split(",");
        let hcpDisplayValue = "";
        hcpIds.forEach((hcpId) => {
          this.dashboardFilterParamComponent?.healthCarePlans.forEach((hcpInfo) => {
            if (parseInt(hcpId, 10) === hcpInfo.healthCarePlanId) {
              hcpDisplayValue += hcpInfo.healthCarePlanName + ", ";
            }
          });
        });
        if (hcpDisplayValue.length > 0) {
          hcpDisplayValue = hcpDisplayValue.substring(0, hcpDisplayValue.length - 2);
          filterParam.displayValue = hcpDisplayValue;
        }
      }

      // Push into array.
      filterParams.push(filterParam);
    });
    return filterParams;
  }

}
