import { Component, OnInit } from '@angular/core';
import { MICaseService } from '@app/mi-case/mi-case.service';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CreateCaseComponent } from '@app/mi-case/case-create/case-create.component';
import {BaseComponent, FennecSnackbarService, Logger} from "xf-common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit  {
  log: Logger = new Logger("HomeComponent");

  constructor(
    private caseService: MICaseService,
    private dashboardService: DashboardService,
    protected snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private router: Router
  ) {
    super();
   }

  ngOnInit(): void {

  }

  createCase() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    const dialogRef = this.matDialog.open(CreateCaseComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.router.navigateByUrl(`/mi-case/${result.id}`);
      }
    });
  }

  onMICaseList() {
    //this.router.navigateByUrl(`/mi-case-list/${result.id}`);
    this.router.navigateByUrl(`/mi-case-list`);
  }

}
