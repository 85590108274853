import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';


import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { StatusTrackService } from "../../status-track/status-track.service";
import { DateUtil } from '../../util/date-util';
import { UB04Edit1 } from '../../model/net/ub04-edit';
import { Logger } from '../../util/logger';
import { CodeValidationService } from "../../code/code-validation.service";
import { DRGCodePacket } from "../../model/net/drg-code-packet";
import { MICaseService } from '../../mi-case/mi-case.service';

@Component({
  selector: 'app-ub04-gen-ib-request-dialog',
  templateUrl: './ub04-gen-ib-request-dialog.component.html',
  styleUrls: ['./ub04-gen-ib-request-dialog.component.css']
})
export class UB04GenIBRequestDialogComponent implements OnInit  {

  ub04Id: number = -1;

  constructor(
    public dialogRef: MatDialogRef<UB04GenIBRequestDialogComponent>,
    private snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    private miCaseService: MICaseService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.ub04Id = data.ub04Id;
  }

  ngOnInit() {

  }

  fetchData() {

  }

  onSubmit() {

  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  genIBRequestLetter(letterNo: number) {
    let attachmentType = "IB_REQUEST_TEMPLATE_1";
    if (letterNo === 2) {
      attachmentType = "IB_REQUEST_TEMPLATE_2";
    } else if (letterNo === 3) {
      attachmentType = "IB_REQUEST_TEMPLATE_3";
    }

    this.ub04Service.genIBRequestLetter(this.ub04Id, attachmentType).subscribe((serverResponse) => {
      if (serverResponse.hasErrors) {
        this.snack.showErrorSnack("Error submitting request to generate IB request letter: " + serverResponse.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Generate IB Request Letter submitted!");
        const returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    }); 

  }

}
