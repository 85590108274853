<pdf-shy-button
  [cssClass]="show | responsiveCSSClass : 'always-in-secondary-menu'"
  title="Use Horizontal Scrolling"
  primaryToolbarId="scrollHorizontal"
  l10nId="pdfjs-scroll-horizontal-button"
  [toggled]="scrollMode == 1"
  [action]="onClick"
  l10nLabel="pdfjs-scroll-horizontal-button-label"
  [order]="3200"
  [closeOnClick]="false"
  image="<svg class='pdf-margin-top-3px' width='24px' height='24px'> <path fill='currentColor' d='M0 4h1.5c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5H0zM9.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C5 4.5 5.5 4 6.5 4zM16 4h-1.5c-1 0-1.5.5-1.5 1.5v5c0 1 .5 1.5 1.5 1.5H16z' /> </svg>"
>
</pdf-shy-button>
