<mat-card *ngIf="miCase">
  <mat-card-header>
    <mat-card-title>
      <span class="label-text">Main Status:</span> {{miCase.statusTrackStatus}}

      <button
        mat-icon-button
        *ngIf="!userIsDataEntry"
        (click)="handleOpenEditDialog(miCase)"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-title>
      <span class="label-text">Priority:</span> {{miCase.customerPriority ?? "N/A"}}
    </mat-card-title>

    <!-- <mat-card-subtitle>
      {{miCase.type}}
    </mat-card-subtitle> -->
    <!-- <mat-card-subtitle *ngIf="miCase.statusTrackStatus !== 'COMPLETE'">
      <button
        mat-stroked-button
        color="accent"
        (click)="confirmCloseDialog()"
      >
        <mat-icon>check</mat-icon>
        Mark as Complete
      </button>
      <button
        class="mi-case-edit-button"
        mat-flat-button color="accent"
        (click)="handleOpenEditDialog(miCase)">
        <mat-icon>edit</mat-icon>
        Edit
      </button>
    </mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content>
    <div class="info-content">
      <div class="column">
        <p>
          <span class="label-text">Client Name</span>
          -
          <span [attr.data-field]="'name'">{{miCase.name}}</span>
        </p>
        <p>
          <span class="label-text">Alt External ID</span>
          -
          <span [attr.data-field]="'altExternalId'">{{ miCase.altExternalId != null ? miCase.altExternalId : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Salesforce Case ID</span>
          -
          <span [attr.data-field]="'salesforceCaseId'">{{ miCase.salesforceCaseId != null ? miCase.salesforceCaseId : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Trial Case</span>
          -
          <span [attr.data-field]="'trialCase'">{{ miCase.trialCase ? "Yes" : "No" }}</span>
        </p>
        <p>
          <span class="label-text">Case Review Due Date</span>
          -
          <span [attr.data-field]="'caseReviewDueDate'">{{ miCase.caseReviewDueDate != null ? miCase.caseReviewDueDate : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Pre 6DH Review Payable</span>
          -
          <span [attr.data-field]="'preReviewPayable'">{{ miCase.preReviewPayable != null ? (miCase.preReviewPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Post 6DH Review Payable</span>
          -
          <span [attr.data-field]="'postReviewPayable'">{{ miCase.postReviewPayable != null ? (miCase.postReviewPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Post Appeal Payable</span>
          -
          <span [attr.data-field]="'postAppealPayable'">{{ miCase.postAppealPayable != null ? (miCase.postAppealPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Par/Non-Par</span>
          -
          <span [attr.data-field]="'providerPar'">{{ miCase?.providerPar ?? "N/A" }}</span>
        </p>
      </div>
      <div class="column">
        <p>
          <span class="label-text">Primary Client</span>
          -
          <span [attr.data-field]="'primaryClientName'">{{miCase.primaryClientName}}</span>
        </p>
        <p>
          <span class="label-text">Plan </span>
          -
          <span [attr.data-field]="'healthCarePlanName'">{{miCase?.healthCarePlanName ? miCase.healthCarePlanName + " - " + miCase.healthCarePlanStateCode : "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Client Claim ID</span>
          -
          <span [attr.data-field]="'clientClaimId'">{{miCase.clientClaimId ?? "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Client Member Number</span>
          -
          <span [attr.data-field]="'clientMemberNumber'">{{miCase.clientMemberNumber ?? "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Created Date</span>
          -
          <span [attr.data-field]="'createdDate'">{{miCase.createdDate | date:'MM/dd/yyyy h:mm a'}}</span>
        </p>
        <p>
          <span class="label-text">Created By</span>
          -
          <span [attr.data-field]="'createdUser'">{{miCase.createdUser}}</span>
        </p>
        <p>
          <span class="label-text">Type</span>
          -
          <span [attr.data-field]="'type'">{{miCase.type ?? "N/A"}}</span>
        </p>
        <!-- moved to top -->
        <!-- <p>
          <span class="label-text">Priority</span> - {{miCase.customerPriority}}
        </p> -->
        <p>
          <span class="label-text">DRG Paid Date</span>
          -
          <span [attr.data-field]="'drgPaidDate'">{{ miCase.drgPaidDate != null ? (miCase.drgPaidDate) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">DRG Payment Amount</span>
          -
          <span [attr.data-field]="'drgPaymentAmount'">{{ miCase.drgPaymentAmount != null ? (miCase.drgPaymentAmount | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Client Internal Adjustments</span>
          -
          <span [attr.data-field]="'clientInternalAdjustments'">{{ miCase.clientInternalAdjustments != null ? (miCase.clientInternalAdjustments | currency) : 'N/A' }}</span>
        </p>
      </div>
      <div class="column">
        <p>
          <span class="label-text">Current Stage</span>
          -
          <span [attr.data-field]="'currentXFWFStageName'">{{miCase.currentXFWFStageName}}</span>
        </p>
        <p>
          <span class="label-text">Current Task</span>
          -
          <span [attr.data-field]="'currentXFWFTaskType'">{{miCase.currentXFWFTaskType}}</span>
        </p>
        <p>
          <span class="label-text">Main Status</span>
          -
          <span [attr.data-field]="'statusTrackStatus'">{{miCase.statusTrackStatus ?? "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Customer Status</span>
          -
          <span [attr.data-field]="'customerFacingStatus'">{{miCase.customerFacingStatus ?? "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Line of Business</span>
          -
          <span [attr.data-field]="'lineOfBusiness'">{{miCase.lineOfBusiness ?? "N/A"}}</span>
        </p>
        <p>
          <span class="label-text">Network Discount</span>
          -
          <span [attr.data-field]="'networkDiscount'">{{ miCase.networkDiscount != null ? miCase.networkDiscount + "%" : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Post Payment Review</span>
          -
          <span [attr.data-field]="'postPaymentReview'">{{ miCase.postPaymentReview === true ? "Yes" : "No" }}</span>
        </p>
        <p>
          <span class="label-text">Insured Group No</span>
          -
          <span [attr.data-field]="'insuredGroupNo'">{{ miCase.insuredGroupNo ?? "N/A" }}</span>
        </p>
        <p>
          <span class="label-text">Insured Group Name</span>
          -
          <span [attr.data-field]="'insuredGroupName'">{{ miCase.insuredGroupName ?? "N/A" }}</span>
        </p>
      </div>
    </div>
    <div>
      <span class="label-text">Notes</span> -
      <p style="margin-left: 5px; margin-right: -15px;" [attr.data-field]="'notes'">
        {{miCase.notes ?? "N/A"}}
      </p>
    </div>
  </mat-card-content>
</mat-card>
