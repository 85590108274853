
<div [hidden]="!(screenMode === 'OVERVIEW')">
  <div class="itemized-list-div">
    <mat-grid-list cols="{{itemizedCount + 1}}" rowHeight="200px" gutterSize="15px">
      <ng-container *ngFor="let itemized of itemizedList">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z8" [ngStyle]="getSelectedStyle(itemized.id)">
            <mat-card-header>
              <mat-card-title 
              class="clickable-span"
                (click)="selectItemized(itemized.id)"
                >Itemized (Id: {{itemized.id}})</mat-card-title>
              <mat-card-subtitle>{{itemized.externalAltId}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <span 
                  *ngIf="itemized.ub04Id >= 0"
                  (click)="ub04SelectMode(itemized.id)"
                  >UB04 Id: {{itemized.ub04Id}}</span>
                <span 
                  class="clickable-span"
                  *ngIf="itemized.ub04Id < 0"
                  (click)="ub04SelectMode(itemized.id)">
                  <mat-icon class="ub04-error-icon">error</mat-icon> No UB04 Assigned</span>
              </p>
            </mat-card-content>
  
            <mat-card-actions>
              <button mat-raised-button color="accent" (click)="selectItemized(itemized.id)"><mat-icon>arrow_downward</mat-icon>Select</button>
              <button mat-raised-button color="accent" (click)="ub04SelectMode(itemized.id)"><mat-icon>assignment</mat-icon>Assign UB04</button>
            </mat-card-actions>    
          </mat-card>
        </mat-grid-tile>
      </ng-container>
      <mat-grid-tile>
        <mat-card class="mat-elevation-z8 title-center create-itemized-card">
          <mat-card-header>
            <mat-card-title>Create Itemized</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              Create a new Itemized for <br>Case: {{miCaseId}}
            </p>            
          </mat-card-content>
  
          <mat-card-actions>
            <button mat-raised-button color="accent" (click)="createItemized()"><mat-icon>add_circle_outline</mat-icon>Create Itemized</button>
          </mat-card-actions>    
        </mat-card>
      </mat-grid-tile>    
    </mat-grid-list>
  </div>

  <mat-paginator
    #paginator
    class="lefty"
    [pageSize]="defaultPageSize"
    [length]="totalRowCount"
    (page)="getItemizedList()"
  >
  </mat-paginator>

  <!-- Note: use of [hidden] here is intentional. Child components need to stay alive for proper 
  messaging.-->
  <div [hidden]="itemizedList.length == 0" class="itemized-revision-list">
    <div class="itemized-revision-list-header">
    <h1>Itemized Revisions</h1>
    </div>
    <app-itemized-revision-list #itemizedRevisionListComponent 
        itemizedId="{{selectedItemizedId}}"></app-itemized-revision-list>
  </div>
</div> <!-- OVERVIEW MODE -->

<!-- Itemized Line Item Editor -->
<div class="itemized-line-item-wrapper" [hidden]="!(screenMode === 'ITEMIZED-LINE-ITEM')">
  <app-itemized-line-item #itemizedLineItemComponent 
    itemizedRevisionId="{{selectedItemizedRevisionId}}">
  </app-itemized-line-item>
</div>

<!-- UB04 Select Mode -->
<div class="itemized-ub04-select-wrapper" [hidden]="!(screenMode === 'UB04-SELECT')">
  <div class="itemized-line-item-top-nav">
    <button mat-raised-button color="accent" (click)="returnToOverview()"><mat-icon>arrow_back</mat-icon>Return to Overview</button>
    <span class="itemized-line-item-rev-header-line"><mat-icon>assignment</mat-icon>Select UB04 for {{getFormattedItemizedName()}}</span>
    <app-ub04-select #ub04SelectComponent></app-ub04-select>
  </div>
</div>
