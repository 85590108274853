import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Injectable } from "@angular/core";
import { BaseResponse } from "../../model/base-response";
import { PagedResponse } from "../../model/paged-response";
import { EnvironmentService } from "../../environment.service";

@Injectable()
export class ProviderEINService {

  private providerEINUrl = this.environment.baseApiUrl + "provider-ein/";

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
  }

  getProviderEINListByProviderID = (id:number): Observable<BaseResponse> => {
    return this.httpClient.get<BaseResponse>(`${this.providerEINUrl}provider/${id}`);
  }

  searchProviderByFederalTaxNumber = (payload:any, first:number, max:number): Observable<PagedResponse<any>> => {
    return this.httpClient.post<PagedResponse<any>>(`${this.providerEINUrl}provider-search?first=${first}&max=${max}`, payload);
  }

  createProviderEIN = (packet:any): Observable<BaseResponse> => {
    return this.httpClient.post<BaseResponse>(`${this.providerEINUrl}create`, packet);
  }

  updateProviderEIN = (packet:any): Observable<BaseResponse> => {
    return this.httpClient.put<BaseResponse>(`${this.providerEINUrl}update`, packet);
  }

  deleteProviderEIN = (id:number): Observable<BaseResponse> => {
    return this.httpClient.delete<BaseResponse>(`${this.providerEINUrl}${id}`);
  }
}
