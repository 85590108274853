import {Component, ElementRef, Inject, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { CodeValidationService } from "../../code/code-validation.service";
import { BaseResponse } from "../../model/base-response";
import { UB04ValueCodeBulkPacket } from '../../model/net/ub04-value-code-bulk-packet';
import { UB04ValueCodePacket } from "../../model/net/ub04-value-code-packet";
import { Logger } from "../../util/logger";


@Component({
  selector: 'app-ub04-value-code-dialog',
  templateUrl: './ub04-value-code-dialog.component.html',
  styleUrls: ['./ub04-value-code-dialog.component.scss',
  '../ub04-dialog-base-styles.scss']
})
export class Ub04ValueCodeDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit {

  override log = new Logger("Ub04ValueCodeDialogComponent");
  formGroup!: FormGroup;
  ub04ValueCodes: any [] = [];

  @ViewChildren('valueCode') rows: any;
  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04ValueCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_VALUE_CODE, statusTrackService);
    this.constructFormGroup();
   }

   ngOnInit(): void {
    this.fetchData();
  }

  getRowDisplayStyle(idx: number): any {
    let ocFormGroup: FormGroup = this.getValueCodeFormGroup(idx);
    if (ocFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  getIconStyle(idx: number){
    let rcFormGroup: FormGroup = this.getValueCodeFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getValueCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  constructFormGroup() {
    this.formGroup = new FormGroup({
      valueCodes: new FormArray([])
    });
  }

  get valueCodes(): FormArray {
    return this.formGroup.controls["valueCodes"] as FormArray;
  }

  getValueCodeFormGroup(idx: number): FormGroup {
    return this.valueCodes.at(idx) as FormGroup;
  }

  populateValueCodesOnFormGroup() {
    // If this is the first time entering Value codes, pop user in with 3 blank lines
    // to start with.
    // NOTE- removed per client request.  Adds one if empty
    if (this.ub04ValueCodes.length <= 0) {
      // let idx = 0;
      // for (idx = 0; idx < 3; idx++) {
        this.onAddValueCodeLine();
      // }
      return;
    }

    // Prepopulate from existing ub04 revenue list.
    let valueCodes = this.valueCodes;
    valueCodes.clear();
    let idx = 0;
    for (idx = 0; idx < this.ub04ValueCodes.length; idx++) {
      let vc = this.ub04ValueCodes[idx];
      let vcFormGroup: FormGroup = new FormGroup({
        id: new FormControl(vc.id),
        valueCode: new FormControl(vc.valueCode, [Validators.required, Validators.maxLength(4)]),
        description: new FormControl(vc.valueCodeDescription),
        amount: new FormControl(vc.amount ?? "", Validators.pattern("[\\d\.]+")),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
      });
      vcFormGroup.controls['description'].disable();
      // Chatty value code validation during data entry
      vcFormGroup.controls['valueCode'].valueChanges.subscribe((val) => {
        this.validateValueCode(vcFormGroup, val);
      });
      valueCodes.push(vcFormGroup);
    }
  }

  onAddValueCodeLine() {
    let valueCodes = this.valueCodes;
    let rcFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      valueCode: new FormControl("", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(),
      amount: new FormControl("", Validators.pattern("[\\d\.]+")),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });
    rcFormGroup.controls['description'].disable();
    // Chatty value code validation during data entry
    rcFormGroup.controls['valueCode'].valueChanges.subscribe((val) => {
      this.validateValueCode(rcFormGroup, val);
    });
    valueCodes.push(rcFormGroup);
    setTimeout(() => {
      this.rows?.last?.nativeElement?.focus();
    }, 20);
  }

  validateValueCode(row: FormGroup, value: any) {
    let rcFormControl: FormControl = row.controls['valueCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;

    if (!rcFormControl.pristine) {
      if (rcFormControl.value.length < 2 || rcFormControl.value.length > 4) {
        rcFormControl.markAsPristine();
        descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        rcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getValueCode(rcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            rcFormControl.markAsPristine();
            rcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              rcFormControl.markAsPristine();
              descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              rcFormControl.setErrors(null);
            } else {
              descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              rcFormControl.markAsPristine();
              rcFormControl.setErrors({'incorrect': true});

            }
          }
        });

      }
    }
  }

  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.valueCodes.length; idx++) {
      let rc: FormGroup = this.getValueCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  fetchData() {
    this.ub04Service.getUb04ValueCodes(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04ValueCodes = response.data;
        this.populateValueCodesOnFormGroup();
      }
    });
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getValueCodeFormGroup(idx);
    // These are newly added by user during this session. Just remove them from the
    // form group list.
    if (rfg.controls["id"].value < 0) {
      this.valueCodes.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let updDto: UB04ValueCodeBulkPacket = new UB04ValueCodeBulkPacket();
    let rcRows: FormArray = this.valueCodes;
    rcRows.controls.forEach((rcRow: any) => {
      let revCode = rcRow.controls["valueCode"].value;
      updDto.ub04Id = this.data.ub04Id;
      if (revCode !== null && revCode !== undefined) {
        let rcDto: UB04ValueCodePacket = new UB04ValueCodePacket();
        rcDto.id = rcRow.controls["id"].value;
        rcDto.ub04Id = parseInt(this.data.ub04Id);
        rcDto.valueCode = rcRow.controls["valueCode"].value;
        rcDto.amount = parseFloat(rcRow.controls["amount"].value) || null;
        rcDto.deleteRow = rcRow.controls["deleteRow"].value;
        updDto.ub04ValueCodes.push(rcDto);
      }
    });

    this.ub04Service.putUb04ValueCodesBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }
}
