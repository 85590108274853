import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { debounceTime, distinctUntilChanged, take } from 'rxjs';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { BaseResponse } from "../../model/base-response";
import { DateUtil } from "../../util/date-util";
import { CodeValidationService } from "../../code/code-validation.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { UB04RemarksUpdatePacket } from '../../model/net/ub04-remarks-update-packet';
import { AdmissionSourceService } from '../../admin/admission-source-admin/admission-source.service';
import { Logger } from "../../util/logger";

@Component({
  selector: 'app-ub04-remarks-dialog',
  templateUrl: './ub04-remarks-dialog.component.html',
  styleUrls: ['./ub04-remarks-dialog.component.scss',
              '../ub04-dialog-base-styles.scss']
})
export class Ub04RemarksDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit, AfterViewInit  {
  override log = new Logger("Ub04RemarksDialogComponent");
  // selectedCoverPeriodFrom?: Date
  // selectedCoverPeriodThru?: Date
  formGroup!: FormGroup;
  ub04Dto: UB04RemarksUpdatePacket = new UB04RemarksUpdatePacket();
  @ViewChild('autosize') autosize?: CdkTextareaAutosize
  @ViewChild("page", {read: ElementRef})
  element?: ElementRef

  admissionSourceList?:any[] = [];

  constructor(
    public dialogRef: MatDialogRef<Ub04RemarksDialogComponent>,
    override snack: FennecSnackbarService,
    private statusTrackService: StatusTrackService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _ngZone: NgZone,
    private codeValidationService: CodeValidationService,
    private renderer: Renderer2,
    private admissionSourceService: AdmissionSourceService
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_REMARKS, statusTrackService);

    // Initialize a FormGroup
      this.formGroup = new FormGroup({
      remarks: new FormControl(),
      coverPeriodFrom: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$'), Validators.required]), //FormControl<Date | null>(null),
      coverPeriodThru: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$'), Validators.required]),//FormControl<Date | null>(null),
      admissionDate: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$'), Validators.required]), //FormControl<Date | null>(null),
      admissionHour: new FormControl("", [Validators.min(0), Validators.max(24)]),
      dischargeHour: new FormControl("", [Validators.min(0), Validators.max(24)]),
      admissionType: new FormControl(),
      admissionSourceCode: new FormControl("", Validators.maxLength(1)),
      dischargeStatusCode: new FormControl("", [Validators.minLength(1) ,Validators.maxLength(4)]),
      dischargeStatusDescription: new FormControl(""),
      admissionSourceDescription: new FormControl(""),
    });

    this.formGroup.controls['admissionSourceDescription'].disable();
  }

  ngOnInit() {
    this.fetchData();
    this.getAdmissionSources();
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  ngAfterViewInit(): void {
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
  }

  fetchData() {
    this.ub04Service.getUb04Remarks(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.formGroup.controls['remarks'].setValue(response.data.remarks)
        this.formGroup.controls['coverPeriodFrom'].setValue(DateUtil.fennecDBStringToInputDateString(response.data.coverPeriodFrom))
        this.formGroup.controls['coverPeriodThru'].setValue(DateUtil.fennecDBStringToInputDateString(response.data.coverPeriodThru))
        this.formGroup.controls['admissionDate'].setValue(DateUtil.fennecDBStringToInputDateString(response.data.admissionDate))
        this.formGroup.controls['admissionHour'].setValue(response.data.admissionHour)
        this.formGroup.controls['admissionType'].setValue(response.data.admissionType)
        this.formGroup.controls['dischargeHour'].setValue(response.data.dischargeHour)
        this.formGroup.controls['admissionSourceCode'].setValue(response.data.admissionSourceCode)
        this.formGroup.controls['admissionSourceDescription'].setValue(response.data.admissionSourceDescription)
        this.formGroup.controls['admissionSourceDescription'].disable()
        this.formGroup.controls['dischargeStatusCode'].setValue(response.data.dischargeStatusCode)
        this.formGroup.controls['dischargeStatusDescription'].setValue(response.data.dischargeStatusDescription)
        this.formGroup.controls['dischargeStatusDescription'].disable()
        // this.selectedCoverPeriodFrom = response.data.coverPeriodFrom
        // this.selectedCoverPeriodThru = response.data.coverPeriodThru
      }
    });

      this.formGroup.controls['admissionSourceCode'].valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(() => {
          this.validateAdmissionCodes()
        })

      this.formGroup.controls['dischargeStatusCode'].valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(() => {
          this.validateDischargeStatusCodes()
        })
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.ub04Dto.id = this.data.ub04Id;
    this.ub04Dto.remarks = this.formGroup.controls['remarks'].value;
    this.ub04Dto.admissionHour = this.formGroup.controls['admissionHour'].value;
    this.ub04Dto.dischargeHour = this.formGroup.controls['dischargeHour'].value;
    this.ub04Dto.admissionType = this.formGroup.controls['admissionType'].value;
    this.ub04Dto.admissionSourceCode = this.formGroup.controls['admissionSourceCode'].value;
    this.ub04Dto.dischargeStatusCode = this.formGroup.controls['dischargeStatusCode'].value;
    // this.ub04Dto.coverPeriodFrom = this.selectedCoverPeriodFrom
    // this.ub04Dto.coverPeriodFrom = this.selectedCoverPeriodThru
    this.ub04Dto.coverPeriodFrom = this.formGroup.controls['coverPeriodFrom'].value ? DateUtil.inputDateStringToFennecDBDateString(this.formGroup.controls['coverPeriodFrom'].value) : "";
    this.ub04Dto.coverPeriodThru = this.formGroup.controls['coverPeriodThru'].value ? DateUtil.inputDateStringToFennecDBDateString(this.formGroup.controls['coverPeriodThru'].value) : "";
    this.ub04Dto.admissionDate = this.formGroup.controls['admissionDate'].value ? DateUtil.inputDateStringToFennecDBDateString(this.formGroup.controls['admissionDate'].value) : "";

    this.ub04Service.putUb04Remarks(this.ub04Dto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          id: response.data.id
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }
  // AdmissionSourceCode chatty validation logic
  validateAdmissionCodes() {
    if (!this.formGroup.controls['admissionSourceCode'].pristine) {
      if (this.formGroup.controls['admissionSourceCode'].value.length > 1) {
        this.formGroup.controls['admissionSourceCode'].markAsPristine()
        this.formGroup.controls['admissionSourceDescription'].setValue("Invalid")
        this.formGroup.controls['admissionSourceCode'].setErrors({'incorrect' : true})
      } else {
        this.codeValidationService.getAdmissionSource(this.formGroup.controls['admissionSourceCode'].value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            this.formGroup.controls['admissionSourceCode'].markAsPristine()
            this.formGroup.controls['admissionSourceCode'].setErrors({'inccorect' : true})
          } else {
            if (response.data !== null) {
            this.formGroup.controls['admissionSourceCode'].markAsPristine()
            this.formGroup.controls['admissionSourceDescription'].setValue(response.data.description)
            this.formGroup.controls['admissionSourceCode'].setErrors(null)
            } else {
            this.formGroup.controls['admissionSourceDescription'].setValue("Invalid: Value does not exist.")
            this.formGroup.controls['admissionSourceCode'].markAsPristine()
            this.formGroup.controls['admissionSourceCode'].setErrors({'incorrect' : true})
            }
          }
        })
      }
    }
  }
  // DischargeStatusCode chatty validation logic
  validateDischargeStatusCodes() {
    if (!this.formGroup.controls['dischargeStatusCode'].pristine) {
      if (this.formGroup.controls['dischargeStatusCode'].value.length > 3) {
        this.formGroup.controls['dischargeStatusCode'].markAsPristine()
        this.formGroup.controls['dischargeStatusDescription'].setValue("Invalid, max input allowed: 3 digits")
        this.formGroup.controls['dischargeStatusCode'].setErrors({'incorrect' : true})
      } else {
        this.codeValidationService.getDischargeStatus(this.formGroup.controls['dischargeStatusCode'].value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            this.formGroup.controls['dischargeStatusCode'].markAsPristine()
            this.formGroup.controls['dischargeStatusCode'].setErrors({'inccorect' : true})
          } else {
            if (response.data !== null) {
            this.formGroup.controls['dischargeStatusCode'].markAsPristine()
            this.formGroup.controls['dischargeStatusDescription'].setValue(response.data.description)
            this.formGroup.controls['dischargeStatusCode'].setErrors(null)
            } else {
            this.formGroup.controls['dischargeStatusDescription'].setValue("Invalid: Value does not exist.")
            this.formGroup.controls['dischargeStatusCode'].markAsPristine()
            this.formGroup.controls['dischargeStatusCode'].setErrors({'incorrect' : true})
            }
          }
        })
      }
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        this.onCancel();
        break;
    }
  }

  getAdmissionSources() {
    this.admissionSourceService.getAdmissionSourcesByParams({active:true}, 0, 999).subscribe(response => {
      if(response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage ?? "Error Getting Admission Sources");
      }else {
        this.admissionSourceList = response.data;
      }
    });
  }
}
