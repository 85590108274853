import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BaseResponse, EnvironmentService, ItemizedUpdatePacket, PagedResponse, UB04ItemizedPacket} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class ItemizedService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getItemizedListForMICase(miCaseId: number): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized/list/mi-case/" + miCaseId.toString());
  }

  getItemizedForMICasePaginated(id: number, first:number, max:number): Observable<PagedResponse<any>> {
    return this.httpClient.get<PagedResponse<any>>(this.environment.baseApiUrl + "itemized/list/mi-case/p/" + id.toString() + "?first=" + first + "&max=" + max);
  }

  addItemized(payload: ItemizedUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "itemized/create", payload);
  }

  getItemizedById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}itemized/${id}`);
  }

  addUb04ToItemized(payload: UB04ItemizedPacket): Observable<any> {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}itemized/add-ub04`, payload);
  }
}
