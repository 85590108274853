import { HttpClient, HttpParams } from "@angular/common/http";
import { LookupResult } from "../../model/net/lookup-result";
import { DataResponse } from "../../model/data-response";
import { Observable } from "rxjs";
import { LookupFilter } from "../../model/net/lookup-filter";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "../../environment.service";


@Injectable({
  providedIn: 'root'
})
export class TextLookupService {
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService
  ) {}

  searchTextLookup = (
    endpoint: string,
    query: LookupFilter,
    first = 0,
    max = 25
  ): Observable<DataResponse<LookupResult[]>> => {
    const url = `${this.environment.baseApiUrl}${endpoint}`
    let params = new HttpParams();
    params = params.set("first", first);
    params = params.set("max", max);
    return this.http.post<DataResponse<LookupResult[]>>(url, query, { params });
  };
}
