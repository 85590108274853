import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding, Inject,
  Input,
  OnDestroy,
  Optional,
  Self
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup, FormGroupDirective,
  NgControl, NgForm,
  ValidatorFn,
} from "@angular/forms";
import { LookupResult } from "../../model/net/lookup-result";
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { BaseComponent } from "../base.component";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../util/logger";
import { TextLookupService } from "./text-lookup.service";
import { MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD, MatLegacyFormField as MatFormField, MatLegacyFormFieldControl as MatFormFieldControl } from "@angular/material/legacy-form-field";
import { FocusMonitor } from "@angular/cdk/a11y";

@Component({
  selector: "lib-text-lookup",
  templateUrl: "./text-lookup.component.html",
  styleUrls: ["./text-lookup.component.scss"],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TextLookupComponent
    }
  ]
})
export class TextLookupComponent
  extends BaseComponent
  implements ControlValueAccessor,
    MatFormFieldControl<string>,
    AfterViewInit,
    OnDestroy {

  static nextId = 0;

  @HostBinding() id = `lib-text-lookup-${TextLookupComponent.nextId++}`;

  protected readonly log = new Logger("TextLookupComponent");

  @Input()
  endpoint: string;

  @Input()
  form: FormGroup;

  @Input()
  formControl: FormControl;

  @Input()
  validator: ValidatorFn[];

  @Input()
  placeholderText: string;

  @Input()
  label: string;

  @Input()
  class: string;

  @Input()
  keyName: string;

  @Input()
  valueName: string;

  @Input()
  userInput: string;

  @Input()
  disabled = false;

  @Input()
  strictMatch = true;

  @Input()
  minLength = 0;

  @Input()
  maxLength = 8;

  stateChanges = new Subject<void>();

  errorState = false;

  touched = false;

  _lookupOptions = new BehaviorSubject<LookupResult[]>([]);

  constructor(
    protected snack: FennecSnackbarService,
    protected textLookupService: TextLookupService,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    private fm: FocusMonitor
  ) {
    super();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(_elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    setTimeout(() => {
      this.ngControl?.valueChanges?.subscribe((val: string) => {
        this.errorState = this.checkValidity(val);
        this.ngControl?.control?.setErrors(this.errorState ? { invalid: true } : null);
      });
    })
  }

  checkValidity(val?: string) {
    if (!this.touched)
      return false;
    if (this.minLength > this.value?.length)
      return true;
    if (this.maxLength < this.value?.length)
      return true;
    if (!this.strictMatch)
      return false;
    return !this.lookupOptions.some((lookup) => lookup[this.keyName] == val);
  }


  ngAfterViewInit() {
    this.lookupSearch();

    this.userInputChange$?.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyed$),
      debounceTime(300)
    ).subscribe((val) => {
      this.lookupSearch(val);
    });
  }

  @Input()
  get value(): string | null {
    return this.userInput;
  }

  set value(val: string | null) {
    this.userInput = val;
    this.stateChanges.next();
  }

  override ngOnDestroy() {
    this.stateChanges.complete();
    super.ngOnDestroy();
  }

  onChange = (userInput) => {
    this.ngControl.control?.setValue(userInput);
  };

  onTouched = () => {};
  get lookupOptions(): LookupResult[] {
    return this._lookupOptions.value;
  }

  set lookupOptions(options: LookupResult[]) {
    this._lookupOptions.next(options);
  }


  writeValue(val: any): void {
    this.userInput = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  private lookupSearch(val?: any) {
    const query = val ? {
      [this.keyName]: val
    } : {};
    this.performXFRequest({
      requestDescription: "Search DRG codes",
      requestFn: this.textLookupService.searchTextLookup,
      fnParams: [this.endpoint, query, 0, 25],
      onSuccess: (data) => {
        this.lookupOptions = data;
      }
    });
  }

  autofilled: boolean;
  controlType: string;

  get empty() {
    return this.userInput == null || this.userInput === "";
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _placeholder: string;

  onContainerClick(event: MouseEvent): void {
    this.markAsTouched();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  focused = false;

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(required: boolean) {
    const temp: any = required;
    required = (temp != "true");
    this._required = required;
    this.stateChanges.next();
  }

  private _required = false;

  setDescribedByIds(ids: string[]): void {
  }

  readonly userAriaDescribedBy: string;

  userInputChange$ = new Subject<string>();

  userInputChange(val: string) {
    this.userInputChange$.next(val);
    this.ngControl.control?.setValue(val);
    this.stateChanges.next();
  }
}
