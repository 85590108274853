
<div *ngIf="dataSource.data.length == 0" class="no-records-found-div">
    No Tasks Found!
</div>

<div *ngIf="dataSource.data.length != 0">
<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="taskStatus">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.taskStatus}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef> Task Type </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.taskType}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="data">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.data}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskAssignedBy">
        <mat-header-cell *matHeaderCellDef> Assigned By </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.taskAssignedBy}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskAssignedTo">
        <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.taskAssignedTo}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskCreatedBy">
        <mat-header-cell *matHeaderCellDef> Task Created By </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.taskCreatedBy}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef> Due Date </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{formatDate(c.taskDueDateString)}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
  </mat-table>
  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>