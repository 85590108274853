<mat-dialog-content>
  <h1 mat-dialog-title>Create New Itemized Revision</h1>
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form">
      <mat-form-field class="name-field">
        <mat-label>Enter Name</mat-label>
        <input matInput placeholder="Enter Name..." formControlName="name">
      </mat-form-field>      
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Create
    </button>

    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>

