import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from "../model/base-response";
import { EnvironmentService } from "../environment.service";
import { DataResponse } from "../model/data-response";
import { PagedResponse } from "../model/paged-response";
import { DRGCodePacket } from "../model/net/drg-code-packet";

@Injectable({
  providedIn: 'root'
})
export class CodeValidationService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getRevenueCode(revenueCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/revenue-code/" + revenueCode);
  }

  getDiagnosisCode(diagnosisCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/diagnosis-code/" + diagnosisCode);
  }

  getConditionCode(conditionCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/condition-code/" + conditionCode);
  }

  getOccurrenceCode(occurrenceCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/occurrence-code/" + occurrenceCode);
  }

  getAdmissionSource(admissionSourceCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/admission-source/" + admissionSourceCode);
  }

  getDischargeStatus(dischargeStatusCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/discharge-status/" + dischargeStatusCode);
  }

  getValueCode(valueCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/value-code/" + valueCode);
  }

  getDRGCode = (drgCode: string): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/drg-code/" + drgCode);
  }

  getTaxonomyCode(taxonomyCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/taxonomy-code/" + taxonomyCode);
  }

  getProcedureCode(procedureCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/procedure-code/" + procedureCode);
  }

  getAdjustmentReasonCode(adjustmentReasonCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/adjustment-reason-code/" + adjustmentReasonCode);
  }

  getAdjustmentExplanationCode(adjustmentExplanationCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/adjustment-explanation-code/" + adjustmentExplanationCode);
  }

  getPatientRelationshipCode(patientRelationshipCode: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "codes/patient-relationship-code/" + patientRelationshipCode);
  }

  searchDRGCodes = (packet: any, first: number, max: number) : Observable<PagedResponse<DRGCodePacket>> => {
    const endpoint = `${this.environment.baseApiUrl}drg-code/list`
    let params = new HttpParams();
    params = params.set("first", first);
    params = params.set("max", max);
    return this.httpClient.post<PagedResponse<DRGCodePacket>>(endpoint, packet, { params });
  }
}
