<!-- Main App entry point -->
<div class="navbar-wrapper">
  <div class="navbar-left">
    <button
      mat-button
      (click)="toggleSideNav()"
      matTooltip="Navigation"
      class="nav-button"
      matTooltipShowDelay="1000">
      <mat-icon>menu</mat-icon>
      <img
        class="fennec-logo"
        src="../assets/logo_6degrees.png"
      />
    </button>
    <a
      class="navbar-header-link"
      [routerLink]="''"
    >
      <h2>Fennec Medical Incident Management System </h2>
    </a>
  </div>

  <div class="navbar-right">
    <ng-container *ngIf="username">
      <a
        class="navbar-header-link"
        [routerLink]="'profile'"
      >
        <h2>Welcome {{ username }}</h2>
      </a>
    </ng-container>
    <div class="logout-section">
      <button
        id="web-logout-button"
        (click)="logout()"
        mat-button
        matTooltip="Log Out"
      >
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>

</div>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="side" [(opened)]="navigationOpen">
    <nav class="sidenav">
      <a
        class="sidenav-link"
        [routerLink]="''"
        (click)="toggleSideNav()">
        Home
      </a>
      <a *ngIf="userCanSeeDashboards"
        class="sidenav-link"
        [routerLink]="'dashboard/STANDARD'"
        (click)="toggleSideNav()">
        Dashboards
      </a>
      <a *ngIf="userCanSeeCenteneDashboards"
        class="sidenav-link"
        [routerLink]="'dashboard/CENTENE'"
        (click)="toggleSideNav()">
        Dashboards (Centene)
      </a>
      <a *ngIf="!this.userIsDataEntry"
        class="sidenav-link"
        [routerLink]="'mi-case-list'"
        (click)="toggleSideNav()">
        Medical Incident Stays
      </a>
      <a *ngIf="!this.userIsDataEntry"
        class="sidenav-link"
        [routerLink]="'profile'"
        (click)="toggleSideNav()">
        User Profile
      </a>
      <a *ngIf="!this.userIsDataEntry"
        class="sidenav-link"
        [routerLink]="'activity-diary'"
        (click)="toggleSideNav()">
        Recent Activity
      </a>
      <a *ngIf="this.userIsDataEntry"
      class="sidenav-link"
      [routerLink]="'data-entry'"
      (click)="toggleSideNav()">
      Data Entry
      </a>
    </nav>
  </mat-sidenav>

  <mat-sidenav-content class="app-content">
    <router-outlet class="hidden-router"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

