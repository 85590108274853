import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Ub04Service } from '../../ub04.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { DateUtil } from '../../../util/date-util';
import { SingleChoiceDialogComponent } from '../../../dialog/single-choice-dialog/single-choice-dialog.component';
import { Logger } from '../../../util/logger';
import { BaseComponent } from '../../../view/base.component';
import { FennecSnackbarService } from '../../../dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'lib-existing-patient-search',
  templateUrl: './existing-patient-search.component.html',
  styleUrl: './existing-patient-search.component.scss'
})
export class ExistingPatientSearchComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger = new Logger("ExistingPatientSearchComponent");
  
  ub04Id:number = -1;
  selectedPatientId:number = -1;

  formGroup!: FormGroup;
  
  patientList = [];
  displayColumns = ["altPatientId", "name", "dob", "address", "actions"];

  totalRowCount = 0;
  defaultPageSize = 5;
  pageSizeOptions = [5, 10, 20];

  @ViewChild("paginator")
  paginator;

  constructor (
    protected override snack: FennecSnackbarService,
    protected ub04Service: Ub04Service,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<ExistingPatientSearchComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if (data) {
      this.ub04Id = data.ub04Id;
      this.selectedPatientId = data.selectedPatientId;
    }

    this.formGroup = new FormGroup({
      altPatientId: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
    });
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData() {
    const packet = {
      altPatientId: this.formGroup.controls['altPatientId']?.value,
      firstName: this.formGroup.controls['firstName']?.value,
      lastName: this.formGroup.controls['lastName']?.value
    };

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "Get Existing Patients",
      requestFn: this.ub04Service.searchExistingPatients,
      fnParams: [packet, first, pageSize],
      onSuccess: data => {
        this.patientList = data;
        console.log(this.patientList);
      },
      onResponse: response => {
        if (response?.['totalRowCount']) {
          this.totalRowCount = response['totalRowCount'];
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  formatDate = (inputDate:string) => {
    return DateUtil.getDisplayDate(inputDate);
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  selectPatient(patient:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Are you sure you want to add ${patient?.firstName} ${patient?.lastName} to UB04 ID:${this.ub04Id}?`,
      infoLine1: "This will affect all UB04s attached to this case!"
    };

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.performXFRequest({
          requestDescription: "Add Existing Patient to UB04",
          requestFn: this.ub04Service.setExistingPatientById,
          fnParams: [this.ub04Id, patient.id],
          onSuccess: data => {
            this.dialogRef.close({confirm:true});
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    });
  }
}
