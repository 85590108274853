
<mat-form-field
  *ngIf="ngControl != null"
  [style.width.px]=width 
  [appearance]="appearance">
  <mat-label>
    {{labelText}}
  </mat-label>

  <input #xfFormField
    matInput
    [formControlName]="formControlName"
    [type]="type"
  >
  @if (maxLength > 0 && ngControl?.value?.length > maxLength) {
    <mat-hint class="error-text" align="end">
      {{ngControl?.value?.length}} of {{maxLength}}
    </mat-hint>
  }@else if (maxLength > 0 && (maxLength - (ngControl?.value?.length)) <= warningDifference) {
    <mat-hint align="end">
      {{ngControl?.value?.length}} of {{maxLength}}
    </mat-hint>
  }

  <!-- @if (ngControl?.errors != null) {
    <mat-hint class="error-text">
      Required
    </mat-hint>
  }@else if (ngControl?.hasError("maxlength")) {
    <mat-hint class="error-text">
      Max length exceeded
    </mat-hint>
  }@else if (ngControl?.hasError("minlength")) {
    <mat-hint class="error-text">
      Minimum length not met
    </mat-hint>
  }@else if (ngControl?.hasError("incorrect")) {
    <mat-hint class="error-text">
      Invalid value
    </mat-hint>
  }@else if (ngControl?.hasError("pattern")) {
    <mat-hint class="error-text">
      Incorrecct Format
    </mat-hint>
  }@else if(hint) {
    <mat-hint>
      {{hint}}
    </mat-hint>
  } -->

  @if (ngControl?.errors != null && !ngControl?.pristine) {
    <mat-hint
      class="error-text"
    >
      {{ getErrorMessage() }} {{ hint }}
    </mat-hint>
  }@else if(hint) {
    <mat-hint>
      {{ hint }}
    </mat-hint>
  }
</mat-form-field>