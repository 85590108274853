<pdf-shy-button
  title="Next Page"
  [cssClass]="'xs' | responsiveCSSClass"
  primaryToolbarId="primaryNext"
  secondaryMenuId="primaryNextPage"
  l10nId="pdfjs-next-button"
  l10nLabel="pdfjs-next-button-label"
  [disabled]="disableNextPage"
  image="<svg width='24px' height='24px' viewBox='0 0 24 24'><path fill='currentColor' d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' /></svg>"
  [order]="700"
  [closeOnClick]="false"
  eventBusName="nextpage"
>
</pdf-shy-button>
