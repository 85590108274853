import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Ub04Service } from "../../ub04/ub04.service";
import { ItemizedService } from "../itemized.service";
import { UB04ItemizedPacket } from "../../model/net/ub04-itemized-packet";

@Component({
  selector: 'app-ub04-select',
  templateUrl: './ub04-select.component.html',
  styleUrls: ['./ub04-select.component.scss']
})
export class Ub04SelectComponent implements OnInit, AfterViewInit {

  itemizedId: number = 0;

  ub04List: any[] = [];
  miCaseId?: number;
  displayedColumns: any [] = ["id", "npiNumber", "patientName", "createdBy", "actions"];

  saveSuccessful: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private ub04Service: Ub04Service,
    private itemizedService: ItemizedService,
    private snack: FennecSnackbarService
  ) {
   }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  getUb04List(miCaseId: number, itemizedId: number) {
    this.miCaseId = miCaseId;
    this.itemizedId = itemizedId;
    this.ub04Service.getUb04ListForMICase(miCaseId, 0, 9999).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack("Error getting UB04s for MICase");
      } else {
        this.ub04List = response.data;
      }
    });
  }

  addUB04ToItemized(ub04Id: number) {
    const outPacket = new UB04ItemizedPacket();
    outPacket.ub04Id = ub04Id;
    outPacket.itemizedId = this.itemizedId;

    this.itemizedService.addUb04ToItemized(outPacket).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack("Error adding UB04 to Itemized");
      } else {
        this.snack.showInfoSnack("UB04 Added to Itemized");
        this.saveSuccessful.next(ub04Id);
      }
    });
  }

}
