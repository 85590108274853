import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from "../../view/crud/base.crud.service";
import { AdmissionSourcePacket } from "../../model/net/admission-source";
import { DataResponse } from "../../model/data-response";
import { PagedResponse } from "../../model/paged-response";
import { Crud } from "../../util/common-handlers";

@Injectable()
export class AdmissionSourceService extends BaseCrudService<AdmissionSourcePacket> {
  baseEndpoint = super.apiEndpoint("admission-source");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "AdmissionSourceService", "AdmissionSource");
  }

  getAdmissionTypes = () => {
    const endpoint = `${this.baseEndpoint}/types`;
    return this.httpClient.get<DataResponse<String[]>>(endpoint);
  }

  getAdmissionSource: () => Observable<DataResponse<AdmissionSourcePacket>> = () => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.get<DataResponse<AdmissionSourcePacket>>(endpoint);
  }

  _list: Crud.ListFunction<AdmissionSourcePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<AdmissionSourcePacket[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<AdmissionSourcePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<AdmissionSourcePacket>>(endpoint);
  }

  _create: Crud.CreateFunction<AdmissionSourcePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<AdmissionSourcePacket>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<AdmissionSourcePacket> = (user): Observable<DataResponse<AdmissionSourcePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<AdmissionSourcePacket>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<AdmissionSourcePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  _delete = (ids: number[]):  Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids);
  }

  changeStatus = (diagnosisCodePacket: AdmissionSourcePacket): Observable<DataResponse<AdmissionSourcePacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<AdmissionSourcePacket>>(endpoint, diagnosisCodePacket);
  }

  protected _crudFunctions: Crud.CrudServiceDef<AdmissionSourcePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getAdmissionSourcesByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<AdmissionSourcePacket[]>>(endpoint, packet);
  }
}
