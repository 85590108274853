import { NgModule } from '@angular/core';
import { XfCommonComponent } from './xf-common.component';
import { SingleChoiceDialogComponent } from "./dialog/single-choice-dialog/single-choice-dialog.component";
import { MaterialModule } from "./view/material.module";
import {
  Ub04ConditionCodeDialogComponent
} from "./ub04/ub04-condition-code-dialog/ub04-condition-code-dialog.component";
import { UB04CreateComponent } from "./ub04/ub04-create/ub04-create.component";
import { UB04EditDialog1Component } from "./ub04/ub04-edit-dialog1/ub04-edit-dialog1.component";
import { Ub04EditPatientDialogComponent } from "./ub04/ub04-edit-patient-dialog/ub04-edit-patient-dialog.component";
import {
  Ub04EditProcedureCodeDialogComponent
} from "./ub04/ub04-edit-procedure-code-dialog/ub04-edit-procedure-code-dialog.component";
import {
  Ub04OccurrenceCodeDialogComponent
} from "./ub04/ub04-occurrence-code-dialog/ub04-occurrence-code-dialog.component";
import { Ub04RevenueCodeDialogComponent } from "./ub04/ub04-revenue-code-dialog/ub04-revenue-code-dialog.component";
import { Ub04ValueCodeDialogComponent } from "./ub04/ub04-value-code-dialog/ub04-value-code-dialog.component";
import {
  Ub04AddProviderComponent
} from "./ub04/ub04-edit-provider-dialog/ub04-add-provider/ub04-add-provider.component";
import { Ub04AddCompanyComponent } from "./ub04/ub04-payer-dialog/ub04-add-company/ub04-add-company.component";
import {
  Ub04EditPayerComponentComponent
} from "./ub04/ub04-payer-dialog/ub04-edit-payer-component/ub04-edit-payer-component.component";
import { Ub04RemarksDialogComponent } from "./ub04/ub04-remarks-dialog/ub04-remarks-dialog.component";
import { Ub04PayerDialogComponent } from "./ub04/ub04-payer-dialog/ub04-payer-dialog.component";
import { Ub04EditProviderDialogComponent } from "./ub04/ub04-edit-provider-dialog/ub04-edit-provider-dialog.component";
import {
  Ub04DiagnosisCodeDialogComponent
} from "./ub04/ub04-diagnosis-code-dialog/ub04-diagnosis-code-dialog.component";
import { FennecSnackbarService } from "./dialog/fennec-snackbar/fennec-snackbar.service";
import { EnvironmentI, EnvironmentService } from "./environment.service";
import { Ub04ListComponent } from "./ub04/ub04-list/ub04-list.component";
import { Ub04ReviewComponent } from "./ub04/ub04-review/ub04-review.component";
import { Ub04SelectComponent } from "./itemized/ub04-select/ub04-select.component";
import { ItemizedService } from "./itemized/itemized.service";
import { StatusTrackService } from "./status-track/status-track.service";
import { NameSearchPromptComponent } from "./search/name-search-prompt/name-search-prompt.component";
import { AddressComponent } from "./admin/address-admin/address.component";
import { AddressService } from "./admin/address-admin/address.service";
import { StateService } from "./admin/state-admin/state.service";
import {
  AdmissionSourceListComponent
} from "./admin/admission-source-admin/admission-source-list/admission-source-list.component";
import { CommonViewModule } from "./view/common-view.module";
import { CommonModule } from "@angular/common";
import {
  AdmissionSourceDialogComponent
} from "./admin/admission-source-admin/admission-source-dialog/admission-source-dialog.component";
import {
  CRUDDeleteStatusChangeDialogComponent
} from "./admin/crud-delete-status-change-dialog/crud-delete-status-change.component";
import { ProviderEinListComponent } from "./provider/provider-ein/provider-ein-list/provider-ein-list.component";
import { ProviderAddressComponent } from "./provider/provider-address/provider-address.component";
import { ProviderEinDialogComponent } from "./provider/provider-ein/provider-ein-dialog/provider-ein-dialog.component";
import { ProviderService } from "./provider/provider.service";
import { ProviderEINService } from "./provider/provider-ein/provider-ein.service";
import { AdmissionSourceService } from "./admin/admission-source-admin/admission-source.service";
import { MiCaseUb04Component } from "./mi-case/mi-case-ub04/mi-case-ub04.component";
import { ItemizedCreateComponent } from "./itemized/itemized-create/itemized-create.component";
import { CompanyService } from "./company/company.service";
import { TextLookupComponent } from "./view/text-lookup/text-lookup.component";
import { TextLookupService } from "./view/text-lookup/text-lookup.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { XFFormField } from './util/components/xf-form-field/xf-form-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExistingPatientSearchComponent } from './ub04/patient/existing-patient-search/existing-patient-search.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { PatientComponent } from './patient/patient/patient.component';
import { PatientCaseListComponent } from './patient/patient-case-list/patient-case-list.component';
import { PatientOverviewComponent } from './patient/patient-overview/patient-overview.component';
import { AddressDialogComponent } from './admin/address-admin/address-dialog/address-dialog.component';
import { ContactListComponent } from "./contact/contact-list/contact-list.component";
import { ContactDialogComponent } from './contact/contact-dialog/contact-dialog.component';
import { UB04GenIBRequestDialogComponent } from './ub04/ub04-gen-ib-request-dialog/ub04-gen-ib-request-dialog.component';
import {DateInputComponent} from "./forms/date-input/date-input.component";
import {MatLegacyInputModule} from "@angular/material/legacy-input";
import {RouterLink} from "@angular/router";

const environmentValue: EnvironmentI = { // these placeholders are overridden in ui/web app.module.ts
  app: '',
  production: false,
  baseApiUrl: '',
  baseApiAttachmentUrl: '',
  keycloakServerUrl: '',
  keycloakRealmName: '',
  keycloakClientId: '',
  keycloakRedirectUri: '',
};

@NgModule({
    imports: [
        CommonModule,
        CommonViewModule,
        MaterialModule,
        BrowserAnimationsModule,
        MatLegacyInputModule,
        RouterLink
    ],
  declarations: [
    XfCommonComponent,
    AddressComponent,
    AdmissionSourceDialogComponent,
    AdmissionSourceListComponent,
    CRUDDeleteStatusChangeDialogComponent,
    ItemizedCreateComponent,
    MiCaseUb04Component,
    SingleChoiceDialogComponent,
    Ub04ConditionCodeDialogComponent,
    UB04CreateComponent,
    Ub04DiagnosisCodeDialogComponent,
    UB04EditDialog1Component,
    UB04GenIBRequestDialogComponent,
    Ub04EditPatientDialogComponent,
    Ub04EditProcedureCodeDialogComponent,
    Ub04EditProviderDialogComponent,
    Ub04AddProviderComponent,
    Ub04ListComponent,
    Ub04OccurrenceCodeDialogComponent,
    Ub04PayerDialogComponent,
    Ub04AddCompanyComponent,
    Ub04EditPayerComponentComponent,
    Ub04RemarksDialogComponent,
    Ub04RevenueCodeDialogComponent,
    Ub04ReviewComponent,
    Ub04SelectComponent,
    Ub04ValueCodeDialogComponent,
    NameSearchPromptComponent,
    ProviderEinListComponent,
    ProviderEinDialogComponent,
    TextLookupComponent,
    XFFormField,
    ExistingPatientSearchComponent,
    PatientListComponent,
    PatientComponent,
    PatientCaseListComponent,
    PatientOverviewComponent,
    ProviderAddressComponent,
    AddressDialogComponent,
    ContactListComponent,
    ContactDialogComponent,
    DateInputComponent
  ],
  exports: [
    XfCommonComponent,
    AddressComponent,
    AdmissionSourceDialogComponent,
    AdmissionSourceListComponent,
    CRUDDeleteStatusChangeDialogComponent,
    ItemizedCreateComponent,
    MiCaseUb04Component,
    SingleChoiceDialogComponent,
    Ub04ConditionCodeDialogComponent,
    UB04CreateComponent,
    Ub04DiagnosisCodeDialogComponent,
    UB04EditDialog1Component,
    UB04GenIBRequestDialogComponent,
    Ub04EditPatientDialogComponent,
    Ub04EditProcedureCodeDialogComponent,
    Ub04EditProviderDialogComponent,
    Ub04AddProviderComponent,
    Ub04ListComponent,
    Ub04OccurrenceCodeDialogComponent,
    Ub04PayerDialogComponent,
    Ub04AddCompanyComponent,
    Ub04EditPayerComponentComponent,
    Ub04RemarksDialogComponent,
    Ub04RevenueCodeDialogComponent,
    Ub04ReviewComponent,
    Ub04SelectComponent,
    Ub04ValueCodeDialogComponent,
    NameSearchPromptComponent,
    ProviderEinListComponent,
    ProviderEinDialogComponent,
    TextLookupComponent,
    XFFormField,
    ExistingPatientSearchComponent,
    PatientListComponent,
    PatientComponent,
    PatientCaseListComponent,
    PatientOverviewComponent,
    ProviderAddressComponent,
    AddressDialogComponent,
    ContactListComponent,
    ContactDialogComponent,
    DateInputComponent
  ],
  providers: [
    {
      provide: EnvironmentService,
      useValue: environmentValue
    },
    AddressService,
    FennecSnackbarService,
    ItemizedService,
    StateService,
    StatusTrackService,
    ProviderService,
    ProviderEINService,
    AdmissionSourceService,
    CompanyService,
    TextLookupService
  ]
})
export class XfCommonModule { }
