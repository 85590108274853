<form [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Organization Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName">
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field appearance="outline">
      <mat-label>NPI Number</mat-label>
      <input matInput formControlName="npiNumber">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Provider Type</mat-label>
      <mat-select matInput formControlName="providerType">
        <mat-option *ngFor="let type of providerTypes" [value]="type?.value">{{type?.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Taxonomy Code</mat-label>
      <input matInput formControlName="taxonomyCode">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Taxonomy Code Description</mat-label>
      <input matInput formControlName="taxonomyCodeDescription">
    </mat-form-field>
  </div>
</form>
<app-address #addressComponent context="PROVIDER"></app-address>
