<input type="text"
       matInput
       [(ngModel)]="userInput"
       (ngModelChange)="userInputChange($event)"
       [placeholder]="placeholder"
       [matAutocomplete]="textLookupAutocomplete">
<!--       [formGroup]="form"-->
<!--       [formControlName]="ctrlName"-->
<mat-autocomplete #textLookupAutocomplete autoActiveFirstOption>
  <mat-option *ngFor="let option of _lookupOptions | async" [value]="option[keyName]">
    {{ option[keyName] }} - {{ option[valueName] | slice: 0: 20 }}
  </mat-option>
</mat-autocomplete>
<mat-error *ngIf="formControl?.errors != null">
  The provided value is invalid
</mat-error>
