import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, catchError, map, throwError} from "rxjs";
import { FennecSnackbarService } from "./dialog/fennec-snackbar/fennec-snackbar.service";
import { EnvironmentService } from "./environment.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  baseApiUrl = this.environment.baseApiUrl;
  baseApiAttachmentUrl = this.environment.baseApiAttachmentUrl;

  constructor(
    private fennecSnackBarService: FennecSnackbarService,
    private environment: EnvironmentService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith("api:")) {
      //console.log('request url did start with api:');
      request = request.clone({url: `${this.baseApiUrl}${request.url.substring(4)}`});
    }
    else if (request.url.startsWith("attachment:")) {
      //console.log('request url did start with attachment:');
      request = request.clone({url: `${this.baseApiAttachmentUrl}${request.url.substring(11)}`});
    }

    return next.handle(request).pipe(
      map(res => {
        //console.log("Passed through the interceptor in response");
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          //console.log('This is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          //console.log('This is server side error');
          /* Developers please put in standard HTTP response error handling here for all requests */
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          if (error.status === 403) {
            this.fennecSnackBarService.showErrorSnack("You are not authorized to use this feature. Please contact your system administrator.");
          }
        }
        //console.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }
}
