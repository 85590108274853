import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {CompanyPacket, EnvironmentService, PagedResponse} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyUrl = this.environment.baseApiUrl + "company/";

  constructor(
    public httpClient: HttpClient,
    public environment: EnvironmentService
  ) {}

  getCompany = (id?: number): Observable<any> => {
    return this.httpClient.get<any>(this.companyUrl + id);
  }

  getAllCompanies = (name?: string, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list?first=" + first + "&max=" + max + "&name=" + name);
  }

  createCompany = (company?: CompanyPacket): Observable<any> => {
    return this.httpClient.post<any>(this.companyUrl + "create", company);
  }

  updateCompany = (company?: CompanyPacket): Observable<any> => {
    return this.httpClient.put<any>(this.companyUrl + "update", company);
  }

  // Note: sending up -1 as the id (UserProfile.Id) necessary for this endpoint. The back-end will handle the conversion using
  // the user information sent up with the token.
  getActiveUserProfilePrimaryClientCompanies = (first: number = 0, max: number = 9999): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.companyUrl + "company-list-crt/USER_PROFILE/-1/PRIMARY_CLIENT"
      + "?first=" + first + "&max=" + max);
  }

}
