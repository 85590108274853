<pdf-shy-button
  [cssClass]="show | responsiveCSSClass : 'always-in-secondary-menu'"
  title="Do not join page spreads"
  primaryToolbarId="spreadNone"
  l10nId="pdfjs-spread-none-button"
  [toggled]="spread === 'off'"
  [action]="onClick"
  l10nLabel="pdfjs-spread-none-button-label"
  [order]="2000"
  [closeOnClick]="false"
  [disabled]="scrollMode === 1"
  image="<svg class='pdf-margin-top-3px' width='24px' height='24px' viewBox='0 0 24 24'><path fill='currentColor' d='M6 3c-1 0-1.5.5-1.5 1.5v7c0 1 .5 1.5 1.5 1.5h4c1 0 1.5-.5 1.5-1.5v-7c0-1-.5-1.5-1.5-1.5z' /></svg>"
>
</pdf-shy-button>
