<mat-dialog-content class="alt-theme">
  <h1 mat-dialog-title>Edit UB04 General Information</h1>
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form">

      <div style="display:flex; width:100%;">
        <mat-form-field appearance="outline" class="ub04-review-type">
          <mat-label>UB04 Review Type</mat-label>
          <mat-select formControlName="ub04ReviewType">
            <mat-option *ngFor="let t of ub04ReviewTypes" [value]="t.ub04ReviewType">
              {{ t.ub04ReviewType }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="ub04-review-type">
          <mat-label>UB04 Visit Type</mat-label>
          <mat-select formControlName="visitType">
            <mat-option *ngFor="let t of ub04VisitTypes" [value]="t">
              {{ t }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="ub04-bill-type" appearance="outline">
        <mat-label>Bill Type (Max: 3 digits)</mat-label>
        <input matInput placeholder="Enter Bill Type (NNN)" formControlName="ub04BillType">
      </mat-form-field>
      <mat-form-field class="patient-control-number" appearance="outline">
        <mat-label>Patient Control No</mat-label>
        <input matInput placeholder="Enter Patient Control Number..." formControlName="patientControlNumber">
      </mat-form-field>
      <mat-form-field class="medical-record-number" appearance="outline">
        <mat-label>Medical Record No</mat-label>
        <input matInput placeholder="Enter Medical Record Number..." formControlName="medicalRecordNumber">
      </mat-form-field>
      <mat-form-field class="federal-tax-number" appearance="outline">
        <mat-label>Federal Tax No</mat-label>
        <input matInput placeholder="Enter Federal Tax Number..." formControlName="federalTaxNumber">
      </mat-form-field>

      <mat-form-field class="drg-code" appearance="outline">
        <mat-label>DRG Code</mat-label>
        <lib-text-lookup
          keyName="code"
          valueName="description"
          formControlName="drgCode"
          endpoint="drg-code/list"
          [strictMatch]="false"
        ></lib-text-lookup>
        <mat-error *ngIf="drgCodeFormControl.errors != null">
          The provided code is not a valid DRG code
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
    Mark Complete
  </mat-checkbox>
  <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
      <mat-icon>save</mat-icon> Save
  </button>
  <button mat-flat-button (click)="onCancel()" color="primary">
      <mat-icon>cancel</mat-icon> Cancel
  </button>
</mat-dialog-actions>

