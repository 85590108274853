/**
 * Base Component for UB04 edit dialogs for data entry. This includes the standard endpoint calls
 * for status track locking/unlocking that should be employed by all of the dialogs. This lets the
 * user override the COMPLETE status on each domain/id/element that is status tracked (i.e in
 * this case the locked status of each dialog box on ub04 data entry)
*/
import { StatusTrackService } from "../../status-track/status-track.service";
import { BaseComponent } from "../../view/base.component";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";


export abstract class UB04StatusTrackBaseDialogComponent extends BaseComponent {

  abstract override log;
  statusTrackComplete: boolean = false;
  localStatusTrackService?: StatusTrackService;
  stUB04Id: number = -1;
  stStatusTrackElement: string = "";

  protected constructor(
    override snack: FennecSnackbarService,
    ub04Id: number,
    statusTrackElement: string,
    statusTrackService: StatusTrackService
  ) {
    super();
    this.localStatusTrackService = statusTrackService;
    this.stUB04Id = ub04Id;
    this.stStatusTrackElement = statusTrackElement;
    // Calls up the the server to find out whether or not the status tracking for this ub04
    // (domain, element, ub04id) is locked or not, and sets the property at the class level
    // accordingly. You can then use this to tell the user whether or not this section of the
    // ub04 (which is tracked) is locked or not. It's recommended to use a checkbox for this.
    this.localStatusTrackService.getStatusTrackElement(
      StatusTrackService.DOMAIN_UB04_DATA_ENTRY,
      ub04Id,
      statusTrackElement).subscribe(response => {
        if (response.hasErrors) {
        } else {
          if (response.data != null) {
            // console.log(response);
            this.statusTrackComplete = response.data.statusLocked;
          }
        }
    });

  }

  /**
   * Call this function from a checkbox that toggles the locked status of the status track element
   * you are working with. This will handle updating the locked status of that status track
   * domain / element / id (in this case the ub04id) for you.
   *
   * (i.e. (change)="toggleStatusTrackComplete()")
   */
  toggleStatusTrackComplete() {
    this.statusTrackComplete = !this.statusTrackComplete;
    if (this.statusTrackComplete) {
      this.localStatusTrackService?.lockStatus(
          StatusTrackService.DOMAIN_UB04_DATA_ENTRY,
          this.stUB04Id,
          this.stStatusTrackElement,
          StatusTrackService.STATUS_COMPLETE).subscribe(response => {
        if (response.hasErrors) {
          // no action needed here
        } else {
          // No action needed here
        }
      });
    } else {
      this.localStatusTrackService?.unlockElement(
        StatusTrackService.DOMAIN_UB04_DATA_ENTRY,
        this.stUB04Id,
        this.stStatusTrackElement).subscribe(response => {
      if (response.hasErrors) {
        // No action needed here
      } else {
        // No action needed here
      }
    });
    }
  }

}
