import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import {
  BaseService,
  Crud,
  DataResponse,
  EnvironmentService,
  HealthCarePlanPacket,
  Logger,
  PagedResponse
} from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class HealthCarePlanService extends BaseService {

  baseEndpoint = this.apiEndpoint('health-care-plan');

  constructor(
    public override httpClient: HttpClient
  ) {
    super(new Logger("HealthCarePlanService"));
  }

  _list: Crud.ListFunction<HealthCarePlanPacket> = () => {
    return EMPTY;
  };
  _getById: Crud.GetByIdFunction<HealthCarePlanPacket> = () => {
    return EMPTY;
  };
  _create = (packet:HealthCarePlanPacket) => {
    const endpoint = `${this.baseEndpoint}/create`
    return this.httpClient.post<DataResponse<any>>(endpoint, packet);
  };
  _update = (packet:HealthCarePlanPacket) => {
    const endpoint = `${this.baseEndpoint}/update`
    return this.httpClient.post<DataResponse<any>>(endpoint, packet);
  };
  _deactivate: Crud.DeleteFunction<HealthCarePlanPacket> = () => {
    return EMPTY;
  };
  _delete: Crud.DeleteFunction<HealthCarePlanPacket> = () => {
    return EMPTY;
  }

  getHealthCarePlansByQueryParams = (params:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<HealthCarePlanPacket>>(endpoint, params);
  }

  toggleActiveStatus = (id:number) => {
    const endpoint = `${this.baseEndpoint}/deactivate/${id}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  }

  getHealthCarePlansByRelation = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/crt/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<HealthCarePlanPacket>>(endpoint, packet);
  }

  createHealthCarePlanRelationalMap = (packet: any) => {
    const endpoint = `${this.baseEndpoint}/crt/create`;
    return this.httpClient.post<DataResponse<any>>(endpoint, packet);
  }

  toggleActiveStatusOfHealthCarePlanRelationalMap = (id:number) => {
    const endpoint = `${this.baseEndpoint}/crt/delete/${id}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  }

  getHealthCarePlansForUserTenantCompanies = (userId:number, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/user-tenant-company/${userId}/list?first=${first}&max=${max}`;
    return this.httpClient.get<PagedResponse<HealthCarePlanPacket>>(endpoint);
  }

  getHealthCarePlansForTenantCompaniesAndFilters = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/user-tenant-company/search?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<HealthCarePlanPacket>>(endpoint, packet);
  }

  getHealthCarePlansForUserTenantCompanyIdAndUserId = (packet:any) => {
    const endpoint = `${this.baseEndpoint}/user-tenant-company/user-assigned`;
    return this.httpClient.post<PagedResponse<HealthCarePlanPacket>>(endpoint, packet);
  }

  protected _crudFunctions: Crud.CrudServiceDef<HealthCarePlanPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  }
}
