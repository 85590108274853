import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from "../environment.service";
import { BaseResponse } from "../model/base-response";

@Injectable({
  providedIn: 'root'
})
export class StatusTrackService {

  public static DOMAIN_MI_CASE: string = "MI_CASE";
  public static DOMAIN_UB04_DATA_ENTRY: string = "UB04_DATA_ENTRY";
  public static ELEMENT_MI_CASE_DBRD_SAVINGS: string = "MI_CASE_DBRD_SAVINGS";
  public static ELEMENT_MI_CASE_MAIN_STATUS: string = "MI_CASE_MAIN_STATUS";
  public static ELEMENT_MI_CASE_CF_STATUS: string = "MI_CASE_CF_STATUS";
  public static ELEMENT_UB04_EDIT1: string = "UB04_EDIT1";
  public static ELEMENT_UB04_EDIT_PATIENT: string = "UB04_EDIT_PATIENT";
  public static ELEMENT_UB04_EDIT_PROVIDER: string = "UB04_EDIT_PROVIDER";
  public static ELEMENT_UB04_EDIT_PAYER: string = "UB04_EDIT_PAYER";
  public static ELEMENT_UB04_EDIT_REV_CODE: string = "UB04_EDIT_REV_CODE";
  public static ELEMENT_UB04_EDIT_DIAGNOSIS_CODE: string = "UB04_EDIT_DIAGNOSIS_CODE";
  public static ELEMENT_UB04_EDIT_CONDITION_CODE: string = "UB04_EDIT_CONDITION_CODE";
  public static ELEMENT_UB04_EDIT_OCCURRENCE_CODE: string = "UB04_EDIT_OCCURRENCE_CODE";
  public static ELEMENT_UB04_EDIT_VALUE_CODE: string = "UB04_EDIT_VALUE_CODE";
  public static ELEMENT_UB04_EDIT_PROCEDURE_CODE: string = "UB04_EDIT_PROCEDURE_CODE";
  public static ELEMENT_UB04_EDIT_REMARKS: string = "UB04_EDIT_REMARKS";
  public static STATUS_COMPLETE: string = "COMPLETE";
  public static STATUS_NONE: string = "NONE";
  public static STATUS_READY: string = "READY";

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getStatusTrackListForDomainRelatedId(domain: string, relatedId: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "status-track/" + domain + "/" + relatedId.toString());
  }

  getStatusTrackLogListForStatusTrackId(id: number, first: number, max: number): Observable<BaseResponse> {
    let urlFragment = "?first=" + first + "&max=" + max;
    return this.httpClient.get<any>(this.environment.baseApiUrl + "status-track/logs/" + id.toString() + urlFragment);
  }  

  /**
   * Get's the actual status track record (element tracking record) this is used to track an element that is linked to a domain and
   * related id.
   */
  getStatusTrackElement(domain: string, relatedId: number, element: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "status-track/element/"
      + domain + "/" + relatedId.toString() + "/" + element);
  }

  getManualStatusTrackStatusListForElement(element: string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "status-track/manual-assign-status-track-status-list/"
      + element);
  }  

  lockStatus(domain: string, relatedId: number, element: string, status: string): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "status-track/lock/"
      + domain + "/" + relatedId.toString() + "/"
      + element + "/" + status, {});
  }

  unlockElement(domain: string, relatedId: number, element: string): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "status-track/unlock/"
      + domain + "/" + relatedId.toString() + "/" + element, {});
  }

  updateStatusTrackStatus(domain: string, relatedId: number, element: string, status: string): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "status-track/update/"
      + domain + "/" + relatedId.toString() + "/" + element + "/" + status, {});
  }

}
