import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PagedResponse } from "../model/paged-response";
import { EnvironmentService } from "../environment.service";

@Injectable({
    providedIn: 'root'
  })
export class ActivityLogService {
  public static ELEMENT_XFWF_TASK: string = "XFWF_TASK";

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) {}

  getAllActivityLogForXFWFTask(entity: string, relatedId: number, user: string, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-log/list/${entity}/${relatedId}/${user}?first=${first}&max=${max}`);
  }

  getAllActivityLogForEntityRelatedId(entity: string, relatedId: number, user: string, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-log/list/${entity}/${relatedId}/${user}?first=${first}&max=${max}`);
  }

  getAllActivityLogForLineItem(lineItemId: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-log/itemized-line-item-list/${lineItemId}`);
  }

  getAllActivityLogForUB04Entity(ub04Id: number, activityLogEntity: string, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-log/ub04-child-list/${activityLogEntity}/${ub04Id}?first=${first}&max=${max}`);
  }  

}
