import {Injectable, OnDestroy} from "@angular/core";
import {ReplaySubject} from "rxjs";

import {HttpClient} from "@angular/common/http";
import { Logger } from "../util/logger";

@Injectable()
export abstract class BaseService implements OnDestroy {
  public abstract baseEndpoint?: string;
  protected abstract httpClient: HttpClient;
  protected constructor(public log: Logger) {}

  public destroyed$ = new ReplaySubject<void>(1);
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  apiEndpoint(url: string): string {
    return url && url.includes('attachment')
    ? `attachment:${url}`
    : `api:${url}`;
  }

  endpoint(url: string): string {
    return this.baseEndpoint
      ? `/${this.baseEndpoint}/${url}`
      : `/${url}`;
  }
}
