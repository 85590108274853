import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DataEntryService } from './data-entry.service';
import {FennecSnackbarService} from "xf-common";

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent implements OnInit {

  constructor(
    private dataEntryService: DataEntryService,
    protected snack: FennecSnackbarService
  ) { }

  ngOnInit(): void {

  }

}
