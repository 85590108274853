<div #topLevelWrapper class="itemized-line-item-parent-wrapper"
  [style.height.px]="componentHeightPX">
  <div class="itemized-line-item-left-nav">
    <button mat-raised-button color="accent" (click)="closeItemizedLineItemComponent()"><mat-icon>arrow_back</mat-icon>Return to Overview</button>
    <div class="itemized-line-item-rev-header-line">IRev #: {{itemizedRevisionId}}</div>    
    <div>
      <table class="itemized-revision-summary-table">
        <tr>
          <td>Line Items: </td>
          <td class="itemized-revision-summary-number">{{lineItemCount}}</td>
        </tr>
        <tr>
          <td>Total Bill Amt: </td>
          <td class="itemized-revision-summary-number">{{totalBilledAmount | currency}}</td>
        </tr>
        <tr>
          <td>Total Adj Amt: </td>
          <td class="itemized-revision-summary-number">{{totalAdjustedAmount | currency}}</td>
        </tr>
        <tr>
          <td>Savings Amt: </td>
          <td [ngClass]="{'itemized-revision-summary-savings': (totalBilledAmount - totalAdjustedAmount) > 0, 'itemized-revision-summary-no-savings' : (totalBilledAmount - totalAdjustedAmount) == 0}">
            {{totalBilledAmount - totalAdjustedAmount | currency}}
          </td>
        </tr>                     
      </table>
    </div>
    <div class="line-item-query-filter-div">
      <div class="line-item-query-filter-header"> 
        Line Item Filters
        <button mat-icon-button (click)="clearAllFilters()" matTooltip="Clear All filters">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <form [formGroup]="lineItemQueryFormGroup">
        <mat-form-field appearance="outline">
          <mat-label>Service Date</mat-label>
          <mat-select formControlName="serviceDateString" (selectionChange)="serviceDateSelectionChanged($event.value)">
            <mat-option *ngFor="let sds of serviceDateStrings" [value]="sds">{{sds}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>  
  </div>
  <div class="itemized-line-item-grid">
    <div class="itemized-line-item-grid-header-div">
      <div class="itemized-line-item-grid-header-child">
        <form [formGroup]="descriptionFilterFormGroup">
          <mat-form-field class="description-filter-input">
            <mat-label>Search Description</mat-label>
            <input matInput id="description" formControlName="description" />
          </mat-form-field>
          <button mat-icon-button (click)="onDescriptionSearch()" matTooltip="Search by Description">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-icon-button (click)="clearDescriptionFilter()" matTooltip="Clear Description filter">
            <mat-icon>clear</mat-icon>
          </button>            
        </form>        
      </div>
      <div class="itemized-line-item-grid-header-child itemized-line-item-grid-header-sum">
        # Lines: <span class="num-highlight">{{itemizedLineItems.lineItemCount}}</span>
        Total Billed: <span class="num-highlight">{{itemizedLineItems.totalBilledAmount | currency}}</span>
        Total Adj: <span class="num-highlight">{{itemizedLineItems.totalAdjustedAmount | currency}}</span>      
      </div>
      <div class="itemized-line-item-grid-header-child">
        <mat-paginator 
          [length]="totalRowCount" [pageSize]="defaultPageSize" 
          [pageSizeOptions]="defaultPageSizeOptions">
        </mat-paginator>
      </div>
    </div>
    <table *ngIf="screenMode == 'DISPLAY'" class="itemized-line-item-grid-table">
      <thead>
        <tr>
          <th style="width: 100px">Date</th>
          <th style="width: 80px">Rev</th>
          <th style="width: 80px">CPT</th>
          <th>Description</th>
          <th style="width: 90px; text-align: right;">Units</th>
          <th style="width: 150px; text-align: right;">Billed</th>
          <th style="width: 150px ; text-align: right;">Adj</th>
          <th style="width: 150px" *ngIf="userIsDataEntry">Actions</th>
        </tr>
      </thead>
      <tr class="line-item-data-row" *ngFor="let li of itemizedLineItems.lineItems">
        <td class="line-item-data-cell">{{formatServiceDate(li)}}</td>
        <td [ngClass]="li.revenueCode == '9999' ? 'line-item-data-cell-error' : 'line-item-data-cell'">{{li.revenueCode}}</td>
        <td class="line-item-data-cell">{{li.cptCode}}</td>
        <td class="line-item-data-cell">{{li.description}}</td>
        <td class="line-item-data-cell-number">{{li.units | number:'1.2-2'}}</td>
        <td class="line-item-data-cell-number">{{li.billedAmount | currency:'USD':'symbol' }}</td>
        <td class="line-item-data-cell-number">{{li.adjustedAmount | currency:'USD':'symbol' }}</td>
        <td class="line-item-data-cell" *ngIf="userIsDataEntry">
          <button class="li-action-button" (click)="onDelete(li.id)" matTooltip="Delete Line Item">
            <mat-icon>delete</mat-icon>
          </button>          
          <button class="li-action-button" (click)="onEdit(li.id)" matTooltip="Edit Line Item">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="li-action-button" (click)="onCopyLineItem(li.id)" matTooltip="Copy/Clone Line Item">
            <mat-icon>content_copy</mat-icon>
          </button>          
        </td>                
      </tr>
    </table>
    <div [hidden]="!(screenMode == 'EDIT')" class="itemized-line-item-grid-table">
      <app-itemized-line-item-bulk-editor #itemizedLineItemBulkEditorComponent
        [itemizedRevisionId]="itemizedRevisionId">
      </app-itemized-line-item-bulk-editor>
    </div>
  </div>
  <div *ngIf="screenMode == 'DISPLAY'" class="itemized-line-item-right-nav">
    <div>
      <button mat-flat-button (click)="createLineItem()" matTooltip="Add New Line Item" *ngIf="userIsDataEntry || userIsAdmin">
        <mat-icon>add_circle</mat-icon>
        New
      </button>     
    </div>
    <div class="bulk-create-line-items-div">
      <form [formGroup]="lineItemCreateParameters">
        <mat-form-field appearance="outline" color="accent" class="bulk-create-line-item-input">
          <mat-label>
            Line Item #
          </mat-label>
          <input matInput type="number"
            id="bulkCreateLineItemCount"
            formControlName="bulkCreateLineItemCount"/>
        </mat-form-field>
      </form>
      <button mat-flat-button (click)="createLineItemBulk()" matTooltip="Add New Line Item" *ngIf="userIsDataEntry || userIsAdmin">
        <mat-icon>add_circle</mat-icon> <mat-icon>add_circle</mat-icon>
        New (N)
      </button>     
    </div>    
  </div>

</div>
