<h2 class="header">Edit Stay</h2>
<!--<p class="sub-header">Editing alternate ID: {{case?.altExternalId}}</p>-->
<mat-dialog-content *ngIf="formGroup">
  <form [formGroup]="formGroup" class="alt-theme alt-edit-form">
    <mat-form-field>
      <mat-label>Client Name</mat-label>
      <input class="input" matInput formControlName="name" placeholder="Enter name">
      <mat-error *ngIf="formGroup.get('name')?.hasError('required') && formGroup.get('name')?.touched">
        Name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Alternate External ID</mat-label>
      <input class="input" matInput formControlName="altExternalId" placeholder="Enter external ID" [value]="formGroup.get('altExternalId')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Salesforce Case ID</mat-label>
      <input class="input" matInput formControlName="salesforceCaseId" placeholder="Salesforce Case ID" [value]="formGroup.get('salesforceCaseId')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Client Claim ID</mat-label>
      <input class="input" matInput formControlName="clientClaimId" placeholder="Client Claim ID" [value]="formGroup.get('clientClaimId')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Client Member Number</mat-label>
      <input class="input" matInput formControlName="clientMemberNumber" placeholder="Client Member Number" [value]="formGroup.get('clientMemberNumber')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Insured Group Name</mat-label>
      <input matInput formControlName="insuredGroupName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Insured Group Number</mat-label>
      <input matInput formControlName="insuredGroupNo">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" [disabled]="!this.canUpdateType" [value]="formGroup.get('type')?.value">
        <mat-option *ngFor="let t of typeList" [value]="t.value">
          {{ t.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Line of Business</mat-label>
      <mat-select formControlName="lineOfBusiness" [disabled]="!this.canUpdateLOB" [value]="formGroup.get('lineOfBusiness')?.value">
        <mat-option *ngFor="let t of linesOfBusinessList" [value]="t.value">
          {{ t.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Par/Non-Par Provider</mat-label>
      <mat-select formControlName="providerPar" [value]="formGroup.get('providerPar')?.value">
        <mat-option *ngFor="let t of providerParList" [value]="t">
          {{ t }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Pre 6DH Review Payable</mat-label>
      <mat-icon matPrefix>attach_money</mat-icon>
      <input class="input" type="number" matInput formControlName="preReviewPayable" [value]="formGroup.get('preReviewPayable')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Post 6DH Review Payable</mat-label>
      <mat-icon matPrefix>attach_money</mat-icon>
      <input class="input" type="number" matInput formControlName="postReviewPayable" [value]="formGroup.get('postReviewPayable')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Post Appeal Payable</mat-label>
      <mat-icon matPrefix>attach_money</mat-icon>
      <input class="input" type="number" matInput formControlName="postAppealPayable" [value]="formGroup.get('postAppealPayable')?.value">
    </mat-form-field>

    <mat-form-field class="drg-paid-date">
      <mat-label>DRG Paid Date</mat-label>
      <input matInput placeholder="(MM/DD/YYYY)" formControlName="drgPaidDate">
      <mat-error *ngIf="formGroup.controls['drgPaidDate'].errors != null">
        Please enter a date in MM/DD/YYYY format
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>DRG Payment Amount</mat-label>
      <mat-icon matPrefix>attach_money</mat-icon>
      <input class="input" type="number" matInput formControlName="drgPaymentAmount" [value]="formGroup.get('drgPaymentAmount')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Network Discount</mat-label>
      <mat-icon matSuffix>percent</mat-icon>
      <input class="input" type="number" matInput formControlName="networkDiscount" [value]="formGroup.get('networkDiscount')?.value">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="customerPriority" [disabled]="!this.canUpdatePriority" [value]="formGroup.get('customerPriority')?.value">
        <mat-option *ngFor="let t of customerPriorityList" [value]="t.value">
          {{ t.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Trial Case</mat-label>
<!--      Option 1: Suffix checkbox (right-aligned) -->
      <input matInput disabled>
      <mat-checkbox formControlName="trialCase" matSuffix></mat-checkbox>
<!--      Option 2: Checkbox underneath mat-label -->
<!--      <input matInput disabled value=" ">-->
<!--      <mat-checkbox formControlName="trialCase"></mat-checkbox>-->
    </mat-form-field>
    <mat-form-field>
      <mat-label>Case Review Due Date (MM/DD/YYYY)</mat-label>
      <input class="input" matInput formControlName="caseReviewDueDate" placeholder="Due Date (MM/DD/YYYY)" [value]="formGroup.get('caseReviewDueDate')?.value">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Post Payment Review</mat-label>
      <input matInput disabled>
      <mat-checkbox formControlName="postPaymentReview" matSuffix [value]="formGroup.get('postPaymentReview')?.value"></mat-checkbox>
    </mat-form-field>

    <mat-form-field class="client-internal-adjustments">
      <mat-label>Client Internal Adjustments</mat-label>
      <input matInput type="number" formControlName="clientInternalAdjustments" (wheel)="$event.preventDefault()">
      <mat-icon matPrefix>attach_money</mat-icon>
    </mat-form-field>

    <mat-form-field style="width:100%" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea matInput formControlName="notes" rows="7" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="actions" style="display: flex; justify-content: flex-end;" *ngIf="formGroup">
  <button
    mat-flat-button
    color="accent"
    (click)="onSave()"
    [disabled]="formGroup.invalid">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-stroked-button
    (click)="onCancel()">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>
