<div style="margin-top: 15px;">

  <div class="no-attachments-found" *ngIf="attachmentList.length <= 0">
    No Attachments Found!
  </div>

  <!-- Existing Attachment List -->
  <div class="attachment-list-div" *ngIf="attachmentList.length > 0">
    <mat-grid-list cols="{{attachmentList.length}}" rowHeight="fit" gutterSize="15px">
      <ng-container *ngFor="let attachment of attachmentList">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z8 attachment-card" [ngStyle]="getSelectedStyle(attachment.id)">
            <mat-card-header>
              <mat-card-title>{{attachment.name}}</mat-card-title>
              <mat-card-subtitle>Attachment Type: {{attachment.attachmentType}}</mat-card-subtitle>
              <mat-card-subtitle>Attachment ID: {{attachment.id}}</mat-card-subtitle>

              <mat-menu #appMenu="matMenu">
                <button
                  *ngIf="isFilePdf(attachment)"
                  mat-menu-item
                  color="accent"
                  (click)="viewAttachment(attachment)"
                >
                  <mat-icon color="accent">picture_as_pdf</mat-icon>
                  View PDF
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  *ngIf="canDownload(attachment)"
                  (click)="downloadAttachment(attachment.id)"
                  >
                  <mat-icon color="accent">download</mat-icon>
                  <span>Download</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  [disabled]="!canEmail(attachment)"
                  (click)="emailAttachment(attachment.id)">
                  <mat-icon color="accent">email</mat-icon>
                  <span>Email</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  [disabled]="!canDelete(attachment)"
                  (click)="deleteAttachmentConfirmation(attachment)">
                  <mat-icon color="accent">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
              <button style="margin-left:auto;" class="menu-icon" mat-icon-button [matMenuTriggerFor]="appMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <!-- <button style="margin-left:auto;" mat-button title="Delete Attachment" (click)="deleteAttachmentConfirmation(attachment)"><mat-icon>delete</mat-icon></button> -->
            </mat-card-header>
            <mat-card-content>
              <p>
                <span class="global-data-element-label-span">Created:</span><span class="global-data-element-value-span">{{getFormattedDate(attachment)}}</span>
                <ng-container *ngIf="attachment.reportReceived">
                  - <span class="global-data-element-value-span" style="color: green">Received</span>
                </ng-container>
              </p>
            </mat-card-content>

            <mat-card-actions>
              <button *ngIf="context=='MICASEATTACHMENTS'" mat-raised-button color="accent" 
                (click)="selectAttachment(attachment.id)">Select
              </button>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>

    <!-- <button 
      mat-icon-button 
      class="arrow-button" 
      id="left" (click)="getAttachmentViewList(currentIndex - attachmentViewAmount)" 
      [disabled]="currentIndex == 0"><mat-icon>arrow_circle_left</mat-icon>
    </button>

    <button 
      mat-icon-button 
      class="arrow-button" 
      id="right" (click)="getAttachmentViewList(currentIndex + attachmentViewAmount)" 
      [disabled]="currentIndex + 5 >= attachmentList.length"><mat-icon>arrow_circle_right</mat-icon>
    </button> -->

    <mat-paginator
      #paginator
      [pageSize]="defaultPageSize"
      [length]="totalRowCount"
      (page)="getAttachmentList()"
    ></mat-paginator>
  </div>

  <!-- Selected Attachment -->
  <div class="selected-attachment-container">
    <mat-card *ngIf="selectedAttachment != null" [@fadeInOut] class="selected-attachment-card">
      <mat-card-title style="display:flex;">
        <mat-card-header>
        Selected Attachment
        </mat-card-header> 
        <button mat-icon-button class="close-button" (click)="deselectAttachment()"><mat-icon>close</mat-icon></button>
      </mat-card-title>
      
      <mat-card-content>
        <div style="display:flex; justify-content: space-around; margin-left: 15px; margin-right:15px;">
          <div>
            <p><span class="global-data-element-label-span">Name:</span><span class="global-data-element-value-span">{{selectedAttachment.name}}</span></p>
            <p><span class="global-data-element-label-span">Type:</span><span class="global-data-element-value-span">{{selectedAttachment.attachmentType}}</span></p>
          </div>

          <div>
            <p><span class="global-data-element-label-span">ID:</span><span class="global-data-element-value-span">{{selectedAttachment.id}}</span></p>
            <p><span class="global-data-element-label-span">Created:</span><span class="global-data-element-value-span">{{getFormattedDate(selectedAttachment)}}</span></p>
          </div>
        </div>
      </mat-card-content>
  
      <mat-card-actions style="float:right;">
        <button 
          *ngIf="attachmentTypesExtended[selectedAttachment.attachmentType]?.allowReportReceived"
          mat-raised-button color="accent"
          (click)="confirmSetReportReceivedDialog(selectedAttachment)">
            <mat-icon>fact_check</mat-icon>
            <ng-container *ngIf="selectedAttachment.reportReceived">
              Set Report NOT Received
            </ng-container>
            <ng-container *ngIf="!selectedAttachment.reportReceived">
              Set Report Received
            </ng-container>            
        </button>

        <button 
          *ngIf="selectedAttachment.attachmentType == 'Itemized'"
          mat-raised-button color="accent"
          (click)="convertToItemized(selectedAttachment.id)">
            <mat-icon>insights</mat-icon>
            Process/Create Itemized 
        </button>
    
        <button 
          *ngIf="canDownload(selectedAttachment)"
          mat-raised-button color="accent"
          (click)="downloadAttachment(selectedAttachment.id)"
        >
        <mat-icon>download</mat-icon>
        Download</button>
    
        <button 
          mat-raised-button 
          color="accent" 
          (click)="emailAttachment(selectedAttachment.id)">
          <mat-icon>email</mat-icon>
          Email
        </button>
        
        <button 
          style="margin-left: 3px;" 
          mat-raised-button 
          title="Delete Attachment" 
          color="accent"
          (click)="deleteAttachmentConfirmation(selectedAttachment)">
          <mat-icon>delete</mat-icon>Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>


  <!-- Upload File Attachment Div-->
  <div *ngIf="showUpload=='true'">
    <div class="create-upload-attachment-header">
      <h1>Create and Upload Attachment</h1>
    </div>
    <div class="container">
      <div class="inside">

        <div class="input-group alt-theme">
          <mat-form-field appearance="outline" class="name-input">
            <mat-label>Document Name</mat-label>
            <input matInput [(ngModel)]="name">
          </mat-form-field>

          <mat-form-field appearance="outline" class="type-input">
            <mat-label>Attachment Type</mat-label>
            <mat-select
              [(ngModel)]="attachmentType"
              [ngModelOptions]="{standalone: true}"
              >
              <mat-option *ngFor="let at of attachmentTypes" [value]="at">{{ at }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h5>{{ file ? "Current file: " + file.name : "No File Chosen" }}</h5>
        <input
        #fileUploadInput
        type="file"
        name="fileUpload"
        (change)="addFile(fileUploadInput)"
        [hidden]="true">

        <div class="button-group">
            <button
              [disabled]="uploading"
              class="btn"
              mat-raised-button
              color="primary"
              #upload
              (click)="fileUploadInput.click()">
                <mat-icon>upload</mat-icon> 
                Choose file
            </button>

            <button
              class="btn"
              mat-raised-button
              color="accent"
              (click)="onSave()"
              [disabled]="disableSave()"
              >
              <mat-icon>save</mat-icon> 
              {{uploading ? "Uploading..." : "Save"}}
            </button>

          </div>

          <div *ngIf="uploading" class="spinner">
            <mat-spinner color="accent"></mat-spinner>
          </div>
      </div>
    </div>
  </div>
</div>
