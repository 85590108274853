<div class="create-form alt-theme">
    <form [formGroup]="formGroup" class="container">

      <mat-form-field 
        class="prompt-NPI"
        *ngIf="formGroup.contains('npiNumber')">
        <mat-label>NPI Number</mat-label>
        <input matInput placeholder="Enter NPI (Complete)" formControlName="npiNumber">
      </mat-form-field>

      <mat-form-field 
        *ngIf="formGroup.contains('name')"
        class="prompt-name">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Enter Name (Partial)" formControlName="name">
      </mat-form-field>

      <mat-form-field 
        class="prompt-first-name"
        *ngIf="formGroup.contains('firstName')"
      >
        <mat-label>First Name</mat-label>
        <input matInput placeholder="Enter First Name (Partial)" formControlName="firstName">
      </mat-form-field>

      <mat-form-field 
        class="prompt-last-name"
        *ngIf="formGroup.contains('lastName')">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Enter Last Name (Partial)" formControlName="lastName">
      </mat-form-field>

      <button mat-button mat-stroked-button class="search-button" (click)="onSearch()">
        <mat-icon>search</mat-icon>
      </button> 
                                 
      <button mat-button mat-stroked-button class="search-button" (click)="onClearSearch()">
        <mat-icon>clear</mat-icon>
      </button> 
    </form>
    
    <mat-expansion-panel style="margin-bottom: 10px;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>EIN Search</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
        <div class="alt-theme">
          <mat-form-field appearance="outline" style="width:84%; margin-right: 8px;">
            <mat-label>EIN/Federal Tax Number</mat-label>
            <input matInput [(ngModel)]="federalTaxNumber">
          </mat-form-field>

          <button
            mat-flat-button
            color="accent"
            (click)="providerEINSearch()"
            style="margin-right: 4px;"
          >
            EIN Search
          </button>

          <button 
            mat-stroked-button 
            color="accent" 
            (click)="onClearSearch()"
          >
            Clear
          </button>
        </div>
    </mat-expansion-panel>
  </div>

  <div *ngIf="searchResults.length === 0">
    No Results found
  </div>

  <!-- Display List of Search Results-->
  <div *ngIf="searchResults.length > 0">
    Search Results
    <mat-table style="max-height: 50vh; overflow: auto;" [dataSource]="searchResults">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
        <mat-cell *matCellDef="let result"> {{result?.id == -1 ? "External" : result?.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          {{result?.name}} 
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="healthCarePlanName">
        <mat-header-cell *matHeaderCellDef> Plan Name</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          {{result?.healthCarePlanName}} 
        </mat-cell>
      </ng-container>      

      <ng-container matColumnDef="name/npi">
        <mat-header-cell *matHeaderCellDef> Name / NPI Number</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          Name: {{result?.name}} <br> 
          NPI: {{result?.npiNumber}} <mat-icon *ngIf="doesExternalNPIExistOnList(result)" class="check-icon" matTooltip="NPI Number already stored in Fennec Database">check</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell *matCellDef="let result"> {{result?.providerType}} </mat-cell>
      </ng-container>      

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> First Name</mat-header-cell>
        <mat-cell *matCellDef="let result"> {{result?.firstName}} </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Last Name</mat-header-cell>
        <mat-cell *matCellDef="let result"> {{result?.lastName}} </mat-cell>
      </ng-container>     
      
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          {{ result?.addressLine1 }} {{ result?.addressLine2 }} <br>
          {{ result?.city }}, {{ result?.stateCode }} {{ result?.zipCode }}
        </mat-cell>
      </ng-container> 

      <ng-container matColumnDef="payerAddress">
        <mat-header-cell *matHeaderCellDef> Address</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          {{ result?.address }}
          {{ test(result) }}
        </mat-cell>
      </ng-container> 

      <ng-container *ngIf="selectMode === 'MULTI_SELECT'" matColumnDef="ub04ProviderActions">
        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          <button mat-button mat-stroked-button class="ub04-provider-action-button" 
            (click)="onUB04ProviderSelected(result?.id, 'PRIMARY')">
            <mat-icon>check</mat-icon> Primary</button>
          <button mat-button mat-stroked-button class="ub04-provider-action-button"
            (click)="onUB04ProviderSelected(result?.id, 'ATTENDING')">
            <mat-icon>check</mat-icon> Attending</button>
          <button mat-button mat-stroked-button class="ub04-provider-action-button" 
            (click)="onUB04ProviderSelected(result?.id, 'OPERATING')">
            <mat-icon>check</mat-icon> Operating</button>
        </mat-cell>
      </ng-container>
      
      <ng-container *ngIf="selectMode !== 'MULTI_SELECT'" matColumnDef="ub04ProviderActions">
        <mat-header-cell *matHeaderCellDef> Select</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          <button mat-button mat-stroked-button class="ub04-provider-action-button" 
            (click)="selectUB04Provider(result)">
            <mat-icon>check</mat-icon> Select</button>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="selectMode !== 'MULTI_SELECT'" matColumnDef="ub04PayerActions">
        <mat-header-cell *matHeaderCellDef> Select</mat-header-cell>
        <mat-cell *matCellDef="let result"> 
          <button mat-button mat-stroked-button class="ub04-provider-action-button" 
            (click)="selectUB04Payer(result)">
            <mat-icon>check</mat-icon> Select</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        (click)="onSelect(row)"
        [ngStyle]="getRowStyle(row)"
      ></mat-row>
    </mat-table>
  </div>
