<mat-dialog-content>
    <div class="dialog-header">
      <h1 mat-dialog-title>Edit Revenue Code Information</h1>
      <!-- <button 
        mat-button
        mat-stroked-button 
        class="ub04-icon-btn"
        tabindex="-1"
        (click)="onCancel()"
        matTooltip="Close Dialog">
        <mat-icon>close</mat-icon>
      </button> -->
    </div>
    <div style="clear: both;"></div>
    <div [formGroup]="formGroup" class="content-wrapper alt-theme">
      <ng-container formArrayName="revenueCodes">
          <ng-container *ngFor="let rcRow of revenueCodes.controls; let i = index">
              <div #revCodeForm class="lesson-form-row" [formGroup]="getRevenueCodeFormGroup(i)" [ngStyle]="getRowDisplayStyle(i)">
                <mat-icon
                  class="ub04-field-status-icon"
                  style={{getIconStyle(i)}}
                >
                {{ getDeleteRowByIdx(i) ? "remove_circle_outline" : rcRow.valid ? "check_circle" : "error" }}
              </mat-icon>
                <mat-form-field appearance="outline" [style.width.px]=150>
                  <mat-label>Revenue Code</mat-label>
                  <input #revenueCode matInput formControlName="revenueCode" placeholder="Revenue Code">
                </mat-form-field>
                <mat-form-field appearance="fill" [style.width.px]=500>
                  <mat-label>Description</mat-label>
                  <input matInput formControlName="description" placeholder="Description">
                </mat-form-field>
                <mat-form-field appearance="outline" [style.width.px]=150>
                  <mat-label>Units</mat-label>
                  <input matInput formControlName="units" placeholder="Units" type="number" (wheel)="$event.preventDefault()">
                </mat-form-field>
                <mat-form-field appearance="outline" [style.width.px]=200>
                  <mat-label>Amount</mat-label>
                  <input matInput formControlName="amount" placeholder="Amount" type="number" (wheel)="$event.preventDefault()">
                </mat-form-field> 
                <button 
                  *ngIf="!getDeleteRowByIdx(i)"
                  mat-flat-button 
                  matTooltip="Delete"
                  class="ub04-icon-btn"
                  (click)="onDelete(i)">
                  <mat-icon>delete</mat-icon>
                </button>         
                
                <button 
                  *ngIf="getDeleteRowByIdx(i)"
                  mat-flat-button 
                  matTooltip="Cancel Delete"
                  class="ub04-icon-btn"
                  (click)="onDelete(i)">
                  <mat-icon>restore_from_trash</mat-icon>
                </button>                                                   
              </div>
          </ng-container>
      </ng-container>  
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions class="fennec-controls">
    <div class="ub04-dialog-add-btn">
      <button 
        mat-button 
        mat-stroked-button 
        matTooltip="Add"
        class="ub04-icon-btn"
        (click)="onAddRevenueCodeLine()">
        <mat-icon>add_circle</mat-icon>
        Add Revenue Code
      </button>
    </div>
    
    <div>
      <!-- <span style="width:150px;">{{unitsSubtotal}}</span> -->
      <mat-form-field [style.width.px]=150>
        <mat-label>Units Subtotal</mat-label>
        <input matInput [value]="unitsSubtotal" disabled>
      </mat-form-field>

      <!-- <span style="width:200px;">${{dollarAmountSubtotal}}</span> -->
      <mat-form-field [style.width.px]=200>
        <mat-label>Amount Subtotal</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input matInput [value]="dollarAmountSubtotal" disabled>
      </mat-form-field>
      
      <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
        Mark Complete
      </mat-checkbox>
      
      <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid || updateInFlight" color="accent">
        <mat-icon>save</mat-icon> Save
      </button>
      <button mat-button mat-stroked-button (click)="onCancel()" [disabled]="updateInFlight">
        <mat-icon>cancel</mat-icon> Cancel
      </button>
    </div>
  </mat-dialog-actions>
