<span class="loadingInput start">
  <input
    type="number"
    id="pageNumber"
    class="toolbarField pageNumber"
    title="Page"
    value="1"
    size="4"
    min="1"
    data-l10n-id="pdfjs-page-input"
    autocomplete="off"
  />
</span>
<span [class]="showPagingButtons | responsiveCSSClass : 'hiddenXLView'" id="numPages" class="toolbarLabel"></span>
