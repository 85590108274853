
<div matDialogTitle>
  {{title}}
  </div>
  
  <mat-dialog-content>
    <pre>{{jsonContent | json}}</pre>
  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button
      mat-stroked-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
      Close
    </button>
  </mat-dialog-actions>