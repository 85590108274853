
<div *ngIf="showFilterParams" class="dashboard-tabulator-filter-display-div-wrapper">
  <span class="dashboard-tabulator-param-span" *ngFor="let fp of filterParams">
    {{fp.label}}: <span class="dashboard-tabulator-param-value-span">{{fp.displayValue}}</span>
  </span>
</div>
<div [hidden]="!isDataEmpty() || isDataLoading()" class="dashboard-tabulator-no-data">
  No data for current dashboard.
</div>
<div [hidden]="!isDataLoading()" class="dashboard-tabulator-no-data">
  Executing Dashboard Query...
</div>
<div [hidden]="isDataEmpty() || isDataLoading()" class="dashboard-tabulator-grid-wrapper" #tabularGridWrapper id="tabular-grid-wrapper"></div>
