import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AttachmentService } from '../attachment.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import {BaseComponent, FennecSnackbarService, FilePacket, FileType, FileUtil, Logger} from "xf-common";

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent extends BaseComponent implements OnInit {
  protected log: Logger = new Logger("PDFViewerComponent");

  attachmentId:string = "-1";
  attachment: null | undefined | string = null;
  loading: boolean = true;

  constructor(
    protected attachmentService: AttachmentService,
    protected snack: FennecSnackbarService,
    private route: ActivatedRoute
  ) {
    super();
    this.attachmentId = this.route?.snapshot?.paramMap?.get('id') ?? "-1";
    this.getBlob();
   }

  ngOnInit(): void {
  }

  getBlob = () => {
    if(this.attachmentId == "-1") {
      this.loading = false;
      return;
    }

    const attachmentId = parseInt(this.attachmentId);

    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        // super.showSuccessSnack("downloading attachment");
        const result = FileUtil.downloadBlob(response, FileType.PDF);
        if(result !== null) {
          this.attachment = URL.createObjectURL(result);
        }

        this.loading = false;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

}
