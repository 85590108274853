
export class UB04RemarksUpdatePacket {
    id: number = -1
    remarks?: string | null
    coverPeriodFrom?: string | null
    coverPeriodThru?: string | null
    admissionDate?: string | null
    admissionHour?: number | null
    dischargeHour?: number | null
    admissionType?: string | null
    admissionSourceCode?: string | null
    dischargeStatusCode?: string | null
    admissionSourceDescription?: string | null
    dischargeStatusDescription?: string | null
}
