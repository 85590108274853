
<div matDialogTitle>
  <div>
    Activity Log
  </div>
  <div class="activity-log-dialog-sub-title">
    Entity: <b>{{configData?.activityLogEntity}}</b>
    Related Id: <b>{{configData?.relatedId}}</b>
  </div>
</div>
  
  <mat-dialog-content>
    <ng-container *ngIf="noData">
      <div class="no-activity-log-data-available">
        No Activity Log data available!
      </div>
    </ng-container>

    <ng-container *ngIf="!noData">
    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="activityLogType">
        <mat-header-cell *matHeaderCellDef> Log Type</mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.activityLogType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ub04ChildCode">
        <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.ub04ChildCode}} </mat-cell>
      </ng-container>        

      <ng-container matColumnDef="changed">
          <mat-header-cell *matHeaderCellDef>Value Changed</mat-header-cell>
          <mat-cell *matCellDef="let c">
            {{
              c?.changes?.length > 0 && c.changes[0]?.key ? c.changes[0].key : "None"
            }}
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="beforeData">
        <mat-header-cell *matHeaderCellDef> Before Data </mat-header-cell>
        <mat-cell *matCellDef="let c">
          <button *ngIf="c.beforeData !== null && c.beforeData !== undefined" style="width: 600px" (click)="viewJson('Before Data', c.beforeData)">
            Before Data
          </button>
          <span *ngIf="c.beforeData === null || c.beforeData === undefined" style="width: 600px">
            N/A
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="afterData">
        <mat-header-cell *matHeaderCellDef> After Data </mat-header-cell>
        <mat-cell *matCellDef="let c">
          <button *ngIf="c.afterData !== null && c.afterData !== undefined" style="width: 600px" (click)="viewJson('After Data', c.afterData)">
            After Data
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.createdBy}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let c">
          {{c.createdDate | date: 'MM/dd/yyyy'}} {{c.createdDate | date: 'shortTime'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastModifiedDate">
        <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
        <mat-cell *matCellDef="let c">
          {{c.lastModifiedDate | date: 'MM/dd/yyyy'}} <br> {{c.lastModifiedDate | date: 'shortTime'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastModifiedBy">
          <mat-header-cell *matHeaderCellDef> Last Modified By </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.lastModifiedBy}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="changes">
        <mat-header-cell *matHeaderCellDef>Changes Made</mat-header-cell>
        <mat-cell *matCellDef="let c" class="changes-column">

          <button
            *ngIf="c?.changes?.length > 0"
            (click)="moreInfoDialog(c)"
            mat-stroked-button
          >
            <mat-icon>info</mat-icon>
            More Info
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <div>
      <mat-paginator #adPaginator [length]="totalRowCount" [pageSize]="defaultPageSize" 
        [pageSizeOptions]="pageSizeOptions" style="float: right"></mat-paginator>
    </div>
    </ng-container>

  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button
      mat-stroked-button
      (click)="close()">
      <mat-icon>close</mat-icon>
      Close
    </button>
  </mat-dialog-actions>