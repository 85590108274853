
<div matDialogTitle>
  Set Service Date Range for Data Entry
</div>

<mat-dialog-content>
  <form [formGroup]="formGroup" class="container">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Start Date (mm/dd/yyyy)</mat-label>
      <input type='date' matInput id="startDate" formControlName="startDate"/>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>End Date (mm/dd/yyyy)</mat-label>
      <input type='date' matInput id="endDate" formControlName="endDate"/>
    </mat-form-field>
  </form>
</mat-dialog-content>
  
<mat-dialog-actions>
  <div class="fennec-controls">
    <button mat-button mat-stroked-button (click)="onYes()" color="accent" [disabled]="!formGroup.valid">
      <mat-icon>checked</mat-icon> Save
    </button>
    <button mat-button mat-stroked-button (click)="onNo()">
      <mat-icon>cancel</mat-icon> Cancel
    </button>
  </div>
</mat-dialog-actions>
