import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { CreateCaseComponent } from '../case-create/case-create.component';
import { MICaseService } from '../mi-case.service';
import { CompanyService } from '@app/company/company.service';
import { Subscription } from "rxjs";
import {BaseComponent, FennecSnackbarService, Logger, PagedResponse} from "xf-common";

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent extends BaseComponent implements OnInit {

  @Input()
  miCaseId?: string;
  log: Logger = new Logger("CaseListComponent");

  dataSource = new MatTableDataSource<any>();
  page?: number = 0;
  displayColumns = ['id', 'type', 'currentXFWFStageName', 'statusTrackStatus', 'altExternalId', 'clientClaimId', 'name', 'clientName', 'lineOfBusiness', 'createdBy', 'createdDate'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [10, 20, 25, 50];

  // ASC / DESC
  idOrderBy: string = "DESC"

  queryParams = {
    miCaseId: this.miCaseId,
    altExternalId: "",
    clientClaimId: "",
    miCaseName: "",
    statusTrackStatus: "",
    createdBy: "",
    primaryClientName: "",
    idAscDesc: this.idOrderBy
  };

  miCaseMainStatusTrackStatuses: any[] = [];

  // UserProfile PRIMARY_CLIENT company list. Used to indicate to the user whether or not they have any
  // PRIMARY_CLIENT(s) associated with their user id. If the user has none, we display a clear message
  // to the user indicating this so they are not confused as to why they may not see any cases listed.
  userPrimaryClientList: any [] = [];


  constructor(
    private caseService: MICaseService,
    private companyService: CompanyService,
    protected snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private router: Router,
  ) {
    super();
    this.isLoading = true;
  }

  ngOnInit() {
    this.getUserPrimaryClientList();
  }

  createCase() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    const dialogRef = this.matDialog.open(CreateCaseComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getCases();
        this.router.navigateByUrl(`/mi-case/${result.id}`);
      }
    });
  }

  // Note: this is intended to be called only once, on component init. This is by design. It's used to
  // message the user if they do not have any PRIMARY_CLIENT(s) setup for them yet.
  getUserPrimaryClientList() {
    this.companyService.getActiveUserProfilePrimaryClientCompanies(0, 9999).subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage != null ? response.consolidatedErrorMessage : "");
        } else {
          this.userPrimaryClientList = response.data;
          if (this.userPrimaryClientList.length > 0) {
            this.getMICaseMainStatusTrackStatuses();
            this.getCases();
          } else {
            this.isLoading = false;
          }
        }
      }
    );
  }

  getCases() {
    this.queryParams.miCaseId = this.queryParams.miCaseId?.toString()?.trim() ?? "";
    this.queryParams.altExternalId = this.queryParams.altExternalId?.trim() ?? "";
    this.queryParams.clientClaimId = this.queryParams.clientClaimId?.trim() ?? "";
    this.queryParams.miCaseName = this.queryParams.miCaseName?.trim() ?? "";
    this.queryParams.createdBy = this.queryParams.createdBy?.trim() ?? "";
    this.queryParams.primaryClientName = this.queryParams.primaryClientName?.trim() ?? "";
    this.queryParams.idAscDesc = this.idOrderBy;

    const pageSize = this.paginator?.pageSize ?? this.defaultPageSize;
    const first = pageSize * (this.paginator?.pageIndex ?? 0);

    this.performXFRequest({
      requestDescription: "GET All Cases",
      requestFn: this.caseService.getAllCases,
      fnParams: [this.queryParams, first, pageSize],
      onResponse: (response: PagedResponse<any>) => {
        this.isLoading = false;
        this.dataSource.data = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  searchMICases() {
    this.paginator?.firstPage();
    this.getCases();
  }

  clearSearch() {
    this.queryParams.miCaseId = "";
    this.queryParams.altExternalId = "";
    this.queryParams.clientClaimId = "";
    this.queryParams.miCaseName = "";
    this.queryParams.statusTrackStatus = "";
    this.queryParams.createdBy = "";
    this.queryParams.primaryClientName = "";
    this.getCases();
  }

  getMICaseMainStatusTrackStatuses = () => {
    this.performXFRequest({
      requestDescription: "Get Status Track Statuses",
      requestFn: this.caseService.getMICaseMainStatusTrackStatuses,
      fnParams: [],
      onSuccess: (data) => {
        this.miCaseMainStatusTrackStatuses = data;
      },
      onError: (err) => {
        super.showWarningSnack("Unable to retrieve filter options for Statuses");
        this.log.error(err);
      }
    });
  }

  toggleIdOrderBy = () => {
    if(this.idOrderBy === "ASC") {
      this.idOrderBy = "DESC";
    }else if(this.idOrderBy === "DESC") {
      this.idOrderBy = "ASC"
    }
    this.getCases();
  }
}
