import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';


import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { StatusTrackService } from "../../status-track/status-track.service";
import { DateUtil } from '../../util/date-util';
import { UB04Edit1 } from '../../model/net/ub04-edit';
import { Logger } from '../../util/logger';
import { CodeValidationService } from "../../code/code-validation.service";
import { DRGCodePacket } from "../../model/net/drg-code-packet";



@Component({
  selector: 'app-ub04-edit-dialog1',
  templateUrl: './ub04-edit-dialog1.component.html',
  styleUrls: ['./ub04-edit-dialog1.component.css']
})
export class UB04EditDialog1Component extends UB04StatusTrackBaseDialogComponent implements OnInit  {
  override log = new Logger("UB04EditDialog1Component");

  formGroup!: FormGroup;
  typeList: String[] = [];
  ub04ReviewTypes: any [] = [];
  ub04VisitTypes: any[] = [];
  //statusTrackComplete: boolean = false;

  drgCodeFormControl: FormControl;
  drgCodeOptions: DRGCodePacket[] = [];

  constructor(
    public dialogRef: MatDialogRef<UB04EditDialog1Component>,
    override snack: FennecSnackbarService,
    private statusTrackService: StatusTrackService,
    private ub04Service: Ub04Service,
    private codeValidationService: CodeValidationService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // Provide an instance StatusTrackService to the Base component because Angular won't inject
    // this. Also provide additional params (ub04id, element) so the Base component has enough
    // information to do it's work (like make endpoint calls for status tracking lock / unlock).
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT1, statusTrackService);
    // Initialize a FormGroup
    this.formGroup = new FormGroup({
      federalTaxNumber: new FormControl(),
      patientControlNumber: new FormControl(),
      ub04BillType: new FormControl("", Validators.maxLength(3)),
      ub04ReviewType: new FormControl("CLAIM_REVIEW"),
      medicalRecordNumber: new FormControl(),
      visitType: new FormControl(),
      drgCode: new FormControl("", Validators.maxLength(6)),
      drgCodeTest: new FormControl("", Validators.maxLength(6))
    });
    this.drgCodeFormControl = this.formGroup.controls["drgCode"] as FormControl;
    this.drgCodeFormControl.valueChanges.subscribe((val) => {
      this.validateDRGCode(val);
    });
  }

  ngOnInit() {
    this.getUB04ReviewTypes();
    this.getUB04VisitTypes();
    this.fetchData();
  }

  fetchData() {
    this.ub04Service.getUb04Edit1(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.formGroup.patchValue(response.data);
      }
    });
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let ub04Dto = new UB04Edit1();
    Object.assign(ub04Dto, this.formGroup.value);
    ub04Dto.id = this.data.ub04Id;

    this.ub04Service.putUb04Edit1(ub04Dto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          id: response.data.id
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  getUB04ReviewTypes() {
    this.ub04Service.getAllUB04ReviewTypes().subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04ReviewTypes = response.data;
        if (this.ub04ReviewTypes.length > 0) {
          this.formGroup.controls['ub04ReviewType'].setValue(this.ub04ReviewTypes[0].ub04ReviewType);
        }
      }
    });
  }

  getUB04VisitTypes() {
    this.ub04Service.getAllUB04VisitTypes().subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04VisitTypes = response.data;
        if (this.ub04VisitTypes.length > 0) {
          this.formGroup.controls['visitType'].setValue(this.ub04VisitTypes[0]);
        }
      }
    });
  }

  private validateDRGCode(val: any) {
    const query = { code: val };
    this.performXFRequest({
      requestDescription: "Search DRG codes",
      requestFn: this.codeValidationService.searchDRGCodes,
      fnParams: [query, 0, 25],
      onSuccess: (data) => {
        this.drgCodeOptions = data;
      }
    });
  }
}
