import { Deserializable } from "../deserializable";


export class ItemizedUpdatePacket extends Deserializable<ItemizedUpdatePacket> {
  id?: number = -1;
  miCaseId?: number = -1;
  altId?: string;
  externalAltId?: string;
  ub04Id?: number;
}
