import { HttpResponse } from "@angular/common/http";
import { deflate, inflate } from "pako";
import { Logger } from "./logger";
import { SimpleObject } from "../model/simple-object";
import { FilePacket } from "../model/net/filePacket";

const logger = new Logger("FileUtil");

const MAX_SUGGESTED_FILE_SIZE = 150 * 1024 * 1024;

export enum FileType {
  Excel_XLS = "Excel_XLS",
  Excel_XLSX = "Excel_XLSX",
  PDF = "PDF",
  PNG = "PNG"
}

export interface FileProperties {
  defaultFileExtension: string;
  contentType: string;
}

export const ContentTypes: { [k in FileType]: FileProperties } = {
  Excel_XLS: {
    defaultFileExtension: "xls",
    contentType: "application/vnd.ms-excel"
  },
  Excel_XLSX: {
    defaultFileExtension: "xlsx",
    contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
  },
  PDF: {
    defaultFileExtension: "pdf",
    contentType: "application/pdf"
  },
  PNG: {
    defaultFileExtension: "png",
    contentType: "image/png"
  }
}

export class FileUtil {

  private constructor() {}

  public static convertFileEntryToFilePacket(fileEntry: FileSystemFileEntry, documentType?: number): Promise<FilePacket> {
    return new Promise((resolve, reject) => {
      fileEntry.file((file: File) => this.convertFileToFilePacket(file, documentType).then(resolve).catch(reject));
    });
  }

  public static convertFileToFilePacket(file: File, documentType?: number): Promise<FilePacket> {
    return new Promise(((resolve, reject) => {
      if (file.size >= MAX_SUGGESTED_FILE_SIZE) {
        reject(`File size should not exceed ${MAX_SUGGESTED_FILE_SIZE} MB: ${file.name}`);
      }
      const filePacket = new FilePacket();
      FileUtil.blobToBase64(file.slice()).then((data: string) => {
        filePacket.fileName = file.name;
        filePacket.data = data;
        filePacket.mimeType = file.type;
        if (documentType != null) {
          filePacket.config["type"] = documentType;
        }
        resolve(filePacket);
      }, reject);
    }))
  }

  public static downloadFileInBrowser(
    file: FilePacket,
    downloadFileAnchor: HTMLAnchorElement,
    fileType?: FileType,
    defaultFileName: string = "" + new Date()
  ): void {
    if (file == null || downloadFileAnchor == null) {
      return;
    }
    if (file.data == null) {
      logger.warn("Attempted to download file with null contents: " + file.fileName);
      return;
    }
    const props: SimpleObject = fileType ? ContentTypes[fileType] : {};
    const extension = file.fileType || props["defaultFileExtension"] || "bin";
    const fileName = file.fileName || `${defaultFileName}.${extension}`;
    const blob = FileUtil.base64toBlob(file.data, file.mimeType || props["contentType"]);

    const fileUrl = window.URL.createObjectURL(blob);
    downloadFileAnchor.href = fileUrl;
    downloadFileAnchor.download = fileName;
    downloadFileAnchor.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(fileUrl);
    }, 5000);
  }

  /**
   * For use with PDF Viewer
   */
  public static downloadBlob(file: FilePacket, fileType?: FileType): Blob | null {
    if (file == null) {
      return null;
    }
    if (file.data == null) {
      logger.warn("Attempted to download file with null contents: " + file.fileName);
      return null;
    }

    const props: SimpleObject = fileType ? ContentTypes[fileType] : {};
    const blob = FileUtil.base64toBlob(file.data, file.mimeType || props["contentType"]);
    return blob;
  }

  /**
   * Only for use with standard HttpResponse download (not FilePacket)
   * @param response
   */
  public static getFileName(response: HttpResponse<Blob>): string | undefined {
    let filename: string | undefined;
    try {
      const disposition = response.headers.get("content-disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const r = /(?:filename=")(.+)(?:")/;
        const result = r.exec(disposition);
        if (result != null) {
          filename = result[1];
        }
      }
    } catch (e) {
      filename = undefined;
    }
    return filename;
  }

  public static base64toBlob(base64Data?: string, contentType?: string): Blob {
    if (base64Data == null) {
      return new Blob();
    }
    const parts = base64Data.split(';base64,');
    if (contentType == null) {
      contentType = parts[0].split(':')[1];
    }
    const decodedData = window.atob(parts[0]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  // Justin TODO debug me
  public static base64toBlobNA(base64Data?: string, contentType?: string): Blob {
    if (base64Data == null) {
      return new Blob();
    }
    contentType = contentType ?? "";
    const sliceSize = 1024;
    const byteCharacters = window.atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays: Uint8Array[] = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex++) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const flattenedByteArray = new Uint8Array(slicesCount * sliceSize);
    for (
      let arrayNumber = 0, bufferIndex = 0, currentArr = byteArrays[arrayNumber];
      arrayNumber < byteArrays.length;
      arrayNumber++, bufferIndex += currentArr.length
    ) {
      flattenedByteArray.set(currentArr, bufferIndex);
    }
    const decompressed = inflate(flattenedByteArray);
    return contentType !== ""
      ? new Blob([decompressed], { type: contentType })
      : new Blob([decompressed]);
    // const byteArray = new Uint8Array(buffer, 0, buffer.length);
    // return new Blob([new Uint8Array(buffer, 0, buffer.length)])
  }

  public static blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // yank the data:mime/type;base64, prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  // Justin TODO debug me
  public static blobToBase64NA(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const readTimeout = setTimeout(() => {
        reject("File read timeout");
      }, 60000);

      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);

      reader.onloadend = () => {
        if (reader.result == null) {
          reject("Failed to read file");
          return; // for typescript compiler to know reader.result is not null below
        }
        clearTimeout(readTimeout);
        const compressedData = deflate(reader.result);

        // 2022-08-16 FMG - String.fromCharCode produces a maximum length exceeded recursion error.
        // const binaryString = String.fromCharCode(...compressedData);
        // Replacement code:
        let binaryString = '';
        let bytes = new Uint8Array(compressedData);
        let len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
              binaryString += String.fromCharCode( bytes[ i ] );
        }
        // End

        // const decoder = new TextDecoder();
        // const binaryString = decoder.decode(compressedData);
        const base64 = window.btoa(binaryString);
        resolve(base64);
        // if (reader.result instanceof ArrayBuffer) {
        //   resolve(decoder.decode(reader.result));
        // } else {
        //   clearTimeout(readTimeout);
        //   resolve(reader.result);
        // }
      };
    });
  }
}
