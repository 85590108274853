import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakUserInfo } from './keycloak/keycloak-user-info';
import { UserProfileService } from './user-profile/user-profile.service';
import {FennecSnackbarService} from "xf-common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web';

  deviceIsMobile = false;
  username: string = "";
  userIsAdmin = false;
  userIsDataEntry = false;
  userCanSeeDashboards = false;
  userCanSeeCenteneDashboards = false;
  loaded: boolean = false;
  navigationOpen = false;

  constructor(
    private keycloakService: KeycloakService,
    public breakpointObserver: BreakpointObserver,
    private snackBar: FennecSnackbarService,
    private userProfileService: UserProfileService
  ) {

  }

  ngOnInit(): void {
    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.userIsDataEntry = userRoles.includes("DATA_ENTRY");
    this.userCanSeeDashboards = this.userIsAdmin || this.keycloakService.isUserInRole("DASHBOARD_READ");
    this.userCanSeeCenteneDashboards = this.userIsAdmin || this.keycloakService.isUserInRole("DASHBOARD_CENTENE_READ");
    this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .subscribe((state: BreakpointState) => {
        this.deviceIsMobile = state.matches;
      });

    this.keycloakService.getKeycloakInstance()?.loadUserInfo().then((result) => {
      let type: KeycloakUserInfo = <KeycloakUserInfo>result;
      this.username = type.given_name;
      this.loaded = true;

      // Ping up to the Fennec server to get the UserProfile. We don't do anything with the data
      // returned here. We need to do this for new users added into Keycloak and not yet
      // added into the Fennec db. If the UserProfile on this call is not found, the back
      // end will automatically add the UserProfile record for the user. This is simple
      // housekeeping that is required for new users.
      // TODO: maybe make an EP that just does the new user add and does not return user
      // profile information.
      this.getUserProfile();

    });

  }

  logout() {
    this.keycloakService.logout();
  }

  // Make sure this call stays here, even though it's not doing anything with the response. This
  // will create an initial user profile record for the user if it does not already exists.
  getUserProfile() {
    this.userProfileService.getUserProfile().subscribe((response) => {
      if (response.hasErrors) {
        this.snackBar.showErrorSnack(response.consolidatedErrorMessage);
      }
    });
  }

  toggleSideNav() {
    this.navigationOpen = !this.navigationOpen;
  }

}
