import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { BaseResponse } from "../model/base-response";
import { ProviderPacket } from "../model/net/provider-packet";
import { PagedResponse } from "../model/paged-response";
import { EnvironmentService } from "../environment.service";

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private providerUrl = this.environment.baseApiUrl + "provider/";
  private taxonomyUrl = this.environment.baseApiUrl + "taxonomy-code/"

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
  }

  getProviders(): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.providerUrl + "list");
  }

  getAllProviders = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.providerUrl + "provider-list?first=" + first + "&max=" + max, queryParams);
  }

  getProvider = (id: number): Observable<any> => {
    return this.httpClient.get<any>(this.providerUrl + id);
  }

  getProviderTypes = (): Observable<any> => {
    return this.httpClient.get<any>(this.providerUrl + "provider-types");
  }

  createProvider = (payload: ProviderPacket): Observable<BaseResponse> => {
    return this.httpClient.post<BaseResponse>(this.providerUrl + "create", payload);
  }

  updateProvider = (payload: ProviderPacket): Observable<any> => {
    return this.httpClient.put<any>(this.providerUrl + "update", payload);
  }

  getTaxonomyCodeByCode = (taxonomyCode: string): Observable<any> => {
    return this.httpClient.get<any>(this.taxonomyUrl + "bycode/" + taxonomyCode);
  }

  getProviderNameSearch(name?: string, firstName?: string, lastName?: string, npiNumber?:string, first?: number, max?: number): Observable<any> {
    let urlFragment = "?first=" + first + "&max=" + max;
    if (name !== null && name !== undefined) {
      urlFragment += "&name=" + name;
    }
    if (firstName !== null && firstName !== undefined) {
      urlFragment += "&firstName=" + firstName;
    }
    if (lastName !== null && lastName !== undefined) {
      urlFragment += "&lastName=" + lastName;
    }
    if (npiNumber !== null && npiNumber !== undefined) {
      urlFragment += "&npiNumber=" + npiNumber;
    }
    urlFragment = urlFragment;
    return this.httpClient.get<any>(this.environment.baseApiUrl + "provider/search"
      + urlFragment);
      //+ "?first=0&max=9999&name=mile");

  }

  searchNPIRegistry = (searchParams: {}): Observable<any>  => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}provider/nppes`, searchParams);
  }

  deleteProvider = (id?: number): Observable<any> => {
    return this.httpClient.delete<any>(`${this.environment.baseApiUrl}provider/${id}`);
  }

  getAddressesForProvider = (providerId: number): Observable<any> => {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}provider/address/${providerId}`);
  }

  addAddressForProvider = (providerAddressPacket: any): Observable<any> => {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}provider/address`, providerAddressPacket);
  }

  deleteProviderAddress = (providerAddressId: number): Observable<any> => {
    return this.httpClient.delete<any>(`${this.environment.baseApiUrl}provider/address/${providerAddressId}`);
  }

  getProviderAddressType = (): Observable<any> => {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}provider/address-types`);
  }
}
