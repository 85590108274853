import { Inject, Injectable } from "@angular/core";
import { BaseService } from "../base.service";

import { Logger } from "../../util/logger";
import { Crud } from "../../util/common-handlers";
import { XFRequest, XFRequestHandlers } from "../../model/safe-request";
import { HttpClient } from "@angular/common/http";

@Injectable()
export abstract class BaseCrudService<T> extends BaseService {

  protected abstract _crudFunctions: Crud.CrudServiceDef<T>;
  protected get crudFunctions() {
    return this._crudFunctions;
  }

  protected set crudFunctions(value) {
    this._crudFunctions = value;
  }

  abstract _list: Crud.ListFunction<T>;
  abstract _getById: Crud.GetByIdFunction<T>;
  abstract _create: Crud.CreateFunction<T>;
  abstract _update: Crud.UpdateFunction<T>;
  abstract _deactivate: Crud.DeleteFunction<T>;
  abstract _delete: Crud.DeleteFunction<T>;

  protected constructor(
    public httpClient: HttpClient,
    @Inject(String) public serviceName: string,
    @Inject(String) public entityName: string
  ) {
    super(new Logger(serviceName));
  }

  protected genericDevWarning: XFRequestHandlers = {
    onComplete: () => this.log.error(`Crud function has not been defined on ${this.serviceName}`)
  };

  private _listRequest?: XFRequest<Crud.ListFunction<T>>;
  public get listRequest(): XFRequest<Crud.ListFunction<T>> {
    if (this._listRequest == null || this._listRequest.requestFn == null) {
      this._listRequest = new XFRequest(this.crudFunctions._list, `Get ${this.entityName} list`);
    }
    return this._listRequest;
  };

  private _getByIdRequest?: XFRequest<Crud.GetByIdFunction<T>>;
  public get getByIdRequest(): XFRequest<Crud.GetByIdFunction<T>> {
    if (this._getByIdRequest == null || this._getByIdRequest.requestFn == null) {
      this._getByIdRequest = new XFRequest(this.crudFunctions?._getById, `Get ${this.entityName} by ID`);
    }
    return this._getByIdRequest;
  };

  private _createRequest?: XFRequest<Crud.CreateFunction<T>>;
  public get createRequest(): XFRequest<Crud.CreateFunction<T>> {
    if (this._createRequest == null || this._createRequest.requestFn == null) {
      this._createRequest = new XFRequest(this.crudFunctions?._create, `Create ${this.entityName}`);
    }
    return this._createRequest;
  };

  private _updateRequest?: XFRequest<Crud.UpdateFunction<T>>;
  public get updateRequest(): XFRequest<Crud.UpdateFunction<T>> {
    if (this._updateRequest == null || this._updateRequest.requestFn == null) {
      this._updateRequest = new XFRequest(this.crudFunctions?._update, `Update ${this.entityName}`);
    }
    return this._updateRequest;
  };

  private _deactivateRequest?: XFRequest<Crud.DeactivateFunction<T>>;
  public get deactivateRequest(): XFRequest<Crud.DeactivateFunction<T>> {
    if (this._deactivateRequest == null || this._deactivateRequest.requestFn == null) {
      this._deactivateRequest = new XFRequest(this.crudFunctions?._deactivate, `Deactivate ${this.entityName}(s)`);
    }
    return this._deactivateRequest;
  };

  private _deleteRequest?: XFRequest<Crud.DeleteFunction<T>>;
  public get deleteRequest(): XFRequest<Crud.DeleteFunction<T>> {
    if (this._deleteRequest == null || this._deleteRequest.requestFn == null) {
      this._deleteRequest = new XFRequest(this.crudFunctions?._delete, `Delete ${this.entityName}(s)`);
    }
    return this._deleteRequest;
  }
}
