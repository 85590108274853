import * as luxon from "luxon";
import { ValidatorFn, Validators } from "@angular/forms";
import * as stream from "node:stream";
import {input} from "@angular/core";

export class DateUtil {

  /*
  Note: luxon can be tested via REPL by opening a terminal session in fennec/ui
  Run the following commands:

  > node
  > luxon = await import("./node_modules/luxon/src/luxon.js")

  Then play around!
  > luxon.DateTime.fromFormat("12/31/1999", "MM/dd/yyyy", { zone: "America/Denver" })
   */

  static readonly INPUT_DATE_FORMAT = "MM/dd/yyyy";
  static readonly DENVER_ZONE_ID = "America/Denver";
  static readonly YEAR_CUTOFF = 30; // if greater than 30, it will assume >= 1930; less than 30, assumes <= 2029
  static readonly STD_DATE_VALIDATOR_PATTERN = '^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$';

  private constructor() {};

  /**
   * For use on display only.  All dates should be entered and saved as YYYY-MM-DD.
   * @Input YYYY-MM-DD formatted Date String
   * @Output DD/MM/YY formatted Date string or empty string on error.
   */
  public static getDisplayDate(dateString: string): string {
    if(dateString == null || dateString.length == 0) {
      return "";
    }
    const dateArr = dateString.split("-");
    if(dateArr.length != 3) {
      return "";
    }

    const year = dateArr[0];
    const month = dateArr[1];
    const day = dateArr[2];
    const yearSegment = year[2] + year[3];

    return `${month}/${day}/${yearSegment}`
  }

  /**
   * For use on display only.  All dates should be entered and saved as YYYY-MM-DD.
   * @Input YYYY-MM-DD formatted Date String
   * @Output DD/MM/YYYY formatted Date string or empty string on error.
   */
  public static getDisplayDateYYYY(dateString: string): string {
    if(dateString == null || dateString.length == 0) {
      return "";
    }
    const dateArr = dateString.split("-");
    if(dateArr.length != 3) {
      return "";
    }

    const year = dateArr[0];
    const month = dateArr[1];
    const day = dateArr[2];

    return `${month}/${day}/${year}`
  }

  public static dateObjectToFennecDBString(dateObject:Date): string {
    if (dateObject == null) {
      return null;
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
  }

  public static dbDateStringToDateObject(dbString:string):Date | null {
    const dbStringSplit = dbString.split("-");
    if(dbStringSplit.length < 3) {
      return null;
    }

    let year = parseInt(dbStringSplit[0]);
    if (year >= 100 && year < 1000) {
      year = parseInt(dbStringSplit[0] + "0");
    }
    if (year < 100) { // dealing with partial date
      if (year < this.YEAR_CUTOFF) {
        year += 2000;
      } else {
        year += 1900;
      }
    }
    let month = parseInt(dbStringSplit[1]);
    let day = parseInt(dbStringSplit[2]);

    month -= 1;

    // const date = new Date(dbString.replace("-", "/"));
    const date = new Date(year, month, day);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    // date.setFullYear(year);
    // date.setMonth(month);
    // date.setDate(day);

    return date;
  }

  /**
   * Input Date String MM/DD/YYYY or MM-DD-YYYY to YYYY-MM-DD
  */
  public static inputDateStringToFennecDBDateString(inputDateString?:string):string | null {
    if (!inputDateString) {
      return null;
    }

    let inputDateSplit = inputDateString.split("-");
    if(inputDateSplit.length < 3) {
      inputDateSplit = inputDateString.split("/");
    }

    if(inputDateSplit.length < 3) {
      return inputDateString
    }
    const month = parseInt(inputDateSplit[0]);
    const day = parseInt(inputDateSplit[1]);
    const year = inputDateSplit[2];

    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
  }

  /**
   * Input Date String YYYY-MM-DD to MM/DD/YYYY
  */
  public static fennecDBStringToInputDateString(dateString:string):string {
    if (dateString === null || dateString === undefined) {
      return "";
    }
    let inputDateSplit = dateString.split("-");

    const year = inputDateSplit[0];
    const month = inputDateSplit[1];
    const day = inputDateSplit[2];

    return `${month}/${day}/${year}`
  }

  public static getDenverJSDateNow(): Date {
    return luxon.DateTime.now().setZone(this.DENVER_ZONE_ID).toJSDate();
  }

  public static getDenverJSDateToday(): Date {
    return luxon.DateTime.now().setZone(this.DENVER_ZONE_ID).startOf('day').toJSDate();
  }

  public static getDenverDateStringNow(): string | null {
    return luxon.DateTime.now().setZone(this.DENVER_ZONE_ID).toISODate();
  }

  public static inputDateStringToJSDate(input: string): Date | null {
     if (input == null) return null;
     const dateTime = luxon.DateTime.fromFormat(input, this.INPUT_DATE_FORMAT, { zone: this.DENVER_ZONE_ID });
     if (!dateTime.isValid) {
       return null;
     }
     return dateTime.toJSDate();
  }

  public static padStringLeft(str: string, length: number) {
    if (str?.length === length) { return str; }
    return ("0".repeat(length) + str).slice(-length);
  }

  public static dateStringValidator(): ValidatorFn {
    return Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$');
  }

  public static parseDateStringToDateObject(value: string): Date {
    if (value == null || value == "") {
      return null;
    } else if (value.indexOf("/") == 2) { // date is MM/DD/YYYY
      return this.inputDateStringToJSDate(value);
    } else if (value.indexOf("-") == 4) {
      return this.dbDateStringToDateObject(value);
    }
    console.error("Attempted to parse date input in invalid format: " + value);
    return null;
  }
}
