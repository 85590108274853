<pdf-shy-button
  primaryToolbarId="presentationMode"
  [cssClass]="showPresentationModeButton | responsiveCSSClass : 'hiddenLargeView'"
  title="Switch to Presentation Mode"
  l10nId="pdfjs-presentation-mode-button"
  l10nLabel="pdfjs-presentation-mode-button-label"
  [order]="100"
  image="<svg class='pdf-margin-top--2px' width='27px' height='27px' viewBox='0 0 24 24'><path fill='currentColor' d='M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z' /></svg>"
>
</pdf-shy-button>
