import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ItemizedService } from "../itemized.service";
import { ItemizedUpdatePacket } from "../../model/net/itemized-update-packet";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { AttachmentService } from '../../attachment/attachment.service';


@Component({
  selector: 'app-itemized-create',
  templateUrl: './itemized-create.component.html',
  styleUrls: ['./itemized-create.component.scss']
})
export class ItemizedCreateComponent implements OnInit {

  formGroup!: FormGroup;
  typeList: String[] = [];

  @Input()
  miCaseId: string = "-1";

  constructor(
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<ItemizedCreateComponent>,
    private snack: FennecSnackbarService,
    private itemizedService: ItemizedService,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = new FormGroup({
      externalAltId: new FormControl("")
    });
    if (data.ub04Id === null || data.ub04Id === undefined) {
      data.ub04Id = -1;
    }
  }

  ngOnInit() {
  }

  onSubmit(miCaseId: any) {
     //Mark all form fields as touched to trigger validation errors
     this.formGroup.markAllAsTouched();
     // Check if miCaseId is present
     if (!miCaseId) {
        return;
     }
     //Create a new ItemizedUpdatePacket object
     let itemizedDto = new ItemizedUpdatePacket();
     itemizedDto.miCaseId = this.data.miCaseId;
     itemizedDto.ub04Id = this.data.ub04Id;
     itemizedDto.externalAltId = this.formGroup.controls['externalAltId'].value;
    if (this.data.origin === "MANUAL") {
      this.itemizedService.addItemized(itemizedDto).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          const returnObj = {
            confirm: true,
            id: response.data.id
          }
          this.dialogRef.close(returnObj);
          this.snack.showSuccessSnack(`Successfully Created!`);
        }
      });
    } else {
      this.attachmentService.convertToItemized(this.data.attachmentId, itemizedDto).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          const returnObj = {
            confirm: true,
            id: response.data.id
          }
          this.dialogRef.close(returnObj);
          this.snack.showWorkerSubmitSnack("Process/Create Itemized initiated!");
        }
      });
    }

  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
