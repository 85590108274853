import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-alt-text-dialog',
  templateUrl: './pdf-alt-text-dialog.component.html',
  styleUrls: ['./pdf-alt-text-dialog.component.css'],
})
export class PdfAltTextDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
