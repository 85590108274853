import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ItemizedRevisionService } from '@app/itemized/itemized-revision/itemized-revision.service';
import {BaseComponent, FennecSnackbarService, Logger} from "xf-common";

@Component({
  selector: 'app-copy-clone-line-item-dialog',
  templateUrl: './copy-clone-line-item-dialog.component.html',
  styleUrls: ['./copy-clone-line-item-dialog.component.scss']
})
export class CopyCloneLineItemDialogComponent extends BaseComponent implements OnInit {
  protected log: Logger = new Logger("CopyCloneLineItemDialogComponent");

  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CopyCloneLineItemDialogComponent>,
    private itemizedRevisionService: ItemizedRevisionService,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.formGroup = new FormGroup({
      cloneCount: new FormControl(1),
      serviceDateSpread: new FormControl(false)
    });
    this.formGroup.controls["serviceDateSpread"].valueChanges.subscribe(selectedValue => {
      if (this.formGroup.contains("serviceDateSpread")) {
        // We set the cloneCount to one (1) less than the number of calculated service dates. We do this
        // because on a clone operation, we will not clone the line item that matches the current
        // service date the user is cloning.
        this.formGroup.controls["cloneCount"].setValue(this.data.serviceDateCount - 1);
      }
    })
   }

  ngOnInit(): void {
  }

  onCloneCount() {
    let cloneOption = "CLONE_COUNT";
    if (this.formGroup.controls['serviceDateSpread'].value) {
      cloneOption = "SERVICE_DATE_SPREAD";
    }
    const returnObj = {
      confirm: true,
      cloneOption: cloneOption,
      cloneCount: this.formGroup.controls['cloneCount'].value
    }
    this.dialogRef.close(returnObj);
  }

  onNo() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
