<div [formGroup]="formGroup" class="content-wrapper alt-theme">
  <ng-container formArrayName="lineItems">
      <ng-container *ngFor="let rcRow of lineItems.controls; let i = index">
        <div [formGroup]="getLineItemFormGroup(i)" class="ili-form-complete-row">

          <div>
            <mat-form-field appearance="outline" class="ili-input-1" color="secondary">
              <mat-label>Service Date (MM/DD/YY)</mat-label>
              <input type='date' #serviceDateInput matInput formControlName="serviceDate" placeholder="YYYY-MM-DD">
            </mat-form-field>          
            <mat-form-field appearance="outline" class="ili-rev-code-input">
              <mat-label>Rev Code</mat-label>
              <input matInput formControlName="revenueCode" placeholder="Rev Code">
            </mat-form-field>
            <mat-form-field appearance="outline" class="ili-cpt-code-input">
              <mat-label>CPT Code</mat-label>
              <input matInput formControlName="cptCode" placeholder="CPT Code">
            </mat-form-field>            
            <mat-form-field appearance="outline" class="ili-units">
              <mat-label>Units</mat-label>
              <input type='number' matInput formControlName="units" placeholder="Units">
            </mat-form-field>
            <mat-form-field appearance="outline" class="ili-billed-amt">
              <mat-label>Billed Amt</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <input type='number' matInput formControlName="billedAmount" placeholder="Billed Amount">
            </mat-form-field>  
            <mat-form-field appearance="outline" class="ili-description">
              <mat-label>Description</mat-label>
              <input matInput formControlName="description" placeholder="Description">
            </mat-form-field>  
            <mat-form-field appearance="fill" id="revenueCodeDescription" class="ili-revenue-code-description">
              <mat-label>Revenue Code Description</mat-label>
              <input matInput formControlName="revenueCodeDescription" placeholder="Revenue Code Description">
            </mat-form-field>                                             
          </div>
          <div>
            <mat-form-field appearance="outline" class="ili-billed-amt">
              <mat-label>Pre Ex Adj Amt</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <input type='number' matInput formControlName="preExAdjustedAmount" placeholder="Pre Ex Adj Amount">
            </mat-form-field>
            <mat-form-field appearance="outline" class="ili-pre-ex-explanation">
              <mat-label>Pre Ex Explanation</mat-label>
              <input matInput formControlName="preExExplanation" placeholder="Pre Ex Explanation">
            </mat-form-field> 
            <mat-form-field appearance="outline" class="ili-pre-ex-audit-comments">
              <mat-label>Pre Ex Audit Comments</mat-label>
              <input matInput formControlName="preExAuditComments" placeholder="Pre Ex Audit Comments">
            </mat-form-field>            
            <mat-form-field appearance="outline" class="ili-units">
              <mat-label>Bill Page No</mat-label>
              <input type='number' matInput formControlName="billPageNo" placeholder="Bill Page No">
            </mat-form-field>
            <mat-form-field appearance="outline" class="ili-units">
              <mat-label>Bill Line No</mat-label>
              <input type='number' matInput formControlName="billLineNo" placeholder="Bill Line No">
            </mat-form-field>            
            <button 
              *ngIf="!getDeleteRowByIdx(i)"
              style="margin-left: 5px"
              mat-flat-button 
              matTooltip="Delete"
              class="ub04-icon-btn"
              (click)="onDelete(i)">
              <mat-icon>delete</mat-icon>
            </button> 
          </div>
        </div>
      </ng-container>
  </ng-container>
  <div class="ili-editor-action-buttons">
    <button 
      style="margin-right:10px"
      mat-flat-button 
      matTooltip="Save"
      [disabled]="!formGroup.valid"
      color="accent"
      (click)="saveLineItems()">
      <mat-icon>save</mat-icon>Save
    </button>
    <button 
      mat-flat-button 
      matTooltip="Cancel"
      color="primary"
      (click)="cancelEdit()">
      <mat-icon>cancel</mat-icon>Cancel
    </button>    
  </div>      
</div>

