import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { Ub04AddProviderComponent } from './ub04-add-provider/ub04-add-provider.component';
import { StatusTrackService } from "../../status-track/status-track.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { SingleChoiceDialogComponent } from "../../dialog/single-choice-dialog/single-choice-dialog.component";
import { NameSearchPromptComponent } from "../../search/name-search-prompt/name-search-prompt.component";
import { Logger } from '../../util/logger';


@Component({
  selector: 'app-ub04-edit-provider-dialog',
  templateUrl: './ub04-edit-provider-dialog.component.html',
  styleUrls: ['./ub04-edit-provider-dialog.component.scss']
})
export class Ub04EditProviderDialogComponent extends UB04StatusTrackBaseDialogComponent
  implements OnInit, AfterViewInit {
  override log = new Logger("Ub04EditProviderDialogComponent");

  formGroup!: FormGroup;
  // Formatted structured data for data entry experience.
  existingUB04Providers: any [] = [];
  existingUB04ProviderColumns: any [] = ["name", "npiNumber", "ub04ProviderType", "actions"];
  // Server data in original format from the server.
  ub04Providers: any [] = [];

  // UI Indicator Flags to help show/hide buttons
  existingTypePrimary: boolean = false;
  existingTypeAttending: boolean = false;
  existingTypeOperating: boolean = false;

  primaryProvider: any;
  attendingProvider: any;
  operatingProvider: any;

  otherProviderA?: any;
  otherProviderB?: any;

  // modes - "EXISTING", "SEARCH_{primary/attending/operating}"
  displayMode: string = "EXISTING";

  @ViewChild('providerNameSearch')
  providerNameSearchComponent!: NameSearchPromptComponent;

  @ViewChild('providerAdd')
  providerAdd!: Ub04AddProviderComponent;

  constructor(
    public dialogRef: MatDialogRef<Ub04EditProviderDialogComponent>,
    public matDialog: MatDialog,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // Provide an instance StatusTrackService to the Base component because Angular won't inject
    // this. Also provide additional params (ub04id, element) so the Base component has enough
    // information to do it's work (like make endpoint calls for status tracking lock / unlock).
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_PROVIDER, statusTrackService);
    this.formGroup = new FormGroup({
    });
   }

  ngOnInit(): void {
    this.fetchData();
  }

  ngAfterViewInit(): void {
    if (this.providerNameSearchComponent !== null && this.providerNameSearchComponent !== undefined) {
      this.providerNameSearchComponent?.ub04ProviderSelected?.subscribe((msgObj) => {
        if (msgObj !== null && msgObj !== undefined) {
          this.createUB04Provider(msgObj);
        }
      });
    }

    if(this.providerAdd !== null && this.providerAdd !== undefined){
      this.providerAdd?.providerSaveComplete?.subscribe((packet) => {
        this.createUB04Provider({
          id: packet.id,
          ub04ProviderType: this.getSelectedType()
        });

        this.setDisplayMode('EXISTING');
      })
    }
  }

  onSubmit() {
    this.providerAdd.onSubmit();
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  fetchData() {
    this.ub04Service.getUb04Providers(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        // console.log(response);
        this.ub04Providers = response.data;
        this.populateExistingUB04Providers();
      }
    });
  }

  populateExistingUB04Providers() {
    this.existingUB04Providers = [];

    // PRIMARY
    let ub04Provider = this.getUB04ProviderForUB04ProviderType("PRIMARY");
    this.existingUB04Providers.push(this.formatUB04ProviderForExistingDisplay(ub04Provider, "PRIMARY"));
    this.existingTypePrimary = ub04Provider !== null;
    this.primaryProvider = ub04Provider;

    // ATTENDING
    ub04Provider = this.getUB04ProviderForUB04ProviderType("ATTENDING");
    this.existingUB04Providers.push(this.formatUB04ProviderForExistingDisplay(ub04Provider, "ATTENDING"));
    this.existingTypeAttending = ub04Provider !== null;
    this.attendingProvider = ub04Provider;

    // OPERATING
    ub04Provider = this.getUB04ProviderForUB04ProviderType("OPERATING");
    this.existingUB04Providers.push(this.formatUB04ProviderForExistingDisplay(ub04Provider, "OPERATING"));
    this.existingTypeOperating = ub04Provider !== null;
    this.operatingProvider = ub04Provider;

    // OTHER
    const otherProviders = this.getUB04ProviderTypeOther();
    if(otherProviders.length > 0) {
      this.otherProviderA = otherProviders[0];
      this.existingUB04Providers.push(this.formatUB04ProviderForExistingDisplay(this.otherProviderA, "OTHER"));
      if(otherProviders.length > 1) {
        this.otherProviderB = otherProviders[1];
        this.existingUB04Providers.push(this.formatUB04ProviderForExistingDisplay(this.otherProviderB, "OTHER"));
      }else{
        this.otherProviderB = null;
      }
    }else{
      this.otherProviderA = null;
    }
  }

  // There should only be one of these.
  getUB04ProviderForUB04ProviderType(ub04ProviderType: string):any {
    let idx = 0;
    for (idx = 0; idx < this.ub04Providers.length; idx++) {
      if (this.ub04Providers[idx].ub04ProviderType === ub04ProviderType) {
        return this.ub04Providers[idx];
      }
    }
    return null;
  }

  getUB04ProviderTypeOther(): any[] {
    const result: any[] = [];
    let idx = 0;
    for (idx = 0; idx < this.ub04Providers.length; idx++) {
      if (this.ub04Providers[idx].ub04ProviderType === "OTHER") {
        result.push(this.ub04Providers[idx]);
      }
    }
    return result;
  }

  formatUB04ProviderForExistingDisplay(ub04Provider: any, ub04ProviderType: string): any {
    let fmtObj = {
      id: -1,
      name: "Not Selected",
      npiNumber: "",
      ub04ProviderType: ub04ProviderType,
      style: {
        'background-color': 'lightgray'
      }
    }
    if (ub04Provider !== null) {
      fmtObj["id"] = ub04Provider.id;
      fmtObj["name"] = ub04Provider.name;
      fmtObj["npiNumber"] = ub04Provider.npiNumber;
      fmtObj["ub04ProviderType"] = ub04Provider.ub04ProviderType;
      fmtObj["style"] = {
        'background-color': 'lightgreen'
      };
    }
    return fmtObj;
  }

  createUB04Provider(paramObj: any) {
    if (paramObj.ub04ProviderType === "EXISTING") {
      // this only occurs in an edge-case (dirty state) of the application
      this.snack.showWarningSnack("There was an error processing this request. Please try again, and contact support if the issue persists.");
      return;
    }
    // Logic to not duplicate ub04 provider types.
    // console.log(paramObj);
    if (
      (paramObj.ub04ProviderType === "PRIMARY" && this.existingTypePrimary) ||
      (paramObj.ub04ProviderType === "OPERATING" && this.existingTypeOperating) ||
      (paramObj.ub04ProviderType === "ATTENDING" && this.existingTypeAttending)
    ) {
      this.snack.showInfoSnack("Provider type " + paramObj.ub04ProviderType + " already selected!");
      this.setDisplayMode("EXISTING");
      return;
    }

    let updDto;

    if(paramObj.id == -1) {
      updDto = {
        ub04Id: this.data.ub04Id,
        provider: paramObj.provider,
        ub04ProviderType: paramObj.ub04ProviderType,
      }

      this.ub04Service.postUb04ImportProvider(updDto).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack("Success!");
          this.fetchData();
        }
      });
    }else{
      updDto = {
        id: -1,
        ub04Id: this.data.ub04Id,
        providerId: paramObj.id,
        ub04ProviderType: paramObj.ub04ProviderType,
      }

      this.ub04Service.postUb04CreateProvider(updDto).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack("Success!");
          this.fetchData();
        }
      });
    }

    this.setDisplayMode("EXISTING");
  }

  onRemoveUB04Provider(id: number) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Remove existing UB04 Provider?"
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteUB04Provider(id);
      }
    });
  }

  deleteUB04Provider(id: number) {
    this.ub04Service.deleteUb04Provider(id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.fetchData();
      }
    });
  }


  getPrimaryProviderName() {
    return this.primaryProvider.name ? this.primaryProvider.name : this.primaryProvider.firstName + " " + this.primaryProvider.lastName;
  }

  getAttendingProviderName() {
    return this.attendingProvider.name ? this.attendingProvider.name : this.attendingProvider.firstName + " " + this.attendingProvider.lastName;
  }

  getOperatingProviderName() {
    return this.operatingProvider.name ? this.operatingProvider.name : this.operatingProvider.firstName + " " + this.operatingProvider.lastName;
  }

  getOtherProviderAName() {
    return this.otherProviderA.name ? this.otherProviderA.name : this.otherProviderA.firstName + " " + this.otherProviderA.lastName;
  }

  getOtherProviderBName() {
    return this.otherProviderB.name ? this.otherProviderB.name : this.otherProviderB.firstName + " " + this.otherProviderB.lastName;
  }

  isSearch() {
    return this.displayMode.startsWith("SEARCH");
  }

  setDisplayMode(mode: string) {
    this.displayMode = mode;
  }

  getSelectedType() {
    if(this.isSearch()){
      return this.displayMode.split("_")[1];
    }else if(this.displayMode.startsWith("ADD_PROVIDER")){
      return this.displayMode.split("_")[2];
    }
    return "EXISTING";
  }

  getFormattedAddressLine1(provider: any) {
    const address = provider.address;
    if(address == null || address == undefined) {
      return "";
    }
    let result = "";
    if(address.addressLine1) {
      result += address.addressLine1;
    }

    if(address.addressLine2) {
      result += " " + address.addressLine2;
    }

    if(address.addressLine3) {
      result += " " + address.addressLine3;
    }

    return result;
  }

  getFormmattedAddressLine2(provider: any) {
    const address = provider.address;
    if(address == null) {
      return "";
    }
    let result = "";
    if(address.city) {
      result += address.city;
    }

    if(address.stateCode) {
      result += " " + address.stateCode;
    }

    if(address.zipCode) {
      result += " " + address.zipCode;
    }

    return result;
  }
}

