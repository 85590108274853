<mat-dialog-content>
  <h1 mat-dialog-title>Create New UB04</h1>
  <div class="create-form alt-theme">
    <form [formGroup]="formGroup" class="container create-form">
      <mat-radio-group aria-label="Select a Visit Type" formControlName="ub04VisitType">
        <mat-radio-button value="INPATIENT">InPatient</mat-radio-button>
        <mat-radio-button value="OUTPATIENT" style="margin-left: 5px">OutPatient</mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="outline" class="ub04-review-type">
        <mat-label>UB04 Review Type</mat-label>
        <mat-select formControlName="ub04ReviewType">
          <mat-option *ngFor="let t of ub04ReviewTypes" [value]="t.ub04ReviewType">
            {{ t.ub04ReviewType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--
      <mat-form-field class="npi-number-field">
        <mat-label>NPI Number</mat-label>
        <input matInput placeholder="Enter NPI Number..." formControlName="npiNumber">
      </mat-form-field>   -->   
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Create
    </button>

    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>

