import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatGridListModule } from "@angular/material/grid-list";
import { RouterModule } from "@angular/router";
import { AttachmentComponent } from "./attachment.component";
import { AttachmentService } from "./attachment.service";
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import {CommonViewModule, MaterialModule} from "xf-common";

@NgModule({
  imports: [
    CommonViewModule,
    MatCardModule,
    MaterialModule,
    MatGridListModule,
    RouterModule,
    CommonModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [AttachmentComponent, PdfViewerComponent],
  exports: [AttachmentComponent],
  providers: [AttachmentService]
})
export class AttachmentModule {}
