<div *ngIf="selectedCompany != null" class="bubble-content alt-theme"[formGroup]="formGroup">
  <div class="header">
    <h3 class="header-title">
      <span *ngIf="existingUB04Payer === null || existingUB04Payer === undefined" style="color: green">** NEW ** </span>
      {{formattedPayerType()}} Payer - {{selectedCompany.name}} 
      <span *ngIf="selectedHealthCarePlan !== null"> - <span style="color: blue">{{selectedHealthCarePlan.name}}</span></span>
      <button
        mat-button 
        mat-stroked-button
        class="icon-btn"
        style="margin-left: 5px"
        matTooltip="Change company / hc plan"
        (click)="onCompanySearch()">
          <mat-icon>edit</mat-icon>
      </button>
    </h3>
    <button
      class="icon-btn"
      mat-button 
      mat-stroked-button
      matTooltip="Delete Primary Payer"
      (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="horizontal-group">
    <div class="vertical-group">
      <mat-checkbox [formControlName]="'releaseInfoCertification'">Release Info Certification</mat-checkbox>
      <mat-checkbox [formControlName]="'assignmentBenefitCertification'">Assignment Benefit Certification</mat-checkbox>
    </div>

    <div class="payments">
      <mat-form-field 
        appearance="outline"
        [style.width.px]=250
        >
        <mat-label>Prior Payments</mat-label>
        <input 
          matInput 
          formControlName="priorPayments"
        >
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>
    
      <mat-form-field
        appearance="outline"
        [style.width.px]=250>
        <mat-label>Estimated Amount Due</mat-label>
        <input 
          matInput 
          formControlName="estimatedAmountDue"
        >
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="horizontal-group">
    <mat-form-field 
        appearance="outline"
        [style.width.px]=350>
      <mat-label>Insured First Name</mat-label>
      <input 
        matInput
        formControlName="insuredFirstName"
      >
    </mat-form-field>
  
    <mat-form-field 
      appearance="outline"
      [style.width.px]=350>
      <mat-label>Insured Last Name</mat-label>
      <input 
        matInput
        formControlName="insuredLastName"
      >
    </mat-form-field>
  
    <!-- <mat-form-field appearance="outline">
      <mat-label>Patient Relationship Code</mat-label>
      <input
        matInput
        formControlName="patientRelationshipCode"
      >
    </mat-form-field> -->
    <!-- [control]="formGroup.controls['patientRelationshipCode']" -->
    <lib-xf-form-field
      [maxLength]=4
      formControlName="patientRelationshipCode"
      labelText="Patient Relationship Code"
      [warningDifference]=1
      appearance="outline"
    ></lib-xf-form-field>
  
    <mat-form-field appearance="outline">
      <mat-label>Patient Relationship Description</mat-label>
      <input
        matInput
        formControlName="patientRelationshipCodeDescription"
      >
    </mat-form-field>
  </div>

  <div class="horizontal-group">
    <!-- <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Insured's Unique ID</mat-label>
      <input 
        matInput
        formControlName="insuredUniqueIdentNo"
      >
    </mat-form-field> -->
    <!-- [control]="formGroup.controls['insuredUniqueIdentNo']" -->
    <lib-xf-form-field
      labelText="Insured's Unique ID"
      [maxLength]="maxLength.insuredUniqueIdentNo"
      formControlName="insuredUniqueIdentNo"
      [width]="300"
      appearance="outline"
    ></lib-xf-form-field>
  
    <!-- <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Insured Group Name</mat-label>
      <input 
        matInput
        formControlName="insuredGroupName"
      >
    </mat-form-field> -->

    <lib-xf-form-field
      labelText="Insured Group Name"
      [width]=300
      appearance="outline"
      formControlName="insuredGroupName"
      [maxLength]="maxLength.insuredGroupName"
    ></lib-xf-form-field>
  
    <!-- <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Insured Group Number</mat-label>
      <input 
        matInput
        formControlName="insuredGroupNo"
      >
    </mat-form-field> -->

    <lib-xf-form-field
      labelText="Insured Group No"
      [width]=300
      appearance="outline"
      formControlName="insuredGroupNo"
      [maxLength]="maxLength.insuredGroupNo"
    ></lib-xf-form-field>
  </div>

  <div class="horizontal-group">
    <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Treatment Authorization Code</mat-label>
      <input 
        matInput
        formControlName="treatmentAuthorizationCode"
      >
    </mat-form-field>
  
    <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Document Control Number</mat-label>
      <input 
        matInput
        formControlName="documentControlNo"
      >
    </mat-form-field>
  
    <mat-form-field 
      [style.width.px]=300  
      appearance="outline">
      <mat-label>Employer Name</mat-label>
      <input 
        matInput
        formControlName="employerName"
      >
    </mat-form-field>
  </div>
</div>