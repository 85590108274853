import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { MICaseXfwfService } from './mi-case-xfwf.service';
import {DateUtil, FennecSnackbarService, Logger} from "xf-common";

@Component({
  selector: 'web-mi-case-xfwf-task-list',
  templateUrl: './mi-case-xfwf-task-list.component.html',
  styleUrls: ['./mi-case-xfwf-task-list.component.scss']
})
export class MiCaseXfwfTaskListComponent implements OnInit {

  log: Logger = new Logger("MiCaseXfwfTaskListComponent");

  @Input()
  miCaseId = "-1";
  dataSource = new MatTableDataSource<any>();
  displayColumns = ['taskStatus', 'type', 'taskAssignedBy', 'taskAssignedTo', 'taskCreatedBy', 'dueDate'];
  userList: any [] = [];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    protected snack: FennecSnackbarService,
    private miCaseXfwfService: MICaseXfwfService,
    protected route: ActivatedRoute
  ) {
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngOnInit() {
    this.getTasksForCase();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getTasksForCase();
      })
    }
  }

  getTasksForCase() {
    if (this.miCaseId == "-1") return;
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.miCaseXfwfService.getCaseTasks(parseInt(this.miCaseId), first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  formatDate(dateString: any) {
    return DateUtil.getDisplayDate(dateString);
  }
}
