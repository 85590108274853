import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityDiaryComponent } from './activity-diary/activity-diary.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { CaseListComponent } from './mi-case/case-list/case-list.component';
import { PdfViewerComponent } from './mi-case/mi-case-attachment/pdf-viewer/pdf-viewer.component';
import { MiCaseComponent } from './mi-case/mi-case.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MiCaseOverviewComponent } from "@app/mi-case/mi-case-overview/mi-case-overview.component";
import { MiCaseItemizedComponent } from "@app/mi-case/mi-case-itemized/mi-case-itemized.component";
import { MiCaseXfwfTaskListComponent } from "@app/mi-case/mi-case-xfwf-task-list/mi-case-xfwf-task-list.component";
import { MiCaseUsersComponent } from "@app/mi-case/mi-case-users/mi-case-users.component";
import { AttachmentComponent } from "@app/mi-case/mi-case-attachment/attachment.component";
import { CommentListComponent } from "@app/comments/comment-list/comment-list.component";
import { MiCaseUb04Component } from 'xf-common';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/:context', component: DashboardComponent, canActivate: [AuthGuard] },
  {
    path: 'mi-case/:miCaseId', component: MiCaseComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: MiCaseOverviewComponent },
      { path: 'ub04', redirectTo: 'ub04/', pathMatch: 'full' },
      { path: 'ub04/:ub04Id', component: MiCaseUb04Component },
      { path: 'itemized', redirectTo: 'itemized/', pathMatch: 'full' },
      { path: 'itemized/:itemizedId', component: MiCaseItemizedComponent },
      { path: 'attachments', component: AttachmentComponent },
      { path: 'users', component: MiCaseUsersComponent },
      { path: 'task-list', component: MiCaseXfwfTaskListComponent },
      { path: 'comments', component: CommentListComponent }
    ]
  },
  { path: 'mi-case-list', component: CaseListComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'activity-diary', component: ActivityDiaryComponent, canActivate: [AuthGuard] },
  { path: 'data-entry', component: DataEntryComponent, canActivate: [AuthGuard] },
  { path: 'view-attachment/:id', component: PdfViewerComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
