import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ItemizedRevisionService } from '@app/itemized/itemized-revision/itemized-revision.service';
import {
  BaseComponent, DateUtil,
  FennecSnackbarService,
  ItemizedLineItemUpdatePacket,
  Logger,
  SingleChoiceDialogComponent
} from 'xf-common';
import { KeycloakService } from 'keycloak-angular';
import { CopyCloneLineItemDialogComponent } from '../copy-clone-line-item-dialog/copy-clone-line-item-dialog.component';
import { ItemizedLineItemBulkEditorComponent } from '../itemized-line-item-bulk-editor/itemized-line-item-bulk-editor.component';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-itemized-line-item',
  templateUrl: './itemized-line-item.component.html',
  styleUrls: ['./itemized-line-item.component.scss']
})
export class ItemizedLineItemComponent extends BaseComponent implements OnInit, AfterViewInit {
  protected log: Logger = new Logger("ItemizedLineItemComponent");

  @Input()
  itemizedRevisionId: string = "-1";

  screenMode: string = "DISPLAY";

  // Indicates whether or not this revision has been run through APE or not.
  apeProcessed: boolean = false;

  // Line Item Create parameters
  lineItemCreateParameters: FormGroup;

  // Line Item Query variable parameters
  lineItemQueryFormGroup: FormGroup;

  // Description filter FormGroup
  descriptionFilterFormGroup: FormGroup;

  // Number of line Items on the currently loaded Itemized Revision
  lineItemCount: number = 0;
  totalBilledAmount: number = 0;
  totalAdjustedAmount: number = 0;

  // Line Item Query pagination
  totalRowCount: number = 0;
  defaultPageSize: number = 100;
  defaultPageSizeOptions = [50, 100];

  // Unique Service Date Strings for Itemized Revision - gleaned from Line Item data
  serviceDateStrings: string [] = [];

  // Data Entry Service Date Range - manually entered by data entry operator and may or may not coincide to
  // the actual dates found in the actual line item data.
  serviceDateStart: string | null = null;
  serviceDateEnd: string | null = null;

  // Fires when the user requests to close the itemized line item component.
  itemizedLineItemComponentClose: Subject<any> = new Subject<any>();

  @ViewChild('topLevelWrapper') topLevelWrapper!: ElementRef;

  @ViewChild('itemizedLineItemBulkEditorComponent')
  itemizedLineItemBulkEditor!: ItemizedLineItemBulkEditorComponent;

  @ViewChild(MatPaginator)
  _paginator?: MatPaginator;

  get paginator(): MatPaginator {
    return this._paginator ?? {} as MatPaginator;
  }

  componentHeightPX: number = 500;
  itemizedLineItems: any = {
    lineItems: []
  };

  userIsDataEntry: boolean = false;
  userIsAdmin:boolean = false;

  constructor(
    private itemizedLineItemService: ItemizedLineItemService,
    private itemizedRevisionService: ItemizedRevisionService,
    protected snack: FennecSnackbarService,
    public matDialog: MatDialog,
    protected keycloakService: KeycloakService,
  ) {
    super();
    this.lineItemQueryFormGroup = this.createFormGroup();
    this.lineItemCreateParameters = this.createLineItemCreateParamsFormGroup();
    this.descriptionFilterFormGroup = this.createDescriptionFilterFormGroup();

    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.userIsDataEntry = userRoles.includes("DATA_ENTRY");
  }

  createFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        serviceDateString: new FormControl("ALL", Validators.required),
        adjusted: new FormControl("ALL"),
        apeAdjusted: new FormControl("ALL")
      });
    return fg;
  }

  createLineItemCreateParamsFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        bulkCreateLineItemCount: new FormControl(10),
      });
    return fg;
  }

  createDescriptionFilterFormGroup(): FormGroup {
    let fg: FormGroup =
      new FormGroup({
        description: new FormControl()
      });
    return fg;
  }

  ngAfterViewInit(): void {
    this.itemizedLineItemBulkEditor!.lineItemSaveComplete.subscribe((data) => {
      this.getLineItemQuery();
      this.getItemizedRevisionInfoQuery();
      this.screenMode = "DISPLAY";
    });
    this.itemizedLineItemBulkEditor!.lineItemEditCancel.subscribe((data) => {
      this.screenMode = "DISPLAY";
    });
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getLineItemQuery();
      });
    }
  }

  ngOnInit(): void {
  }

  /* Component Height is dynamically calculated by hand here */
  recalcComponentSize() {
    setTimeout(() => {
      this.componentHeightPX = window.innerHeight -
      this.topLevelWrapper?.nativeElement?.getBoundingClientRect().top - 30;
    }, 100);
  }

  getSelectedServiceDateString() : string {
    let sdfc: FormControl = this.lineItemQueryFormGroup.controls['serviceDateString'] as FormControl
    if (sdfc !== null && sdfc !== undefined) {
      return sdfc.value;
    } else {
      return "ALL";
    }
  }

  getDescriptionFilterValue() : string {
    let sdfc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl
    return sdfc.value;
  }

  getLineItemQuery() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    let filterNo: number = 0;
    if (this.getSelectedServiceDateString().toUpperCase() !== "ALL" ||
      this.getDescriptionFilterValue() !== null) {
        filterNo = 1;
    }
    this.itemizedLineItemService.getItemizedLineItemQuery(
      parseInt(this.itemizedRevisionId, 10),
      filterNo,
      this.getDescriptionFilterValue(),
      this.getSelectedServiceDateString(),
      [],
      [],
      "ALL",
      "ALL",
      "NA",
      "ALL",
      0,
      first,
      pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedLineItems = response.data;
        this.paginator.pageIndex = response?.pageNo;
        this.paginator.length = response?.totalRowCount;
      }
    });
  }

  getItemizedRevisionInfoQuery() {
    this.itemizedRevisionService.getItemizedRevisionInfo(parseInt(this.itemizedRevisionId, 10)).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.lineItemCount = response.data.numberOfLineItems;
        this.serviceDateStrings = ["ALL"];
        response.data.serviceDateStrings.forEach((sds: string) => {
          this.serviceDateStrings.push(sds);
        });
        this.totalBilledAmount = response.data.totalBilledAmount;
        this.totalAdjustedAmount = response.data.totalAdjustedAmount;
        this.apeProcessed = response.data.apeProcessed;
        this.serviceDateStart = response.data.deServiceStartDateString;
        this.serviceDateEnd = response.data.deServiceEndDateString;
      }
    });
  }

  closeItemizedLineItemComponent() {
    this.itemizedLineItemComponentClose.next(null);
  }

  onEdit(id: number) {
    if (!!this.itemizedLineItemBulkEditor) {
      // Note: this can be used to pass in as many line items as you wish (i.e. multi-edit capability)
      let liOrigData = this.getItemizedLineItem(id);
      let li:ItemizedLineItemUpdatePacket = new ItemizedLineItemUpdatePacket();
      li.id = liOrigData.id;
      li.serviceDateString = liOrigData.serviceDateString;
      li.description = liOrigData.description;
      li.revenueCodeString = liOrigData.revenueCode;
      li.revenueCodeDescription = liOrigData.revenueCodeDescription;
      li.cptCode = liOrigData.cptCode;
      li.units = liOrigData.units;
      li.billedAmount = liOrigData.billedAmount;
      li.billPageNo = liOrigData.billPageNo;
      li.billLineNo = liOrigData.billLineNo;
      li.preExAdjustedAmount = liOrigData.preExAdjustedAmount;
      li.preExExplanation = liOrigData.preExExplanation;
      li.preExAuditComments = liOrigData.preExAuditComments;
      li.adjustmentExplanationCode = liOrigData.adjustmentExplanationCode;
      li.adjustmentReasonCode = liOrigData.adjustmentReasonCode;
      li.adjustmentExplanationCodeDescription = liOrigData.adjustmentExplanationCodeDescription;
      li.adjustmentReasonCodeDescription = liOrigData.adjustmentReasonCodeDescription;
      this.itemizedLineItemBulkEditor.populateLineItemsOnFormGroup([li]);
    }
    this.screenMode = "EDIT";
  }

  onCopyLineItem(id: number) {
    // Calculate the service dates that are between the data entry range entered by the operator. These are
    // not the service dates gleaned from actual line item data.
    const calculatedServiceDatesFromRange: string [] = this.getServiceDateDaysFromDEDateRange();

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      serviceDateStart: this.serviceDateStart,
      serviceDateEnd: this.serviceDateEnd,
      serviceDateCount: calculatedServiceDatesFromRange.length
    };
    const dialogRef = this.matDialog.open(CopyCloneLineItemDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.copyCloneLineItemsForEdit(result.cloneOption, result.cloneCount, id);
      }
    });

  }

  private copyCloneLineItemsForEdit(cloneOption: string, cloneCount: number, id: number) {
    if (!!this.itemizedLineItemBulkEditor) {

      // Calculate the service dates that are between the data entry range entered by the operator. These are
      // not the service dates gleaned from actual line item data.
      const calculatedServiceDatesFromRange: string [] = this.getServiceDateDaysFromDEDateRange();

      // Note: this can be used to pass in as many line items as you wish (i.e. multi-edit capability)
      let liOrigData = this.getItemizedLineItem(id);
      let idx = 0;
      let lineItems = [];
      let lineItemCount = 0;
      while (true) {
        if (lineItemCount >= cloneCount) {
          break;
        }
        let li:ItemizedLineItemUpdatePacket = new ItemizedLineItemUpdatePacket();
        li.id = -1;
        if (cloneOption === "SERVICE_DATE_SPREAD") {
          if (idx < calculatedServiceDatesFromRange.length) {
            // Don't clone the original line item's service date, since the user has already entered it.
            if (calculatedServiceDatesFromRange[idx] === liOrigData.serviceDateString) {
              idx++;
              continue;
            } else {
              // Valid clone condition with a valid service date that's within the calculate range.
              li.serviceDateString = calculatedServiceDatesFromRange[idx];
            }
          } else {
            // This is a break condition. If we're spreading across service dates, and we run out of them,
            // it's time to stop no matter what the cloneCount value is.
            break;
          }
        } else {
          li.serviceDateString = liOrigData.serviceDateString;
        }
        li.description = liOrigData.description;
        li.revenueCodeString = liOrigData.revenueCode;
        li.revenueCodeDescription = liOrigData.revenueCodeDescription;
        li.cptCode = liOrigData.cptCode;
        li.units = liOrigData.units;
        li.billedAmount = liOrigData.billedAmount;
        li.billPageNo = liOrigData.billPageNo;
        li.preExAdjustedAmount = liOrigData.preExAdjustedAmount;
        li.preExExplanation = liOrigData.preExExplanation;
        li.preExAuditComments = liOrigData.preExAuditComments;
        li.adjustmentExplanationCodeDescription = liOrigData.adjustmentExplanationCodeDescription;
        li.adjustmentReasonCodeDescription = liOrigData.adjustmentReasonCodeDescription;
        lineItems.push(li);

        idx++;
        lineItemCount++;

      }
      this.itemizedLineItemBulkEditor.populateLineItemsOnFormGroup(lineItems);
    }
    this.screenMode = "EDIT";
  }

  private getItemizedLineItem(id: number):any {
    let li = null;
    this.itemizedLineItems.lineItems.forEach((aLi: any) => {
      if (aLi.id === id) {
        li = aLi;
      }
    });
    return li;
  }

  createLineItem() {
    if (!!this.itemizedLineItemBulkEditor) {
      let li:ItemizedLineItemUpdatePacket = new ItemizedLineItemUpdatePacket();
      li.id = -1;
      this.itemizedLineItemBulkEditor.populateLineItemsOnFormGroup([li]);
    }
    this.screenMode = "EDIT";
  }

  createLineItemBulk() {
    if (!!this.itemizedLineItemBulkEditor) {
      const lineItemCreateCount =
        this.lineItemCreateParameters.controls['bulkCreateLineItemCount'].value <= 200 ?
        this.lineItemCreateParameters.controls['bulkCreateLineItemCount'].value : 200;
      let lineItems = [];
      let idx = 0;
      for (idx = 0; idx < lineItemCreateCount; idx++) {
        let li:ItemizedLineItemUpdatePacket = new ItemizedLineItemUpdatePacket();
        li.id = -1;
        lineItems.push(li);
      }
      this.itemizedLineItemBulkEditor.populateLineItemsOnFormGroup(lineItems);
    }
    this.screenMode = "EDIT";
  }

  displayMode() {
    this.screenMode = "DISPLAY";
  }

  onDelete(id: number) {
    let li = this.getItemizedLineItem(id);
    if (li === null || li === undefined) {
      return;
    }
    if (li.description === null || li.description === undefined) {
      li.description = "*** blank description ***";
    }
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete Line Item?",
      infoLine1 : li.description + " Units: " + li.units + " Billed Amt: " + li.billedAmount
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteLineItem(id);
      }
    });
  }

  deleteLineItem(id: number) {
    this.itemizedLineItemService.deleteLineItem(id).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.getLineItemQuery();
        this.getItemizedRevisionInfoQuery();
      }
    });
  }

  formatServiceDate(lineItem: any) {
    return DateUtil.getDisplayDate(lineItem.serviceDateString);
  }

  serviceDateSelectionChanged(sds: any) {
    this.getLineItemQuery();
  }

  onDescriptionSearch() {
    this.getLineItemQuery();
  }

  clearDescriptionFilter() {
    let fc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl;
    fc.setValue(null);
    this.paginator.pageIndex = 0;
    this.getLineItemQuery();
  }

  clearAllFilters() {
    let fc: FormControl = this.descriptionFilterFormGroup.controls['description'] as FormControl;
    fc.setValue(null);
    fc = this.lineItemQueryFormGroup.controls['serviceDateString'] as FormControl;
    fc.setValue("ALL");
    fc = this.lineItemQueryFormGroup.controls['adjusted'] as FormControl;
    fc.setValue("ALL");
    fc = this.lineItemQueryFormGroup.controls['apeAdjusted'] as FormControl;
    fc.setValue("ALL");
    this.paginator.pageIndex = 0;
    this.getLineItemQuery();
  }

  private getServiceDateDaysFromDEDateRange(): string [] {
    if (this.serviceDateStart === null || this.serviceDateEnd === null) {
      return [];
    }
    for (var arr=[], dt = new Date(this.serviceDateStart); dt <= new Date(this.serviceDateEnd); dt.setDate(dt.getDate()+1)) {
      arr.push(new Date(dt).toISOString().slice(0,10));
    }
    return arr;
  }

}
