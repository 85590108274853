export abstract class Deserializable<T> {
  deserialize(input: any): T {
    return Object.assign(this, input);
  };

  [key: string]: any;
}

export function isDeserializable<T>(obj: any): obj is Deserializable<T> {
  if (obj && typeof obj === "object") {
    return "deserialize" in obj;
  }
  return false;
}

export interface DeserializableConstructors {
  [key: string]: new () => Deserializable<any>;
}

/**
 * This function will be very useful if we can consistently initialize non-recursive, deserializable properties on objects.
 * e.g. On each object, properties which are classes should be initialized:
 *
 * @param parent
 * @param input
 * @param arrayElementConstructors
 */
export function deserializeAssign<P>(
  parent: P,
  input: any,
  arrayElementConstructors: DeserializableConstructors = {}
): P {
  if (input == null) {
    return parent;
  }
  if (parent == null) {
    parent = {} as P;
  }

  if (!isDeserializable(parent)) {
    Object.assign(parent, input);
  } else {
    const keys = Object.keys(input);
    for (const key of keys) {
      if (key in arrayElementConstructors) {
        const elementConstructor = arrayElementConstructors[key];
        if (!elementConstructor) {
          continue;
        }
        if (!Array.isArray(input[key])) {
          // @ts-ignore
          parent[key] = [];
          continue;
        }
        // @ts-ignore
        parent[key] = input[key].map((obj: any) => {
          const newElement = Object.create(elementConstructor.prototype);
          if (isDeserializable(newElement)) {
            newElement.deserialize(obj);
          } else {
            Object.assign(newElement, obj);
          }
          return newElement;
        });
      } else if (isDeserializable(parent[key])) {
        parent[key].deserialize(input[key]);
      } else {
        // @ts-ignore
        parent[key] = input[key];
      }
    }
  }

  return parent;
}
