<lib-page>
  <ng-container pageTitle>
    Medical Incident Stays for Itemized Data Entry
  </ng-container>
  <ng-container pageContent>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let case">
          {{case.id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Case Type </th>
        <td mat-cell *matCellDef="let case"> {{case.type}} </td>
      </ng-container>
      <ng-container matColumnDef="statusTrackStatus">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let case"> {{case.statusTrackStatus}} </td>
      </ng-container>
      <ng-container matColumnDef="altexternalid">
        <th mat-header-cell *matHeaderCellDef> External Id </th>
        <td mat-cell *matCellDef="let case"> {{case.altExternalId}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let case"> {{case.name}} </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef> Created By </th>
        <td mat-cell *matCellDef="let case"> {{case.createdUser}} </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let case"> {{case.createdDate | date:'MM/dd/yyyy hh:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let case">

          <mat-menu #actionMenu="matMenu">
            <button
              mat-menu-item
              (click)="onManualDataEntry(case.id)">
              Manual Data Entry
            </button>

            <button
            mat-menu-item
              color="accent"
              (click)="onDataEntryComplete(case.id)">
              DE Complete
            </button>

            <button
              mat-menu-item
              (click)="onShowAttachments(case.id)">
              Attachment Download/Upload
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{name: 'Sally'}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    </table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>
