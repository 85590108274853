export class CaseEdit {
  id?: number;
  name?: string;
  altExternalId?: string;
  trialCase?: boolean;
  caseReviewDueDate?: string;
  salesforceCaseId?: string;
  type: any;
  lineOfBusiness: any;
  customerPriority: any;
  clientClaimId?: string;
  preReviewPayable?: number;
  postReviewPayable?: number;
  drgPaidDate?: string;
  drgPaymentAmount?: number;
  networkDiscount?: number;
  notes?: string;
  clientMemberNumber?: string;
  postPaymentReview?: boolean;
  clientInternalAdjustments?: number;
}
