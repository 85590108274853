<mat-dialog-content class="alt-theme">
  <h1 mat-dialog-title>Generate IB Request Letter</h1>

  <div style="width: 100%; display: table;">
    <div style="display: table-row">
        <div style="display: table-cell;">
          <button
            class="generate-ib-request-letter-button"
            mat-flat-button color="accent"
            (click)="genIBRequestLetter(1)">
            Gen IB Request Letter #1 (.pdf)
          </button>  
          <button
            class="generate-ib-request-letter-button"
            mat-flat-button color="accent"
            (click)="genIBRequestLetter(2)">
            Gen IB Request Letter #2 (.pdf)
          </button> 
          <button
            class="generate-ib-request-letter-button"
            mat-flat-button color="accent"
            (click)="genIBRequestLetter(3)">
            Gen IB Request Letter #3(.pdf)
          </button>                                   
        </div>
    </div>
  </div> 

</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <button mat-flat-button (click)="onCancel()" color="primary">
      <mat-icon>cancel</mat-icon> Cancel
  </button>
</mat-dialog-actions>

