import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from "../view/base.service";
import { EnvironmentService } from "../environment.service";
import { AttachmentPacket } from "../model/net/attachmentPacket";
import { DataResponse } from "../model/data-response";
import { FilePacket } from "../model/net/filePacket";
import { ItemizedUpdatePacket } from "../model/net/itemized-update-packet";
import { Logger } from "../util/logger";


@Injectable()
export class AttachmentService extends BaseService {
  baseEndpoint = this.apiEndpoint("attachment");

  constructor(
    protected override httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
    super(new Logger("AttachmentService"));
  }

  getAllAttachmentsforMICase(miCaseId: number, first: number = 0, max: number = 9999): Observable<any> {
    return this.httpClient.get<any>(this.baseEndpoint + "/list/mi-case/" + miCaseId.toString()
      + "?first=" + first + "&max=" + max);
  }

  createAttachment = (attachment: AttachmentPacket): Observable<DataResponse<boolean>> => {
    return this.httpClient.post<DataResponse<boolean>>(this.baseEndpoint, attachment);
  };

  updateAttachment = (attachment: AttachmentPacket): Observable<DataResponse<boolean>> => {
    return this.httpClient.put<DataResponse<boolean>>(this.baseEndpoint, attachment);
  }

  getAttachmentInfo = (attachmentId: number): Observable<DataResponse<FilePacket>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}`;
    return this.httpClient.get<DataResponse<FilePacket>>(endpoint);
  }

  downloadAttachment = (attachmentId: number): Observable<DataResponse<FilePacket>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/download`;
    return this.httpClient.get<DataResponse<FilePacket>>(endpoint);
  }

  emailAttachment = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/email`;
    return this.httpClient.get<DataResponse<any>>(endpoint);
  }

  getAttachmentTypes = (): Observable<DataResponse<String[]>> => {
    const endpoint =`${this.baseEndpoint}/types`
    return this.httpClient.get<DataResponse<String[]>>(endpoint);
  }

  convertToUB04 = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/toub04`;
    return this.httpClient.post<DataResponse<any>>(endpoint, {});
  };

  convertToItemized = (attachmentId: number, dto: ItemizedUpdatePacket): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}/toitemized`;
    return this.httpClient.post<DataResponse<any>>(endpoint, dto);
  };

  deleteAttachment = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/${attachmentId}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  }

  deleteHCPlanLOBlogoAttachment = (hcPlanLOBId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/hcplan-logo/${hcPlanLOBId}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  } 
  
  deleteAttachmentRelationalMap = (armId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/arm/${armId}`;
    return this.httpClient.delete<DataResponse<any>>(endpoint);
  }  

  getAllAttachmentsForEDIRun = (ediRunId: number, first, max): Observable<DataResponse<AttachmentPacket[]>> => {
    const endpoint = `${this.baseEndpoint}/edi-run/${ediRunId}/list/` + "?first=" + first + "&max=" + max;
    return this.httpClient.get<DataResponse<AttachmentPacket[]>>(endpoint);
  }

  toggleAttachmentPublishToWeb = (attachmentId: number): Observable<DataResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/publish-to-web/${attachmentId}`;
    return this.httpClient.post<DataResponse<any>>(endpoint, null);
  }
}
