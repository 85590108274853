<mat-dialog-content>
  <div class="dialog-header">
    <h1 mat-dialog-title>Edit Other Information</h1>
    <!-- <button 
      mat-button
      mat-stroked-button 
      class="ub04-icon-btn"
      tabindex="-1"
      (click)="onCancel()"
      matTooltip="Close Dialog">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>

    <div class="create-form">
      <form [formGroup]="formGroup" class="container create-form alt-theme">
        <!-- <mat-form-field appearance="fill">
        <mat-label>Font size for your Remarks</mat-label>
        <mat-select #fontSize value="16px" (selectionChange)="ngOnInit()">
            <mat-option value="10px">10px</mat-option>
            <mat-option value="12px">12px</mat-option>
            <mat-option value="14px">14px</mat-option>
            <mat-option value="16px">16px</mat-option>
            <mat-option value="18px">18px</mat-option>
            <mat-option value="20px">20px</mat-option>
        </mat-select>
        </mat-form-field> -->
        <!-- <mat-form-field class="text-area" appearance="outline">
            <mat-label>Remarks</mat-label>
            <textarea matInput
                      cdkTextareaAutosize 
                      rows="4"
                      formControlName="remarks"
            >
            </textarea>
        </mat-form-field> -->
      <!-- <br>  -->
      <mat-form-field class="form-field-spacer" appearance="outline">
        <mat-label>Admission Type</mat-label>
        <mat-select formControlName="admissionType">
            <mat-option value="EMERGENCY">Emergency</mat-option>
            <mat-option value="URGENT">Urgent</mat-option>
            <mat-option value="ELECTIVE">Elective</mat-option>
            <mat-option value="NEWBORN">Newborn</mat-option>
            <mat-option value="TRAUMA_CENTER">Trauma Center</mat-option>
            <mat-option value="NOT_AVAILABLE">Not available</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="inner-container">
        <mat-form-field class="form-field-spacer" appearance="outline" class="date-row-input">
          <mat-label>Admission Date</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="admissionDate">

          <!-- DatePicker not working (timezone issue) -->
          <!-- <input matInput [matDatepicker]="picker" formControlName="admissionDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker> -->
        </mat-form-field>

        <mat-form-field class="form-field-spacer" appearance="outline" class="date-row-input">
          <mat-label>Cover Period From</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="coverPeriodFrom">
        </mat-form-field>    

        <mat-form-field class="form-field-spacer" appearance="outline" class="date-row-input">
          <mat-label>Cover Period Thru</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="coverPeriodThru">
        </mat-form-field> 

          <mat-form-field class="form-field-spacer" appearance="outline" class="date-row-input">
            <mat-label>Admission Hour</mat-label>
            <input matInput type="number" formControlName="admissionHour">
          </mat-form-field>
    
          <mat-form-field class="form-field-spacer" appearance="outline" class="date-row-input">
            <mat-label>Discharge Hour</mat-label>
            <input matInput type="number" formControlName="dischargeHour">
          </mat-form-field>
      </div>

        <div>
          <mat-form-field class="form-field-spacer" appearance="outline" class="code-field">
            <mat-label>Admission Code</mat-label>
            <mat-select formControlName="admissionSourceCode" placeholder="Admission Code">
                <!-- <mat-option value="1">1 - Emergency</mat-option>
                <mat-option value="2">2 - Urgent</mat-option>
                <mat-option value="3">3 - Elective</mat-option>
                <mat-option value="4">4 - Newborn</mat-option>
                <mat-option value="5">5 - Trauma Center</mat-option>
                <mat-option value="9">9 - Not available</mat-option> -->
                <mat-option *ngFor="let source of admissionSourceList" [value]="source.code">
                  {{source.code}} - {{source.admissionType}}
                </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field 
              appearance="fill"
              class="description-field">
              <mat-label>Description for Admission Source Code</mat-label>
              <input 
                matInput 
                formControlName="admissionSourceDescription" 
                placeholder="Description for Admission Source Code">
          </mat-form-field>
        </div>

        <div>
          <mat-form-field 
            appearance="outline"
            class="code-field"
          >
          <mat-label>Discharge Status Code</mat-label>
          <input 
            matInput 
            formControlName="dischargeStatusCode" 
            placeholder="Code">
          </mat-form-field>
          
          <mat-form-field 
            appearance="fill"
            class="description-field">
            <mat-label>Description for Discharge Status Code</mat-label>
            <input 
              matInput 
              formControlName="dischargeStatusDescription" 
              placeholder="Description for Discharge Status code">
          </mat-form-field>

        </div>

      <mat-form-field class="text-area" appearance="outline">
        <mat-label>Remarks</mat-label>
        <textarea matInput
                  cdkTextareaAutosize 
                  rows="4"
                  formControlName="remarks"
        >
        </textarea>
    </mat-form-field>
      </form>
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions class="fennec-controls-nn">
    <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
      Mark Complete
    </mat-checkbox>
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
  </mat-dialog-actions>
  
  