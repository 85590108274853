import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  DataResponse,
  EnvironmentService,
  Logger,
  PagedResponse,
  SavingsByExplAndReasonQueryPacket
} from "xf-common";

const log = new Logger("DashboardService");

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  baseEndpoint = super.apiEndpoint("");

  constructor(
    override httpClient: HttpClient
  ) {
    super(log);
  }

  getDashboardData = (endpointSuffix: string, paramsPacket: any): Observable<DataResponse<any[]>> => {
    return this.httpClient.put<any>(`${this.baseEndpoint}dashboard/${endpointSuffix}`, paramsPacket);
  }

  getRecentActivityList(first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.baseEndpoint}activity-diary/list/?first=${first}&max=${max}`);
  }

  getMICaseMonthlyCountInfo(startYearMonth:string, endYearMonth:string): Observable<DataResponse<any>> {
    return this.httpClient.get<any>(`${this.baseEndpoint}mi-case/monthly-count?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}`);
  }

  getReasonsAndExplanations = (): Observable<DataResponse<SavingsByExplAndReasonQueryPacket[]>> => {
    const ep = `${this.baseEndpoint}dashboard/all-reasons-and-explanations`;
    return this.httpClient.get<DataResponse<SavingsByExplAndReasonQueryPacket[]>>(ep);
  }
}
