<span class="loadingInput end">
  <input
    autocomplete="off"
    id="findInput"
    class="toolbarField"
    title="Find"
    [placeholder]="'Find in document…'"
    data-l10n-id="pdfjs-find-input"
    name="search-input-field"
  />
</span>
