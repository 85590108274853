import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject } from 'rxjs';
import { BaseComponent } from "../../../view/base.component";
import { AddressComponent } from "../../../admin/address-admin/address.component";
import { CompanyPacket } from "../../../model/net/company-packet";
import { CompanyService } from "../../../company/company.service";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../../util/logger";

@Component({
  selector: 'app-ub04-add-company',
  templateUrl: './ub04-add-company.component.html',
  styleUrls: ['./ub04-add-company.component.scss']
})
export class Ub04AddCompanyComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("CompanyAddComponent");
  formGroup!: FormGroup;

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  companySaveComplete: Subject<any> = new Subject();

  constructor(
    private companyService: CompanyService,
    protected dialogRef: MatDialogRef<Ub04AddCompanyComponent>,
    protected snack: FennecSnackbarService
  ) {
    super();
    this.formGroup = new FormGroup({
      name: new FormControl("", Validators.required),
      addressValidator: new FormControl()
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.findAddressComponent();
  }

  findAddressComponent() {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        this.saveCompanyToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  saveCompanyToServer(addressId: number){
    this.formGroup.markAllAsTouched();
    let packet = new CompanyPacket();
    packet.id = -1;
    packet.name = this.formGroup.controls["name"].value;
    packet.addressId = addressId;

    this.companyService.createCompany(packet).subscribe(response => {
      if (response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage)
      } else {
        this.companySaveComplete.next(response.data);
        super.showSuccessSnack(`Successfully Created!`);
        let returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    })
  }

  validateAddress(){
    let addressFormControl: FormGroup | undefined = this?.addressComponent?.formGroup;

    if(addressFormControl){
      if(!addressFormControl.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.addressComponent?.saveAddressToServer();
  }

}
