import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: '[pageToolbar]',
  exportAs: '[pageToolbar]'
})
export class PageToolbarDirective {
  constructor(private el: ElementRef) {}

}
