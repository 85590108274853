<lib-page>
  <ng-container pageTitle>
    Dashboards [{{dashboardContext}}]
  </ng-container>
  <ng-container pageToolbar>
    <div class="dashboard-actions-div-wrapper">
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button color="accent" (click)="editDashboardFilterParams()">
        Filter Parameters
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="clearSort()">
        Clear Sort
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="clearCache()">
        Clear Filter Cache
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="exportToXLSX()">
        Export to XLSX
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="exportToCSV()">
        Export to CSV
      </button>
    </div>
  </ng-container>

  <ng-container pageContent>
    <nav mat-tab-nav-bar>
      <ng-container *ngFor="let dc of dashboardConfigArray">
        @if (dc.dashboardConfig.dashboardContext.includes(dashboardContext)) {
          <a mat-tab-link (click)="selectDashboard(dc.dashboardKey)"
             class="dashboard-navigation-button"
             [class.dashboard-navigation-button-selected]="selectedDashboardKey === dc.dashboardKey"
          >
            {{dc.dashboardConfig.name}}
          </a>
        }
      </ng-container>
    </nav>

    <div class="dashboard-div-wrapper">
      <app-dashboard-tabulator
        [gridData]="gridData$.asObservable()"
        [clearSort$]="clearSort$.asObservable()"
        [exportToCSV$]="exportToCSV$.asObservable()"
        [exportToXLSX$]="exportToXLSX$.asObservable()"
        [filters$]="filters$.asObservable()"
      ></app-dashboard-tabulator>
    </div>
  </ng-container>
</lib-page>
