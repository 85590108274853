import { SimpleObject } from "../simple-object";


export class FilePacket {
  fileName?: string;
  fileType?: string;
  mimeType?: string;
  data?: string; // [base-64-encoded(ZLIB-compressed byte-array)]
  config: SimpleObject = {};
}
