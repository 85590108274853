import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { BaseResponse } from "../../model/base-response";
import { UB04RevenueCodeBulkUpdatePacket } from "../../model/net/ub04-revenue-code-bulk-update-packet";
import { UB04RevenueCodeUpdatePacket } from "../../model/net/ub04-revenue-code-update-packet";
import { CodeValidationService } from "../../code/code-validation.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../util/logger";


@Component({
  selector: 'app-ub04-revenue-code-dialog',
  templateUrl: './ub04-revenue-code-dialog.component.html',
  styleUrls: [
    './ub04-revenue-code-dialog.component.scss',
    '../ub04-dialog-base-styles.scss'
  ]
})
export class Ub04RevenueCodeDialogComponent extends UB04StatusTrackBaseDialogComponent
  implements OnInit {
  override log = new Logger("Ub04RevenueCodeDialogComponent");

  formGroup!: FormGroup;
  ub04RevenueCodes: any [] = [];
  dollarAmountSubtotal: number = 0;
  unitsSubtotal: number = 0;

  // Used to manage UI state to prevent duplication from save button smashing.
  updateInFlight: boolean = false;

  @ViewChildren("revenueCode") private revenueCodeInputFields: QueryList<ElementRef>;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04RevenueCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // Provide an instance StatusTrackService to the Base component because Angular won't inject
    // this. Also provide additional params (ub04id, element) so the Base component has enough
    // information to do it's work (like make endpoint calls for status tracking lock / unlock).
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_REV_CODE, statusTrackService);
    this.constructFormGroup();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getRowDisplayStyle(idx: number): any {
    let rcFormGroup: FormGroup = this.getRevenueCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  /* Constructs the bare-bones skeleton FormGroup that will drive data entry on the form. */
  constructFormGroup() {
    this.formGroup = new FormGroup({
      revenueCodes: new FormArray([])
    });
  }

  /* Get's the revenue codes formArray from the form group. This will be an array of FormGroups that
    models an entire revenue code row for each element in the array. Note how it's coded as a 'getter'
    at the class level. This is done so the HTML can properly see this control array when rendering. */
  get revenueCodes(): FormArray {
    return this.formGroup.controls["revenueCodes"] as FormArray;
  }

  /* Get's a single revenue code formGroup control from the revenueCodes FormArray in the parent FormGroup.
    Note how it's modelled as a getter at the class level. This is done so the HTML can properly see this
    control when rendering. */
  getRevenueCodeFormGroup(idx: number): FormGroup {
    return this.revenueCodes.at(idx) as FormGroup;
  }

  /* After the existing list of ub04 revenue codes is retrieved, we make a FormArray of FormGroups that
    represent what we want to edit on the screen. Notice the tempKey. This is a temporary, client side
    key we assign to each row so we can retrieve the control when we need to work with it, after
    certain events are fired. This was the only way I could figure out how to do this. */
  populateRevenueCodesOnFormGroup() {
    // If this is the first time entering revenue codes, pop user in with 3 blank lines
    // to start with.
    // NOTE-  Removed per client request.  User starts with one line
    if (this.ub04RevenueCodes.length <= 0) {
      // let idx = 0;
      // for (idx = 0; idx < 3; idx++) {
        this.onAddRevenueCodeLine();
      // }
      return;
    }

    // Prepopulate from existing ub04 revenue list.
    let revenueCodes = this.revenueCodes;
    revenueCodes.clear();
    let idx = 0;
    for (idx = 0; idx < this.ub04RevenueCodes.length; idx++) {
      let rc = this.ub04RevenueCodes[idx];
      let rcFormGroup: FormGroup = new FormGroup({
        id: new FormControl(rc.id),
        revenueCode: new FormControl(rc.revenueCode, [Validators.required, Validators.maxLength(4)]),
        description: new FormControl(rc.revenueCodeDescription),
        units: new FormControl(rc.units, Validators.required),
        amount: new FormControl(rc.amount, Validators.required),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
      });
      rcFormGroup.controls['description'].disable();
      // Chatty revenue code validation during data entry
      rcFormGroup.controls['revenueCode'].valueChanges.subscribe((val) => {
        this.validateRevenueCode(rcFormGroup, val);
      });
      rcFormGroup.controls['units'].valueChanges.subscribe(() => {
        this.calculateUnitsSubtotal();
      })
      rcFormGroup.controls['amount'].valueChanges.subscribe(() => {
        this.calculateDollarAmountSubtotal();
      })
      revenueCodes.push(rcFormGroup);
    }

    this.calculateDollarAmountSubtotal();
    this.calculateUnitsSubtotal();
  }

  /* This will add a new, blank revenue code line (FormGroup) so the user can enter a new ub04 revenue code */
  onAddRevenueCodeLine() {
    let revenueCodes = this.revenueCodes;
    let rcFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      revenueCode: new FormControl("", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(),
      units: new FormControl("", Validators.required),
      amount: new FormControl("", Validators.required),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });
    rcFormGroup.controls['description'].disable();
    // Chatty revenue code validation during data entry
    rcFormGroup.controls['revenueCode'].valueChanges.subscribe((val) => {
      this.validateRevenueCode(rcFormGroup, val);
    });
    rcFormGroup.controls['units'].valueChanges.subscribe(() => {
      this.calculateUnitsSubtotal();
    })
    rcFormGroup.controls['amount'].valueChanges.subscribe(() => {
      this.calculateDollarAmountSubtotal();
    })
    revenueCodes.push(rcFormGroup);

    setTimeout(() => {
      this.revenueCodeInputFields.last.nativeElement.focus();
    }, 10);

  }

  /* Chatty revenue code validation during data entry. This will validate a revenue code that the
    user is trying to enter. The passed in revenueCodeRow is just the object of form 'values' and has
    no pointer to the control itself firing the event. Therefore, we use the tempKey we assigned
    earlier to find the control that is firing the event so we can introspect it and set validation
    status's on it. This is kind of lame, and I wish Angular provided a more straightforward way of
    doing this. But this works. It just seems like too much work to achieve something that should
    already be out-of-the-box from Angular */
  validateRevenueCode(row: FormGroup, value: any) {
    let rcFormControl: FormControl = row.controls['revenueCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;
    if (!rcFormControl.pristine) {
      if (rcFormControl.value.length < 2 && rcFormControl.value.length < 4) {
        rcFormControl.markAsPristine();
        descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        rcFormControl.setErrors({'incorrect': true});
      } else {
        let value = rcFormControl.value;
        if(value.length === 3) {
          value = "0" + value;
        }

        this.codeValidationService.getRevenueCode(value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            rcFormControl.markAsPristine();
            rcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              rcFormControl.markAsPristine();
              descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              rcFormControl.setErrors(null);
            } else {
              descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              rcFormControl.markAsPristine();
              rcFormControl.setErrors({'incorrect': true});

            }
          }
        });

      }
    }
  }

  /* Given a tempKey - find the FormGroup (i.e. ub04 revenue code row) control that goes with it */
  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.revenueCodes.length; idx++) {
      let rc: FormGroup = this.getRevenueCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  fetchData() {
    this.ub04Service.getUb04RevenueCodes(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04RevenueCodes = response.data;
        this.populateRevenueCodesOnFormGroup();
      }
    });
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getRevenueCodeFormGroup(idx);
    // These are newly added by user during this session. Just remove them from the
    // form group list.
    if (rfg.controls["id"].value < 0) {
      this.revenueCodes.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  // Setting a flag and delaying the update just a bit so the UI renders the save button disabled. Prevents save button mashing frustration causing
  // duplication of updates.
  onSubmit() {
    this.updateInFlight = true;
    setTimeout(() => {
      this.bulkUpdateUB04RevenueCodes();
    }, 50);
  }

  bulkUpdateUB04RevenueCodes() {
    this.formGroup.markAllAsTouched();
    let updDto: UB04RevenueCodeBulkUpdatePacket = new UB04RevenueCodeBulkUpdatePacket();
    let rcRows: FormArray = this.revenueCodes;
    rcRows.controls.forEach((rcRow: any) => {
      let revCode = rcRow.controls["revenueCode"].value;
      if (revCode !== null && revCode !== undefined) {
        let codeString = revCode;
        if(codeString.length === 3) {
          codeString = "0" + codeString;
        }


        let rcDto: UB04RevenueCodeUpdatePacket = new UB04RevenueCodeUpdatePacket();
        rcDto.id = rcRow.controls["id"].value;
        rcDto.ub04Id = this.data.ub04Id;
        rcDto.revenueCodeId = -1;
        rcDto.revenueCodeString = codeString;
        rcDto.units = rcRow.controls["units"].value;
        rcDto.amount = rcRow.controls["amount"].value;
        rcDto.serviceDateString = "2000-01-01";
        rcDto.deleteRow = rcRow.controls["deleteRow"].value;
        updDto.ub04RevenueCodes.push(rcDto);
      }
    });

    this.ub04Service.putUb04RevenueCodesBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.updateInFlight = false;
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);

        if (response.data !== "OK") {
          this.snack.showWarningSnack(response.data);
        } else {
          this.snack.showSuccessSnack(`Successfully Updated!`);
        }
      }
    });
  }

  getIconStyle(idx: number){
    let rcFormGroup: FormGroup = this.getRevenueCodeFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getRevenueCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  calculateUnitsSubtotal = () => {
    let newTotal = 0;
    const formArray = this.formGroup.get('revenueCodes') as FormArray;
    if(formArray != null) {
      formArray.controls.forEach((controlGroup) => {
        if (controlGroup.get('units')?.value) {
          newTotal += controlGroup.get('units')?.value;
        }
      })
    }
    this.unitsSubtotal = newTotal;
  }

  calculateDollarAmountSubtotal = () => {
    let newTotal = 0;
    const formArray = this.formGroup.get('revenueCodes') as FormArray;
    if(formArray != null) {
      formArray.controls.forEach((controlGroup) => {
        if (controlGroup.get('amount')?.value) {
          newTotal += controlGroup.get('amount')?.value ?? 0;
        }
      })
    }
    this.dollarAmountSubtotal = parseFloat(newTotal.toFixed(2));
  }

}
