
<div matDialogTitle>
 Copy / Clone Line Item Options
</div>

<mat-dialog-content>
  <form [formGroup]="formGroup" class="container">
    <mat-form-field appearance="outline" color="accent" class="input-clone-count">
      <mat-label>Clone Count</mat-label>
      <input type='number' matInput id="cloneCount" formControlName="cloneCount"/>
    </mat-form-field>
    <mat-checkbox formControlName="serviceDateSpread">Service Date Spread</mat-checkbox>
  </form>
</mat-dialog-content>
  
<mat-dialog-actions>
  <div class="fennec-controls">
    <button mat-button mat-raised-button (click)="onCloneCount()" color="primary">
      <mat-icon>checked</mat-icon> Clone Count
    </button>    
    <button mat-button mat-stroked-button (click)="onNo()">
      <mat-icon>cancel</mat-icon> Cancel
    </button>
  </div>
</mat-dialog-actions>
