import {DataResponse} from "./data-response";
import {PagedResponse} from "./paged-response";
import {Observable} from "rxjs";

// @formatter:off
export type VarLengthTuple<T> = [T?, ...T[]];

export type ExtractInlineParams<
  Type extends (...args: VarLengthTuple<any>) => any
> = Type extends (...args: infer X) => any
  ? X
  : never;

export type CallbackType<Data> = DataResponse<Data> | PagedResponse<Data[]>;

export type Fn<P extends VarLengthTuple<any>, C extends CallbackType<D>, D> = (...args: P) => Observable<C>;

export type ExtractParams<Type extends Fn<VarLengthTuple<any>, CallbackType<D>, D>, D = any> = Type extends Fn<infer X, any, any>
  ? X
  : never;

export type ExtractCallback<Type extends Fn<any, CallbackType<D>, D>, D = any> = Type extends Fn<any, infer X, any>
  ? X
  : never;

export type ExtractData<Callback> = Callback extends CallbackType<infer X> ? X : never;

const xfRequestProperties = [
  "requestDescription",
  "requestFn",
  "fnParams",
  "cancelRequest$",
  "noErrorPrefix",
  "noErrorFormatting"
];
const xfRequestHandlers = [
  "onComplete",
  "onError",
  "onResponse",
  "onSuccess"
];
const xfRequestFields = [...xfRequestProperties, ...xfRequestHandlers];

export interface XFRequestDefinition<
  R extends Fn<P, C, D>,
  C extends CallbackType<D>,
  D = any,
  P extends VarLengthTuple<any> = []
> {
  requestDescription?: string;
  requestFn: R;
  fnParams?: P;
  cancelRequest$?: Observable<any>;
  noErrorPrefix?: boolean;
  noErrorFormatting?: boolean;

  [key: string]: any;
}

export type XFRequestHandler<P extends VarLengthTuple<any>> = (...args: P) => void;
export type OnComplete = () => void;
export type OnSuccess<D = any> = (data: D) => void;
export type OnError = (message: string) => void;
export type OnResponse<D = any, C extends CallbackType<D> = DataResponse<D>> = (response: C) => void;

export interface XFRequestHandlers<D = any, C extends CallbackType<D> = DataResponse<D>> {
  onComplete?: OnComplete | OnComplete[];
  onError?: OnError | OnError[];
  onResponse?: OnResponse | OnResponse[];
  onSuccess?: OnSuccess | OnSuccess[];
  override?: boolean;

  [key: string]: any;
}

export class XFRequest<
  R extends Fn<P, C, D>,
  C extends CallbackType<D> = ExtractCallback<R>,
  D = ExtractData<C>,
  // @ts-ignore
  P extends VarLengthTuple<any> = ExtractParams<R>
> implements XFRequestDefinition<R, C, D, P>, XFRequestHandlers<D, C> {
  requestDescription?: string;
  requestFn: R;
  fnParams?: P;
  cancelRequest$?: Observable<any>;
  noErrorPrefix?: boolean;
  noErrorFormatting?: boolean;

  onComplete?: OnComplete | OnComplete[];
  onError?: OnError | OnError[];
  onResponse?: OnResponse | OnResponse[];
  onSuccess?: OnSuccess | OnSuccess[];

  [key: string]: any;

  constructor(requestFn: R, description?: string, cancelRequest$?: Observable<any>) {
    this.requestDescription = description;
    this.requestFn = requestFn;
    this.cancelRequest$ = cancelRequest$;
  }

  static from<R extends Fn<P, C, D>,
    C extends CallbackType<D> = ExtractCallback<R>,
    D = ExtractData<C>,
    // @ts-ignore
    P extends VarLengthTuple<any> = ExtractParams<R>>(params: XFRequestDefinition<R, C, D, P> & XFRequestHandlers<D, C>) {
    return new XFRequest<R, C, D, P>(params.requestFn, params.requestDescription,)
      .def(params)
      .handle(params);
  }

  clone = (): XFRequest<R, C, D, P> => {
    const newRequest = new XFRequest<R, C, D, P>(this.requestFn, this.requestDescription);

    for (const field of xfRequestFields) {
      if (this[field] != null) {
        newRequest[field] = this[field];
      }
    }

    return newRequest;
  };

  def = (input?: Partial<XFRequestDefinition<R, C, D, P>>): XFRequest<R, C, D, P> => {
    if (input == undefined) {
      return this;
    }

    const newRequest = this.clone();

    for (const field of xfRequestProperties) {
      if (input[field] != undefined) {
        newRequest[field] = input[field];
      }
    }

    return newRequest;
  }

  handle = (input?: Partial<XFRequestHandlers<D, C>>, override?: boolean): XFRequest<R, C, D, P> => {
    if (input == undefined) {
      return this;
    }
    if (input.override === true) {
      override = input.override;
    }

    const newRequest = this.clone();

    for (const field of xfRequestHandlers) {
      if (input[field] != null) {
        if (newRequest[field] == undefined || override) {
          newRequest[field] = Array.isArray(input[field]) ? input[field] : [input[field]];
        } else {
          newRequest[field] = (Array.isArray(newRequest[field])
            ? newRequest[field]
            : [newRequest[field]]
          ).concat(input[field]);
        }
      }
    }

    return newRequest;
  }

}
// @formatter:on
