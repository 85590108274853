<lib-page>
  <ng-container pageTitle>
    User Profile
  </ng-container>
  <ng-container pageContent>
  <div class="user-profile-div-wrapper">
    <div style="display: flex; flex-direction:column; margin: 2%" class="alt-theme">

      <mat-form-field>
        <mat-label>
          First Name
        </mat-label>
        <input matInput [(ngModel)]="firstName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          Last Name
        </mat-label>
        <input matInput [(ngModel)]="lastName">
      </mat-form-field>

    </div>

    <app-address #addressComponent></app-address>

    <div>
      <button mat-raised-button color="accent" (click)="onSave()" [disabled]="!addressComponentFormValid()">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>
  </ng-container>
</lib-page>
