import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identifierName } from '@angular/compiler';
import { Injectable } from '@angular/core';

import { Observable, catchError, of } from 'rxjs';
import { BaseResponse } from "../model/base-response";
import { ItemizedUpdatePacket } from "../model/net/itemized-update-packet";
import { UB04ItemizedPacket } from "../model/net/ub04-itemized-packet";
import { EnvironmentService } from "../environment.service";

@Injectable({
  providedIn: 'root'
})
export class ItemizedService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getAllItemizedForMICase(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized/list/mi-case/" + id.toString());
  }

  getAllItemizedForMICasePaginated(id: number, first:number, max:number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized/list/mi-case/p/" + id.toString() + "?first=" + first + "&max=" + max);
  }

  addItemized(payload: ItemizedUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "itemized/create", payload);
  }

  addUb04ToItemized(payload: UB04ItemizedPacket): Observable<any> {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}itemized/add-ub04`, payload);
  }

  update(payload: ItemizedUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}itemized/update/`, payload);
  }

}
