<lib-page [isLoading]="isLoading" [noDataFound]="!userPrimaryClientList.length">
  <ng-container pageTitle>
    Medical Incident Stays
  </ng-container>
  <ng-container pageContent>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>Filters</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field *ngIf="!miCaseId" class="search-field" appearance="outline" color="accent">
          <mat-label>
            Stay ID
          </mat-label>
          <input type="number" matInput [(ngModel)]="queryParams.miCaseId" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Alt External ID
          </mat-label>
          <input matInput [(ngModel)]="queryParams.altExternalId" (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Client Claim ID
          </mat-label>
          <input matInput [(ngModel)]="queryParams.clientClaimId" (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Case Name
          </mat-label>
          <input matInput [(ngModel)]="queryParams.miCaseName" (keydown.enter)="searchMICases()">
        </mat-form-field>
        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Status
          </mat-label>
          <mat-select id="statusFilter" [(ngModel)]="queryParams.statusTrackStatus" (valueChange)="searchMICases()">
            <mat-option *ngFor="let statusTrackStatus of miCaseMainStatusTrackStatuses" [value]="statusTrackStatus.value">
              {{statusTrackStatus.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Created By
          </mat-label>
          <input matInput [(ngModel)]="queryParams.createdBy" (keydown.enter)="searchMICases()">
        </mat-form-field>

        <mat-form-field class="search-field" appearance="outline" color="accent">
          <mat-label>
            Primary Client Name
          </mat-label>
          <input matInput [(ngModel)]="queryParams.primaryClientName" autofocus (keydown.enter)="searchMICases()">
        </mat-form-field>

        <div class="button-group">
          <button mat-flat-button color="accent" class="search-button" (click)="searchMICases()">
            <mat-icon>search</mat-icon>
            Search
          </button>
          <button mat-stroked-button color="accent" (click)="clearSearch()">
            Clear
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef (click)="toggleIdOrderBy()">
          <div class="id-wrapper">
            <mat-icon>{{idOrderBy === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon>
            ID
          </div>
        </mat-header-cell>

        <mat-cell *matCellDef="let case">
          <a [routerLink]="['/mi-case/', case.id]" routerLinkActive="active">{{case.id}}</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Case Type </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.type}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="statusTrackStatus">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let case">
          <span [matTooltip]="case.onHold ? case.holdReasonUserLabel || 'Unspecified' : case.statusTrackStatus">
            {{case.statusTrackStatus}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="clientClaimId">
        <mat-header-cell *matHeaderCellDef> Client Claim Id </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.clientClaimId}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="altExternalId">
        <mat-header-cell *matHeaderCellDef> Alt Ext Id </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.altExternalId}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="patientName">
        <mat-header-cell *matHeaderCellDef> Patient Name </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let case">
          <span *ngIf="case.patientLastName != 'Not Available'">{{case.patientFirstName}} {{case.patientLastName}}</span>
          <span *ngIf="case.patientLastName == 'Not Available'" class="patient-name-not-available">{{case.patientLastName}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="currentXFWFStageName">
        <mat-header-cell *matHeaderCellDef> Stage </mat-header-cell>
        <mat-cell *matCellDef="let case">
          {{case.currentXFWFStageName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef>Client Name</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let case">
          {{ case.clientName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lineOfBusiness">
        <mat-header-cell *matHeaderCellDef> Line Of Business </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.lineOfBusiness}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.createdUser}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.createdDate | date:'MM/dd/yyyy'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>

        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let state">
        </mat-cell>
      </ng-container>
      <mat-header-row mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayColumns;"
        [class.on-hold]="row?.onHold"
        [class.deadline-approaching]="row?.deadlineApproaching"
        [class.striped-background]="row?.duplicateParentCaseId != null"
        [class.stalled]="row?.statusTrackStatusCode === 'CREATED_UNASSIGNED' || row?.statusTrackStatusCode === 'IN_PROGRESS_UNASSIGNED' || row?.statusTrackStatusCode === 'AWAITING_NEXT_TASK_CREATE'"
      >
      </mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="getCases()"></mat-paginator>
  </ng-container>

  <ng-container pageNoData>
    You are currently not authorized to view any client's cases. Please contact your system administrator to authorize access for specific client cases!
  </ng-container>
</lib-page>
