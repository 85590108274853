import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseComponent } from "../../view/base.component";
import { Logger } from "../../util/logger";
import { Address } from "../../model/address";
import { State } from "../../model/net/state";
import { AddressService } from "./address.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { StateService } from "../state-admin/state.service";
import { BaseResponse } from "../../model/base-response";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("AddressComponent");

  @Input() events: Observable<void> = new Observable<void>();

  // We are going to setup a rxjs channel (pipeline, event bus)
  saveAddress: Subject<any> = new Subject();
  addressSaveComplete: Subject<number> = new Subject();

  @Output()
  saveFromParent = new EventEmitter<void>();

  @Input()
  mode?: string;

  @Input()
  context?: string;

  @Input()
  existingAddressId?: number | null;

  addressId?: number;

  data?: Address;
  stateList?: State[];
  formGroup!: FormGroup;

  constructor(
    private stateService: StateService,
    private addressService: AddressService,
    protected override snack: FennecSnackbarService,
  ) {
    super();

    // This sets up a 'listener' on the saveAddress subject that will listen for messages
    // and do something when it gets a message.
    this.saveAddress.subscribe((data) => {
      this.saveAddressToServer();
    });

    this.events.subscribe(() => {
      this.saveAddressToServer();
    });

  }

  ngOnInit(): void {
    this.getStates();

    if (this.mode === 'EDIT') {
      this.getAddressById(this.existingAddressId);
    } else {
      this.data = new Address();
    }

    this.formGroup = this.getFormGroupFromPacket(this.data);
  }

  getStates() {
    this.performXFRequest({
      requestDescription: "GET States",
      requestFn: this.stateService._list,
      fnParams: [0, 100],
      onSuccess: data => {
        this.stateList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  getFormGroupFromPacket(packet: Address | any){
    const required = this.context !== "PROVIDER";
    const requiredValidator: ValidatorFn = required ? Validators.required : (_control) => { return null };
    return new FormGroup({
      addressLine1: new FormControl(packet?.addressLine1 ?? "", [requiredValidator, Validators.maxLength(50)]),
      addressLine2: new FormControl(packet?.addressLine2 ?? "", Validators.maxLength(50)),
      addressLine3: new FormControl(packet?.addressLine3 ?? "", Validators.maxLength(50)),
      city: new FormControl(packet?.city ?? "", [requiredValidator, Validators.maxLength(50)]),
      stateId: new FormControl(packet?.stateId ?? "", requiredValidator),
      zipCode: new FormControl(packet?.zipCode ?? "", [requiredValidator, Validators.maxLength(9)])
    });
  }

  getAddressById = (id?: number | null) => {
    if(id != null) {
      this.addressService.getAddressById(id).subscribe((response: BaseResponse) => {
        if (response.hasErrors) {
          super.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.data = response.data;
          this.formGroup = this.getFormGroupFromPacket(response.data);
        }
      });
    }
  }

  isValid(){
    return this.formGroup?.valid;
  }

  saveAddressToServer() {
    this.formGroup.markAllAsTouched();
    const formData = this.formGroup?.value;

    this.data = new Address();
    if (this.mode === "EDIT") {
      this.data.id = this.existingAddressId;
    }
    if (this.formGroup.errors
      || (formData["addressLine1"] == null || formData["addressLine1"] == "")
      || (formData["city"] == null || formData["city"] == "")
      || (formData["stateId"] == null || formData["stateId"] == "")
      || (formData["zipCode"] == null || formData["zipCode"] == "")) {
      super.showErrorSnack("Please fill out all required fields.");
      return;
    }
    this.data.addressLine1 = formData.addressLine1;
    this.data.addressLine2 = formData.addressLine2;
    this.data.addressLine3 = formData.addressLine3;
    this.data.city = formData.city;
    this.data.stateId = formData.stateId;
    this.data.zipCode = formData.zipCode;
    this.addressService.updateAddress(this.data).subscribe((response: BaseResponse) => {
      if(response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage);
      }else{
        this.data = response.data;
        this.formGroup = this.getFormGroupFromPacket(response.data);
        this.addressSaveComplete.next(response.data.id);
      }
    });
  }
}
