<lib-page>
  <ng-container pageTitle>
    Fennec Home
  </ng-container>
  <ng-container pageContent>
    <div>
      <mat-grid-list cols="2" rowHeight="200px" gutterSize="15px">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z8 title-center">
            <mat-card-header>
              <mat-card-title>Add Medical Incident Stay</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>
                Create a new Medical Incident Stay.
              </p>
            </mat-card-content>

            <mat-card-actions>
              <button mat-raised-button color="accent" (click)="createCase()" [attr.data-action]="'create-case'">Add Medical Incident Stay</button>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Access Existing Medical Incident Stay(s)</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>
                Access existing Medical Incident Stay(s).
              </p>
            </mat-card-content>

            <mat-card-actions>
              <button mat-raised-button color="accent" (click)="onMICaseList()">Access Existing Medical Incident Stay</button>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>

    </div>
  </ng-container>

</lib-page>
