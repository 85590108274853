import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AttachmentService } from './attachment.service';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ItemizedCreateComponent } from '@app/itemized/itemized-create/itemized-create.component';
import {
  AttachmentPacket,
  BaseComponent, DateUtil,
  FennecSnackbarService,
  FilePacket, FileType,
  FileUtil,
  Logger,
  SingleChoiceDialogComponent
} from 'xf-common';
import { animate, style, transition, trigger } from '@angular/animations';
import { KeycloakService } from 'keycloak-angular';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ])
  ]
})
export class AttachmentComponent extends BaseComponent implements OnInit {

  @Input()
  context: string = "MICASEATTACHMENTS";

  @Input()
  miCaseId: string = "-1";

  @Input()
  showUpload: string = "true";

  log: Logger = new Logger("AttachmentComponent");
  saveAttachment: Subject<any> = new Subject();
  attachmentSaveComplete: Subject<any> = new Subject();

  // These two fields will only be populated on edits
  id?: number;
  externalObjectId?: string;

  name: string = "";
  file?: File;
  attachmentList: any [] = [];
  attachmentTypes: string[] = [];
  // An object that stores extended attachment type properties (key is attachmentType) used for ui treatments.
  attachmentTypesExtended: any = {};
  attachmentType: string = "Itemized";
  selectedAttachmentId: string = "-1";
  selectedAttachment: any = null;

  uploading: boolean = false;
  canUserDeleteAttachment: boolean = false;

  // Return TRUE if it's okay to download this attachment for the current user.
  readonly attachmentPermissionTable = {
    "MICASEATTACHMENTS": [
      "UB04Report", "ItemizedProviderReport", "ItemizedClientReport",
      "APPEAL_REQUEST", "APPEAL_RESPONSE", "APPEAL_REPORT", "EOP", "Itemized", "MEDICAL_RECORD", "PROVIDER_CORRESPONDENCE",
      "RECONSIDERATION_REQUEST", "RESOLUTION_REQUEST", "UB04", "SINGLE_CASE_AGREEMENT", "PROVIDER_COVER_LETTER"
    ],
    "DATAENTRYATTACHMENTS": ["Itemized", "UB04", "Corrected_UB04"]
  }

  userIsDataEntry = false;
  attachmentViewMode: "DATA_ENTRY" | "MI_CASE" = "DATA_ENTRY";

  @ViewChild('paginator')
  paginator?: MatPaginator;

  defaultPageSize:number = 5;
  totalRowCount:number = 0;

  constructor(
    //protected override log: Logger,
    protected override snack: FennecSnackbarService,
    protected attachmentService: AttachmentService,
    protected keycloakService: KeycloakService,
    public matDialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super();
    if (this.route.parent) {
      this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    }
    this.saveAttachment.subscribe((data) => {
      this.saveAttachmentToServer(this.file);
    })
    const roles = keycloakService.getUserRoles();
    this.userIsDataEntry = roles.includes("DATA_ENTRY") && !roles.includes("ADMIN") && !roles.includes("CASE_READ");
    if (!this.userIsDataEntry) {
      this.attachmentViewMode = "MI_CASE";
    }
    this.canUserDeleteAttachment = roles.includes("ATTACHMENT_DELETE");
   }

  ngOnInit(): void {

    // Router path
    const miCaseId = this.route.snapshot.paramMap.get('miCaseId');
    if (miCaseId !== null && miCaseId !== undefined) {
      this.miCaseId = miCaseId;
    }

    // Populate valid Attachment Types for this session.
    if (this.attachmentViewMode === "DATA_ENTRY") {
      // Note: this is an enum on the server. Must match. I'm being lazy just
      // doing this on the client. But I'm old.
      const itemizedDataEntrySpreadSheet = "Itemized_DE_SS";
      this.attachmentTypes = [itemizedDataEntrySpreadSheet];
      this.attachmentType = itemizedDataEntrySpreadSheet;
    } else {
      this.getAttachmentTypes();
    }
    this.getAttachmentTypesExtended();
    this.getAttachmentList();
  }

  getAttachmentList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    if (this.attachmentViewMode === "MI_CASE") {
      this.attachmentService.getAllAttachmentsforMICase(parseInt(this.miCaseId, 10), first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.attachmentList = response.data;
          this.totalRowCount = response.totalRowCount;
          if (this.attachmentList.length > 0) {
            this.selectAttachment(this.attachmentList[0].id);
          } else {
            this.deselectAttachment();
          }
        }
      });
    } else if (this.attachmentViewMode === "DATA_ENTRY") {
      this.attachmentService.getAllAttachmentsforMICaseDataEntry(parseInt(this.miCaseId, 10), first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.attachmentList = response.data;
          this.totalRowCount = response.totalRowCount;
          if (this.attachmentList.length > 0) {
            this.selectAttachment(this.attachmentList[0].id);
          } else {
            this.deselectAttachment();
          }
        }
      });
    }
  }

  getAttachmentTypes() {
    this.performXFRequest({
      requestDescription: "Get Attachment Types",
      requestFn: this.attachmentService.getAttachmentTypes,
      fnParams: [],
      onSuccess: (data: string[]) => {
        this.attachmentTypes = data;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  /**
   * Call to get all the attachments types with their extended properties for UI treatment purposes.
   * We should probably figure out how to combine the attachment type calls and still maintain all of
   * the current business logic and functionality currently in place.
   */
  getAttachmentTypesExtended() {
    this.performXFRequest({
      requestDescription: "Get Attachment Types Extended",
      requestFn: this.attachmentService.getAttachmentTypesExtended,
      fnParams: [],
      onSuccess: (data: any[]) => {
        this.attachmentTypesExtended = {};
        data.forEach((at) => {
          this.attachmentTypesExtended[at.attachmentType] = at;
        });
        console.log(this.attachmentTypesExtended);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  selectAttachment(id: number): void {
    this.selectedAttachmentId = id.toString();
    this.selectedAttachment = this.getAttachment(id);
  }

  getAttachment(id: number): any | null {
    return this.attachmentList.find(e => {
      return e.id === id;
    })
  }

  convertToItemized(id: number): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
      attachmentId: id,
      origin: 'UPLOAD'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        // TODO: Refresh list maybe?
      }
    });
  }

  getSelectedStyle(id: number):any {
    if (id.toString() === this.selectedAttachmentId) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  addFile(input: any){
    this.file = input.files[0];
    if (this.name === null || this.name === undefined || this.name.length === 0) {
      this.name = this.file?.name !== null && this.file?.name !== undefined ? this.file.name : "";
    }
  }

  saveAttachmentToServer(file?: File) {
    if (file == null) {
      super.showErrorSnack("Cannot save NULL file: " + this.id);
      return;
    }
    if (this.attachmentViewMode == "MI_CASE" && this.attachmentType === 'Itemized_DE_SS') {
      super.showErrorSnack(
        "Only data entry users are allowed to upload attachments of type Itemized_DE_SS here. " +
        "Please use the fennec internal application."
      );
      return;
    }

    this.uploading = true;

    FileUtil.convertFileToFilePacket(file)
      .then((filePacket: FilePacket) => {
        const dto = new AttachmentPacket();
        dto.id = this.id;
        dto.name = this.name;
        dto.miCaseId = parseInt(this.miCaseId);
        dto.externalObjectId = this.externalObjectId;
        dto.attachmentType = this.attachmentType;
        dto.filePacket = filePacket;
        dto.publishToWeb = true;

        this.isLoading = true;

        this.performXFRequest({
          requestDescription: "Update Attachment",
          requestFn: this.attachmentService.updateAttachment,
          fnParams: [dto],
          onSuccess: (data: boolean) => {
            super.showSuccessSnack("Successfully updated attachment");
            this.attachmentSaveComplete.next(data);
            this.uploading = false;
          },
          onComplete: () => {
            this.isLoading = false;
            this.name = "";
            this.attachmentType = "Itemized";
            this.file = undefined;
            this.getAttachmentList();
            this.uploading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
            this.isLoading = false;
            this.name = "";
            this.attachmentType = "Itemized";
            this.file = undefined;
            this.getAttachmentList();
            this.uploading = false;
          }
        });
      })
      .catch((error: any) => {
        const errorMessage = super.processError("Error updating attachment: File size limit exceeded for file: " + this.name, error);
        super.showErrorSnack(errorMessage);
        this.uploading = false;
      });
  }

  onSave() {
    this.saveAttachmentToServer(this.file);
  }

  disableSave(): boolean {
    if(
      this.uploading || (
        this.name.length > 0 &&
        this.file !== undefined &&
        this.attachmentType !== undefined
      )){
        return false;
      }

    return true;
  }

  downloadAttachment(attachmentId: number) {
    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Downloading attachment");
        FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  emailAttachment(attachmentId: number) {
    this.performXFRequest({
      requestDescription: "Email Attachment",
      requestFn: this.attachmentService.emailAttachment,
      fnParams: [attachmentId],
      onSuccess: data => {
        super.showSuccessSnack(data);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  deleteAttachmentConfirmation(attachment: AttachmentPacket): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete Attachment",
      infoLine1 : `Delete ${attachment.name}?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteAttachment(attachment);
      }
    });
  }

  deleteAttachmentConfirmationById(id: string): void {
    const attachment = this.getAttachmentById(id);
    if(attachment != null) {
      this.deleteAttachmentConfirmation(attachment);
    }
  }

  deleteAttachment(packet: AttachmentPacket): void {
    if(packet.id == undefined) {
      super.showErrorSnack("No ID provided in packet");
      return;
    }

    this.performXFRequest({
      requestDescription: "Delete Attachment",
      requestFn: this.attachmentService.deleteAttachment,
      fnParams: [packet.id],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Attachment deleted");
        this.removeAttachmentFromList(packet.id);
        // FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  removeAttachmentFromList(id?:number) {
    for(let i = 0; i < this.attachmentList.length; i++) {
      const attachment = this.attachmentList[i];
      if(attachment.id === id) {
        this.attachmentList.splice(i);
        return;
      }
    }
  }

  getAttachmentAtIndex(index:number) {
    if(index < this.attachmentList.length) {
      return this.attachmentList[index]
    }

    return null;
  }

  getFormattedDate(attachment: any) {
    return DateUtil.getDisplayDate(attachment.createdDate);
  }

  getAttachmentById(idString:string) {
    const id = parseInt(idString, 10);
    let result = null;
    this.attachmentList.forEach((attachment) => {
      if(attachment.id === id) {
        result = attachment;
        return;
      }
    })

    return result;
  }

  deselectAttachment() {
    this.selectedAttachment = null;
    this.selectedAttachmentId = "-1";
  }

  canDownload(attachment: any): boolean {
    if (this.userIsDataEntry) {
      return false;
    }

    let okToDownload: boolean = false;
    const permissionsContext = this.userIsDataEntry ? "DATAENTRYATTACHMENTS" : this.context;
    if (this.attachmentPermissionTable[permissionsContext].includes(attachment.attachmentType)) {
      okToDownload = true;
    }
    return okToDownload;
  }

  // Return TRUE if it's okay to email this attachment for the current user.
  canEmail(attachment: any): boolean {
    let okToEmail: boolean = false;
    if (this.attachmentViewMode === "MI_CASE") {
      okToEmail = true;
    } else if (this.attachmentViewMode === "DATA_ENTRY") {
      okToEmail = false;
    }
    return okToEmail;
  }

  // Return TRUE if it's okay to delete this attachment for the current user.
  canDelete(attachment: any): boolean {
    let okToDelete: boolean = false;
    if (this.attachmentViewMode === "MI_CASE") {
      okToDelete = this.canUserDeleteAttachment;
    } else if (this.attachmentViewMode === "DATA_ENTRY") {
      okToDelete = false;
    }
    return okToDelete;
  }

  viewAttachment(attachment:any): void {
    window.open(`/view-attachment/${attachment.id}`, "_blank");
  }

  isFilePdf(attachment:any):boolean {
    let result = false;

    if(attachment.name && attachment.name.endsWith(".pdf")) {
      result = true;
    }

    return result;
  }

  confirmSetReportReceivedDialog = (attachment:any) => {
    let reportReceived = attachment.reportReceived ? "NOT received" : "received";
    let currentStatus = attachment.reportReceived ? "received" : "NOT received";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: `Are you sure you want to set '${attachment.name}' to ${reportReceived}?`,
      infoLine1: `Attachment is currently set to ${currentStatus}.`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result.confirm) {
        this.toggleSetReportReceived(attachment);
      }
    });
  }

  toggleSetReportReceived = (attachment:any) => {
    this.performXFRequest({
      requestDescription: "Toggle Attachment Report Received",
      requestFn: this.attachmentService.toggleAttachmentReportReceived,
      fnParams: [attachment.id],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack(`'${attachment.name}' successfully updated!`);
        // this.removeAttachmentFromList(packet.id);
        this.getAttachmentList();
        // FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
        this.getAttachmentList();
      },
      onError: (error) => {
        super.showErrorSnack(error);
        this.getAttachmentList();
      }
    });
  }

}
