export class UB04RevenueCodeUpdatePacket {
  // -1 = add
  id: number = -1; 
  ub04Id: number = -1;
  revenueCodeId: number = -1;
  revenueCodeString?: string;
  serviceDateString?: string;
  units: number = 0;
  amount: number = 0;
  nonCoveredAmount: number = 0;
  deleteRow: boolean = false;
}